import styled from 'styled-components';
import { animated } from 'react-spring';

export const OurApp = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  min-height: calc(100vh - 121px);
  padding: 60px 0;
  overflow: hidden;

  @media screen and (min-width: 1920px) {
    min-height: auto;
    height: min(100vh, 1000px);
  }
`;

export const InnerCont = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: min(1000px, 86%);
  height: 100%;

  @media screen and (min-width: 900px) {
    justify-content: space-between;
  }
`;

export const PhonesCont = styled.div`
  position: relative;
  width: 280px;
  height: 600px;
  margin-bottom: 40px;

  @media screen and (min-width: 900px) {
    width: 380px;
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 0;
  }
`;

export const LeftPhone = styled(animated.img)`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: auto;
  opacity: 0;
  transform: translate(-100px, -50px);
`;

export const RightPhone = styled(animated.img)`
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 100%;
  height: auto;
  opacity: 0;
  transform: translate(100px, 50px);
`;
