import { SubCategory as SubCategoryHOC, SubCategoryContext } from './SubCategory';

//HOC component, dependencies components imports
import SubCategoryTitle from 'infrastructure/components/atoms/SubCategoryTitle/SubCategoryTitle';
import SupportArticleTitleSet from 'infrastructure/components/molecules/SupportArticleTitleSet/SupportArticleTitleSet';

export { SubCategoryContext, SubCategoryTitle, SupportArticleTitleSet };

export const SubCategory = Object.assign(SubCategoryHOC, {
  Title: SubCategoryTitle,
  ArticlesSet: SupportArticleTitleSet,
});

export default SubCategory;
