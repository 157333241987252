import * as S from './Feasibility.styled';
import { leftBubleBg, rightBubleBg } from 'infrastructure/assets';

const Feasibility = () => {
  return (
    <S.Feasibility>
      <S.LeftBgImg src={leftBubleBg} alt='background style' />
      <S.InfoCont>
        <S.InfoTitle>Factibilidad de servicio</S.InfoTitle>
        <S.Info>
          Existencia de línea de vista sin obstrucciones entre la ubicación del cliente y un sitio
          de la repetición de la red de transmisión de Wuipi.
        </S.Info>
        <S.Info>
          Análisis de espectro en la banda de 4900-6100 GHz, en polaridades vertical y horizontal,
          para determinar la disponibilidad de un canal libre.
        </S.Info>
        <S.Info>
          Disponibilidad de ancho de banda para cubrir el requerimiento del cliente en los enlaces
          de la red de transmisión que interconecta en el sitio de repetición más cercano al usuario
          con el centro de datos de Wuipi.
        </S.Info>
      </S.InfoCont>

      <S.RightBgImg src={rightBubleBg} alt='background style' />
    </S.Feasibility>
  );
};

export default Feasibility;
