import styled from 'styled-components';

export const SocialMediaIcon = styled.a`
  width: 36px;
  height: 36px;
  border: 1px solid var(--color-white-light);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  @media screen and (min-width: 600px) {
    width: 50px;
    height: 50px;
  }
`;
