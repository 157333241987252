import { WhoIsWuipiInfo } from 'infrastructure/components';

import { wuipiSignalFlat } from 'infrastructure/assets';
import { useWhoIsWuipiSpringAnim } from './hooks';

import * as S from './WhoIsWuipi.styled';

const WhoIsWuipi = () => {
  const { mousePosListening, springDec4 } = useWhoIsWuipiSpringAnim();

  return (
    <S.WhoIsWuipi onMouseMove={mousePosListening}>
      <S.InnerCont>
        <WhoIsWuipiInfo />
        <S.WuipiSignal src={wuipiSignalFlat} alt='wuipi signal flat' style={springDec4} />
      </S.InnerCont>
    </S.WhoIsWuipi>
  );
};

export default WhoIsWuipi;
