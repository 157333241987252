import styled from 'styled-components';
import { animated } from 'react-spring';

export const WhoIsWuipi = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  min-height: calc(100vh - 121px);
  padding: 60px 0;
  overflow-x: hidden;

  @media screen and (min-width: 1920px) {
    min-height: auto;
    height: min(100vh, 800px);
  }
`;

export const InnerCont = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: min(1000px, 86%);
  height: 100%;

  @media screen and (min-width: 900px) {
    justify-content: space-between;
    align-items: center;
  }
`;

export const WuipiSignal = styled(animated.img)`
  width: min(100%, 250px);
  height: auto;
  object-fit: contain;

  @media screen and (min-width: 600px) {
    width: min(90%, 400px);
    height: auto;
    object-fit: contain;
  }
`;
