import { LazyExoticComponent, Suspense, useId } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
//import AppPersistAuthRoutes from './AppPersistAuthRoutes';
//import AppProtectedRoutes from './AppProtectedRoutes';
import { RouteMeta } from 'infrastructure/Meta';
import rootRoutes from './routes';

const AppRoutes = () => {
  const location = useLocation();
  const id = useId();

  useEffect(() => {
    ReactGA.initialize('G-JNNR41CH70');
    ReactGA.pageview(location.pathname + location.search);
  }, [location.pathname, location.search]);

  //this components have all the routes of the layouts, those layout can be a components
  //or components with their own routes (like LayoutMain)

  // Scroll to top when route changes

  type ReactComponent = () => JSX.Element;

  interface RoutesProps {
    path: string;
    Component: LazyExoticComponent<ReactComponent> | ReactComponent;
    onlyAllowAccessTo?: string[];
    nestedRoutes?: RoutesProps[];
    routeMetaData?: {
      contentType: string;
      contentId: string;
      fbqEventName: string;
    };
  }

  const routeCreator = (routesList: RoutesProps[]) => {
    return (
      <>
        {routesList.map(({ path, Component, nestedRoutes, routeMetaData }) => {
          return (
            // <Route
            //   element={<AppProtectedRoutes onlyAllowAccessTo={onlyAllowAccessTo} />}
            //   key={`${id}-${path}`}
            // >

            <Route element={<RouteMeta MetaData={routeMetaData} />} key={`${id}-${path}`}>
              <Route path={path} element={<Component />} key={`${id}-${path}`}>
                {nestedRoutes && routeCreator(nestedRoutes)}
              </Route>
            </Route>
            // </Route>
          );
        })}
      </>
    );
  };

  return (
    <Suspense
      fallback={
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'var(--color-blue-deep)',
          }}
        >
          <MoonLoader />
        </div>
      }
    >
      <Routes>
        {/* <Route element={<AppPersistAuthRoutes />}> */}
        {routeCreator(rootRoutes)}
        <Route path='/*' element={<Navigate to='/' />} />
        {/* </Route> */}
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
