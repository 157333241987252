import { WebTitle, WebSubTitle, MainButton } from 'infrastructure/components';

import * as S from './WebPresentation.styled';

const WebPresentation = () => {
  return (
    <S.WebPresentation>
      <WebTitle WebTitleProps={{ text: '¡No creerás que es inalámbrico!' }} />
      <WebSubTitle text='La nueva era de la tecnología está aquí' />
      <S.ButtonCont>
        <S.LinkTo to='#planes'>
          <MainButton
            MainButtonProps={{
              text: 'Ver planes',
              borderRadius: 'round',
              color: 'orange',
              slimText: true,
              type: 'button',
            }}
          />
        </S.LinkTo>

        <S.LinkTo to='/support'>
          <MainButton
            MainButtonProps={{
              text: 'Soporte',
              borderRadius: 'round',
              color: 'blue',
              slimText: true,
              type: 'button',
            }}
          />
        </S.LinkTo>
      </S.ButtonCont>
    </S.WebPresentation>
  );
};

export default WebPresentation;
