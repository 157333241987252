import styled from 'styled-components';

export const ClientPanel = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-white-light);
  padding: 60px 30px 30px 30px;

  @media screen and (min-width: 900px) {
    padding: 10px;
  }

  @media screen and (min-width: 1200px) {
    padding: 0px 60px;
  }
`;
