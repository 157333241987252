import styled from 'styled-components';

export const SupportMainButton = styled.button`
  background-color: transparent;
  outline: none;
  border-radius: 32px;
  border: 1px solid var(--color-blue-sea);
  font-family: inherit;
  color: var(--color-white-soft);
  font-size: 16px;
  padding: 15px 30px;
  min-width: 240px;
  margin: 0 10px;
  cursor: pointer;

  @media screen and (min-width: 600px) {
    font-size: 18px;
    padding: 20px 30px;
  }
`;
