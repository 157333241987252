import { ClientInfoContext } from 'infrastructure/components/molecules/ClientInfo/ClientInfo';
import { CSSProperties, useContext } from 'react';
import * as S from './ClientEmail.styled';

interface Props {
  clientEmailProps?: ClientEmailProps;
  className?: string;
  style?: CSSProperties;
}

interface ClientEmailProps {
  email?: string;
}

const ClientEmail = ({ clientEmailProps, className, style }: Props) => {
  const { email } = useContext(ClientInfoContext);
  const emailToShow: string = clientEmailProps?.email || email || '---------------';

  return (
    <S.ClientEmail className={className} style={style}>
      <S.Title>Correo electrónico</S.Title>
      <S.Email>{emailToShow}</S.Email>
    </S.ClientEmail>
  );
};

export default ClientEmail;
