import { CSSProperties, useContext } from 'react';
import { CategoryCardContext } from 'infrastructure/components/molecules/CategoryCard/CategoryCard';

import * as S from './CategoryCard.styled';
interface Props {
  CategoryCardTitleProps?: CategoryCardTitleProps;
  className?: string;
  style?: CSSProperties;
}

interface CategoryCardTitleProps {
  title: string;
}

const CategoryCardTitle = ({ CategoryCardTitleProps, className, style }: Props) => {
  const { title } = useContext(CategoryCardContext);
  const titleToShow: string = CategoryCardTitleProps?.title || title || 'Quiero ser cliente Wuipi';

  return (
    <S.CategoryCardTitle className={className} style={style}>
      {titleToShow}
    </S.CategoryCardTitle>
  );
};

export default CategoryCardTitle;
