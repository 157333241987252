import { SectionTitle, PlansAndPricesBadge } from 'infrastructure/components';

import * as S from './PlansAndPrices.styled';

const OurPlatform = () => {
  return (
    <S.PlansAndPrices>
      <S.InnerCont>
        <SectionTitle
          SectionTitleProps={{
            text: 'Planes y Precios',
            color: 'blue',
          }}
        />
        <S.PlansAndPricesBadgeCont>
          <PlansAndPricesBadge
            plansAndPricesBadgeProps={{
              name: 'BEAM 10',
              description: 'Hasta 10 mbps',
              price: '$16 al mes',
            }}
          />
          <PlansAndPricesBadge
            plansAndPricesBadgeProps={{
              name: 'BEAM 20',
              description: 'Hasta 20 mbps',
              price: '$31 al mes',
            }}
          />
          <PlansAndPricesBadge
            plansAndPricesBadgeProps={{
              name: 'BEAM 30',
              description: 'Hasta 30 mbps',
              price: '$43 al mes',
            }}
          />
          <PlansAndPricesBadge
            plansAndPricesBadgeProps={{
              name: 'BEAM 50',
              description: 'Hasta 50 mbps',
              price: '$76 al mes',
            }}
          />
        </S.PlansAndPricesBadgeCont>
        <S.Note>Precios no incluyen IVA</S.Note>
      </S.InnerCont>
    </S.PlansAndPrices>
  );
};

export default OurPlatform;
