import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface ContactProps {
  name: string;
  email: string;
  message: string;
}

interface ContactResponseProps {
  status: 'idle' | 'loading' | 'success' | 'error';
  message: string;
}

interface CheckPlansProps {
  email: string;
  plan: string;
}

export const homeApi = createApi({
  reducerPath: 'homeApi',
  baseQuery: fetchBaseQuery({
    //give the base url from .env
    baseUrl: process.env.REACT_APP_ROOT_ENDPOINT,
  }),

  endpoints: (builder) => ({
    sendContact: builder.mutation<ContactResponseProps, ContactProps>({
      query: ({ name, email, message }) => ({
        url: '/sendgrid/contact',
        method: 'POST',
        body: { name, email, message },
        keepUnusedDataFor: 1,
      }),
    }),

    checkPlans: builder.mutation<ContactResponseProps, CheckPlansProps>({
      query: ({ email, plan }) => ({
        url: '/sendgrid/contact-plan',
        method: 'POST',
        body: { email, plan },
        keepUnusedDataFor: 1,
      }),
    }),
  }),
});

export const { useSendContactMutation, useCheckPlansMutation } = homeApi;
