import { useDeferredValue, useEffect, useState } from 'react';
import { useServiceRequestMutation } from 'infrastructure/store/apis/service';
import { FormValuesProps, FormErrorsProps } from '../interfaces/interfaces';
import validation from '../utils/validation';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CountryData } from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';

const useForm = (initValues: FormValuesProps, error: FormErrorsProps) => {
  const navigate = useNavigate();
  const [serviceRequest, { isLoading }] = useServiceRequestMutation();
  const MySwal = withReactContent(Swal);

  //inputs state
  const [values, setValues] = useState({
    ...initValues,
  });

  const deferredValue = useDeferredValue(values);

  //inputs errors
  const [errors, setErrors] = useState({
    ...error,
  });

  //validation reset
  const [isValidating, setIsValidating] = useState(false);

  //validation reset
  const [disableSubmit, setDisableSubmit] = useState(false);

  //submit handler
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await serviceRequest({
        fullname: values.name,
        email: values.email,
        identification: `${values.ciOrRifType}-${values.ciOrRif}`,
        phone: values.phoneNumber,
        phone_contact: values.contactPhoneNumber,
        plan: values.plan,
        paymentMethod: values.paymentMethod,
        address: values.addressForServiceInstallation,
      })
        .unwrap()
        .then(() => {
          navigate('/thanks-service', { replace: true });
        });
    } catch (error) {
      const statusError = error as unknown as {
        status: number;
        data: { status: string; message: string };
      };

      let errorMessage;
      if (statusError.data.message === 'Invalid email.') {
        errorMessage = 'Por favor introduce un correo valido';
      } else {
        errorMessage =
          'Opps, ha ocurrido un error, comprueba tu conexion a internet e intente mas tarde';
      }

      MySwal.fire({
        toast: true,
        icon: 'error',
        title: errorMessage,
        position: 'top-end',
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
    } finally {
      setIsValidating(false);
      setValues({ ...initValues });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    if (!isValidating) {
      setIsValidating(true);
    }

    setValues((prevFormData) => {
      return { ...prevFormData, [name]: type === 'checkbox' ? checked : value };
    });
  };

  const selectHandleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (!isValidating) {
      setIsValidating(true);
    }

    setValues((prevFormData) => {
      return { ...prevFormData, [name]: value };
    });
  };

  const phoneNumberHandleChange = (
    value: string,
    data: {} | CountryData,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    const { name } = event.target;
    if (!isValidating) {
      setIsValidating(true);
    }

    setValues((prevFormData) => {
      return { ...prevFormData, [name]: formattedValue };
    });
  };

  useEffect(() => {
    setErrors(isValidating ? validation(values) : { ...error });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferredValue]);

  useEffect(() => {
    setDisableSubmit(isValidating ? Object.values(errors).some((error) => error !== '') : true);
  }, [errors, isValidating]);

  return {
    values,
    errors,
    disableSubmit,
    isLoading,
    isValidating,
    handleSubmit,
    handleChange,
    phoneNumberHandleChange,
    selectHandleChange,
  };
};

export default useForm;
