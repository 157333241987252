import { FormatDateProps } from '../interfaces/interfaces';
import moment from 'moment';

const useFormatDate = ({ date }: FormatDateProps) => {
  let suspension = '';

  if (date) {
    const withMonth = moment(date, 'DD/MM/YYYY').add(1, 'month');
    const withMonthString = withMonth.format('08/MM/YYYY');
    suspension = withMonthString;
  }

  return { suspension };
};

export default useFormatDate;
