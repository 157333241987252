import { isValidPhoneNumber } from 'libphonenumber-js';
import { FormValuesProps, FormErrorsProps } from '../interfaces';

const validation = ({
  name,
  email,
  ciOrRif,
  phoneNumber,
  contactPhoneNumber,
  addressForServiceInstallation,
  ciOrRifType,
  plan,
  paymentMethod,
}: FormValuesProps) => {
  const errors: FormErrorsProps = {
    name: '',
    email: '',
    ciOrRif: '',
    phoneNumber: '',
    contactPhoneNumber: '',
    addressForServiceInstallation: '',
    plan: '',
    paymentMethod: '',
  };

  //Validation for fullName
  if (!name.trim()) {
    errors.name = 'Este campo es requerido';
  } else if (name.trim() && name.trim().length < 3) {
    errors.name = 'Debe escribir un minimo de 3 caracteres!';
  } else if (name.trim() && !/^[a-zA-Z ]*$/.test(name.trim())) {
    errors.name = 'Este campo no acepta numeros o caracteres especiales!';
  } else {
    errors.name = '';
  }

  //Validation for ci-rif
  if (!ciOrRif.trim()) {
    errors.ciOrRif = 'Este campo es requerido';
  } else if (ciOrRif.trim() && (ciOrRif.trim().length < 7 || ciOrRif.trim().length > 16)) {
    errors.ciOrRif = 'Por favor introducir un valor correcto';
  } else {
    errors.ciOrRif = '';
  }

  //phone;
  if (!phoneNumber.trim()) {
    errors.phoneNumber = 'Este campo es requerido';
  } else if (phoneNumber.trim() && !isValidPhoneNumber(phoneNumber)) {
    errors.phoneNumber = 'Por favor, usa un numero de telefono valido';
  } else {
    errors.phoneNumber = '';
  }

  //contact phone;
  if (!contactPhoneNumber.trim()) {
    errors.contactPhoneNumber = 'Este campo es requerido';
  } else if (contactPhoneNumber.trim() && !isValidPhoneNumber(contactPhoneNumber)) {
    errors.contactPhoneNumber = 'Por favor, usa un numero de telefono valido';
  } else {
    errors.contactPhoneNumber = '';
  }

  //Validation for email
  if (!email.trim()) {
    errors.email = 'Este campo es requerido';
  } else if (
    email.trim() &&
    !email
      .trim()
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    errors.email = 'Por favor introduzca un email valido';
  } else {
    errors.email = '';
  }

  //Validation for addressForServiceInstallation
  if (!addressForServiceInstallation.trim()) {
    errors.addressForServiceInstallation = 'Este campo es requerido';
  } else if (
    addressForServiceInstallation.trim() &&
    addressForServiceInstallation.trim().length < 3
  ) {
    errors.addressForServiceInstallation = 'Debe escribir un minimo de 3 caracteres!';
  } else {
    errors.addressForServiceInstallation = '';
  }

  //Validation for plan
  if (!plan.trim()) {
    errors.plan = 'Debe de seleccionar un plan para poder enviar la solicitud';
  } else {
    errors.plan = '';
  }

  //Validation for paymentMethod
  if (!paymentMethod.trim()) {
    errors.paymentMethod = 'Debe de seleccionar un plan para poder enviar la solicitud';
  } else {
    errors.paymentMethod = '';
  }

  return errors;
};

export default validation;
