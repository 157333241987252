import { createContext, CSSProperties, ReactElement } from 'react';

import * as S from './SubCategory.styled';

interface Props {
  SubCategoryProps?: SubCategoryProps;
  className?: string;
  style?: CSSProperties;
  children?: ReactElement | ReactElement[];
}

interface SubCategoryProps {
  title?: string;
  subCategoryId?: number;
  articles?: {
    id: number;
    type: number;
    status: number;
    agent_id: number;
    created_at: string;
    category_id: number;
    folder_id: number;
    title: string;
    updated_at: string;
    description: string;
    description_text: string;
    seo_data: SEOData;
    tags: any[];
    attachments: any[];
    cloud_files: any[];
    thumbs_up: number;
    thumbs_down: number;
    hits: number;
    suggested: number;
    feedback_count: number;
    platforms: any[];
  }[];
}

interface SEOData {
  meta_title: string;
  meta_description: string;
}

export const SubCategoryContext = createContext({} as SubCategoryProps);

export const SubCategory = ({ SubCategoryProps, children, className, style }: Props) => {
  const toContextValue: SubCategoryProps = SubCategoryProps || {
    title: '',
    subCategoryId: 0,
    articles: [],
  };

  return (
    <SubCategoryContext.Provider value={toContextValue}>
      <S.SubCategory className={className} style={style}>
        {children}
      </S.SubCategory>
    </SubCategoryContext.Provider>
  );
};
