import { Props, PlansAndPricesBadgeProps } from './interfaces';

import * as S from './PlansAndPricesBadge.styled';

const PlansAndPricesBadge = ({ plansAndPricesBadgeProps }: Props) => {
  const { name, price, description }: PlansAndPricesBadgeProps = plansAndPricesBadgeProps || {
    name: 'BEAM 10',
    description: 'Hasta 10 mbps',
    price: '$16 al mes',
  };

  return (
    <S.PlansAndPricesBadge>
      <S.PlanNameCont>
        <S.PlanName>{name}</S.PlanName>
        <S.PlanDescription>{description}</S.PlanDescription>
      </S.PlanNameCont>
      <S.PlanPriceCont>
        <S.Price> {price}</S.Price>
      </S.PlanPriceCont>
    </S.PlansAndPricesBadge>
  );
};

export default PlansAndPricesBadge;
