import { SectionText, SectionTitle } from 'infrastructure/components';

import * as S from './LocationMap.styled';

const LocationMap = () => {
  return (
    <S.LocationMap>
      <S.InnerCont>
        <S.WuipiLocationMap
          title='wuipi telecomuniaciones'
          src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15706.499889024266!2d-64.6394586!3d10.2110792!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2d757f8c388a33%3A0xefd349313318e5f1!2sWUIPI%20telecomunicaciones!5e0!3m2!1ses-419!2sve!4v1680547656555!5m2!1ses-419!2sve'
          loading='lazy'
        />
        <S.InfoCont>
          <SectionTitle
            SectionTitleProps={{
              text: ['¿Cómo', <br key='stbr1' />, 'ubicarnos?', <br key='stbr2' />],
              color: 'blue',
            }}
          />
          <SectionText
            color='blue'
            text='Nuestra sede se encuentra en la avenida principal La Tinia, Quinta Cerro Alto #1. Puerto La Cruz 6016, Anzoátegui'
          />
          <SectionText
            color='blue'
            text='¡Ven y descubre nuestros productos y servicios hoy mismo!'
          />
        </S.InfoCont>
      </S.InnerCont>
    </S.LocationMap>
  );
};

export default LocationMap;
