import { useEffect, useState } from 'react';

interface FormTextAreaProps {
  id: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  label?: string | [JSX.Element, JSX.Element];
  value: string | number;
  errorMessage: string;
  isLabelSmall?: boolean;
  isInFAQ?: boolean;
  isValidating?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
}

const useTextArea = (props: FormTextAreaProps) => {
  const { label, errorMessage, isLabelSmall, isValidating, isInFAQ, ...inputProps } = props;
  const [focused, setFocused] = useState(false);

  const handleFocusedBlur = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    setFocused(true);
  };
  const handleFocusedFocus = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    setFocused(true);
  };

  useEffect(() => {
    if (!isValidating) {
      setFocused(false);
    }
  }, [isValidating]);

  return {
    label,
    errorMessage,
    inputProps,
    focused,
    handleFocusedBlur,
    handleFocusedFocus,
  };
};

export default useTextArea;
