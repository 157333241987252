import styled from 'styled-components';

export const PaymentMethodsNav = styled.nav`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  max-width: 350px;
  margin-bottom: 40px;

  @media screen and (min-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 20px;
  }
`;
