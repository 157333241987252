import styled from 'styled-components';

export const InvoiceTotalAmount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: var(--color-blue-dark);
`;

export const AmountToPay = styled.p`
  font-size: 20px;
  margin-bottom: 10px;
`;

interface AmountProps {
  amount: number | null | undefined;
}

export const AmountsCont = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

export const Dollar = styled.span<AmountProps>`
  font-size: 30px;
  font-weight: 600;
  color: ${({ amount }) => (amount && amount > 0 ? '#BB4949' : 'var(--color-blue-dark)')};
  margin-left: 20px;
`;

export const Vef = styled.span<AmountProps>`
  font-size: 20px;
  font-weight: 600;
  color: ${({ amount }) => (amount && amount > 0 ? '#BB4949' : 'var(--color-blue-dark)')};
`;
