import * as S from './InfoCard.styled';
import { CardText } from 'infrastructure/components';

interface InfoCardProps {
  children?: JSX.Element;
  text: string;
}

const InfoCard = ({ children, text }: InfoCardProps) => {
  return (
    <S.InfoCard>
      {children}
      <CardText text={text} />
    </S.InfoCard>
  );
};

export default InfoCard;
