import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  status: 'idle' | 'loading' | 'success' | 'error';
  message: string;
  accessToken: string;
  user: {
    userId: string;
    partnerId: number | null;
    role: string;
  };
}

export interface AuthInitialState extends AuthState {
  trustDevice: boolean;
}

const getLocalStateValue = () => {
  const localState = localStorage.getItem('trustDevice');
  const localStateParsed = localState !== null && (JSON.parse(localState) as boolean);
  return localStateParsed;
};

const initialState: AuthInitialState = {
  status: 'idle',
  message: '',
  accessToken: '',
  user: {
    userId: '',
    partnerId: null,
    role: '',
  },
  trustDevice: getLocalStateValue() || false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<AuthState>) => {
      const { status, accessToken, user } = action.payload;
      state.status = status;
      state.accessToken = accessToken;
      state.user = user;
    },

    clearCredentials: (state) => {
      state.status = 'idle';
      state.accessToken = '';
      state.user = {
        userId: '',
        partnerId: null,
        role: '',
      };
    },

    setTrustDevice: (state, action: PayloadAction<boolean>) => {
      state.trustDevice = action.payload;
    },

    setStatus: (state, action: PayloadAction<'idle' | 'loading' | 'success' | 'error'>) => {
      state.status = action.payload;
    },

    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCredentials, clearCredentials, setTrustDevice, setStatus, setMessage } =
  authSlice.actions;
