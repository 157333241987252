import { useEffect, useState } from 'react';
import { SectionTitle, SectionText, OurPlansRange, OurPlansLabel } from 'infrastructure/components';

import * as S from './OurPlansInfo.styled';

const OurPlansInfo = () => {
  const [selectionText, setSelectionText] = useState({
    upload: 'Hasta 10 Mbps',
    download: 'Hasta 10 Mbps',
    price: '$16 / Mes',
  });

  const [selectedPlan, setSelectedPlan] = useState<'BEAM 10' | 'BEAM 20' | 'BEAM 30' | 'BEAM 50'>(
    'BEAM 10'
  );

  useEffect(() => {
    setSelectionText(
      selectedPlan === 'BEAM 10'
        ? {
            upload: 'Velocidad de subida: Hasta 10 Mbps',
            download: 'Velocidad de bajada: Hasta 10 Mbps',
            price: '$16 / Mes',
          }
        : selectedPlan === 'BEAM 20'
        ? {
            upload: 'Velocidad de subida: Hasta 20 Mbps',
            download: 'Velocidad de bajada: Hasta 20 Mbps',
            price: '$31 / Mes',
          }
        : selectedPlan === 'BEAM 30'
        ? {
            upload: 'Velocidad de subida: Hasta 30 Mbps',
            download: 'Velocidad de bajada: Hasta 30 Mbps',
            price: '$43 / Mes',
          }
        : selectedPlan === 'BEAM 50'
        ? {
            upload: 'Velocidad de subida: Hasta 50 Mbps',
            download: 'Velocidad de bajada: Hasta 50 Mbps',
            price: '$76 / Mes',
          }
        : { upload: '', download: '', price: '' }
    );
  }, [selectedPlan, setSelectedPlan]);

  return (
    <S.OurPlansInfo>
      <SectionTitle
        SectionTitleProps={{
          text: ['Explora nuestras opciones'],
          color: 'blue',
        }}
      />
      <OurPlansRange style={{ zIndex: 5 }}>
        <OurPlansLabel
          text='BEAM 10'
          onClick={() => setSelectedPlan('BEAM 10')}
          fill={selectedPlan === 'BEAM 10' ? 'filled' : 'empty'}
        />
        <OurPlansLabel
          text='BEAM 20'
          onClick={() => setSelectedPlan('BEAM 20')}
          fill={selectedPlan === 'BEAM 20' ? 'filled' : 'empty'}
        />
        <OurPlansLabel
          text='BEAM 30'
          onClick={() => setSelectedPlan('BEAM 30')}
          fill={selectedPlan === 'BEAM 30' ? 'filled' : 'empty'}
        />
        <OurPlansLabel
          text='BEAM 50'
          onClick={() => setSelectedPlan('BEAM 50')}
          fill={selectedPlan === 'BEAM 50' ? 'filled' : 'empty'}
        />
      </OurPlansRange>
      <SectionText
        style={{
          margin: '0 0 5px 15px',
          zIndex: 5,
          fontSize: '16px',
        }}
        color='blue'
        text={selectionText.download}
      />
      <SectionText
        style={{
          margin: '0 0 30px 15px',
          zIndex: 5,
          fontSize: '16px',
        }}
        color='blue'
        text={selectionText.upload}
      />
      <SectionText
        style={{
          margin: '0 0 0px 15px',
          fontWeight: 700,
          zIndex: 5,
          fontSize: '16px',
        }}
        color='blue'
        text={selectionText.price}
      />
      <SectionText
        style={{
          margin: '0 0 30px 15px',

          zIndex: 5,
          fontSize: '16px',
          color: '#575761',
        }}
        color='blue'
        text='Precios no incluyen IVA'
      />

      <SectionText
        style={{
          margin: '0 0 0 15px',
          fontWeight: 700,
          zIndex: 5,
          fontSize: '24px',
        }}
        color='blue'
        text='Elije tu plan ideal'
      />
    </S.OurPlansInfo>
  );
};

export default OurPlansInfo;
