import styled from 'styled-components';

export const Campaign = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-blue-sea);
  padding: 60px 10px;
  min-height: 54vh;
  text-align: center;

  @media screen and (min-width: 1920px) {
    min-height: auto;
    height: 600px;
  }
`;

export const Title = styled.h3`
  font-weight: 400;
  font-size: 32px;
  color: var(--color-white-soft);
  text-transform: none;
  margin-bottom: 50px;
`;

export const info = styled.p`
  color: var(--color-white-soft);
  font-size: 18px;
  font-weight: 300;
  width: min(90%, 980px);

  & span {
    font-weight: 500;
    letter-spacing: 0.7px;
  }
`;
