import * as S from './SupportBanner.styled';
import { SupportPresentation } from 'infrastructure/components';

const SupportBanner = () => {
  return (
    <S.Banner>
      <SupportPresentation />
    </S.Banner>
  );
};

export default SupportBanner;
