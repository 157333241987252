import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface StandarResponseProps {
  status: 'idle' | 'loading' | 'success' | 'error';
  message: string;
}

interface GetTelegramStatusProps extends StandarResponseProps {
  msg: string;
}

export const telegramApi = createApi({
  reducerPath: 'telegramApi',

  baseQuery: fetchBaseQuery({
    //give the base url from .env
    baseUrl: 'https://wuipi.net/api/web',
  }),

  endpoints: (builder) => ({
    getTelegramStatus: builder.query({
      query: () => `/notify`,
      transformResponse(response: GetTelegramStatusProps, meta, arg: null) {
        return response.msg;
      },
    }),
  }),
});

export const { useGetTelegramStatusQuery } = telegramApi;
