import { useDeferredValue, useEffect, useState } from 'react';
import { useSearchAllArticlesQuery } from 'infrastructure/store/apis/support';

const useSearchFilter = () => {
  //inputs state
  const [values, setValues] = useState({
    text: '',
    toSearch: '',
  });

  const deferredValue = useDeferredValue(values);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setValues((prevState) => ({
      ...prevState,
      text,
      toSearch: text,
    }));
  };

  const { isSuccess, data, isFetching } = useSearchAllArticlesQuery({
    query: deferredValue.toSearch,
  });

  const [resultData, setResultData] = useState<{ value: string; id: number; folder_id: number }[]>(
    []
  );

  const preventBlur = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
  };

  const cleanOnSearch = () => {
    setResultData([]);
    setValues({
      text: '',
      toSearch: '',
    });
  };

  const cleanOnBlur = () => {
    setResultData([]);
  };

  useEffect(() => {
    if (isSuccess) {
      let dataFormated: { value: string; id: number; folder_id: number }[];

      dataFormated = data.map(({ title, id, folder_id }) => {
        const cleanTitle = title.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return { value: cleanTitle, id, folder_id };
      });
      setResultData(dataFormated);
    }
  }, [deferredValue, deferredValue.text, isSuccess, data]);

  return {
    values,
    resultData,
    isFetching,
    cleanOnSearch,
    preventBlur,
    handleChange,
    cleanOnBlur,
  };
};

export default useSearchFilter;
