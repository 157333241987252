import { useGetArticle } from './hooks';
import { ArticleNavLink, ArticleNavbar } from 'infrastructure/components';
import { MoonLoader } from 'react-spinners';

import * as S from './SupportSelectedArticle.styled';

const SupportSelectedArticle = () => {
  const { isSuccess, isError, isFetching, relatedArticles, article } = useGetArticle();

  return (
    <S.SupportSelectedArticle>
      <S.SubCategoryArticleCont>
        <ArticleNavbar>
          <S.NavTitle>Articulos en esta seccion</S.NavTitle>
          {!isFetching && isSuccess && !isError ? (
            <>
              {relatedArticles.map(({ title, folder_id, id }) => (
                <ArticleNavLink
                  key={`${folder_id}-${id}`}
                  ArticleNavLinkProps={{
                    text: title,
                    to: `/support/articles/${folder_id}/${id}/#article`,
                  }}
                />
              ))}
            </>
          ) : null}
          <MoonLoader color='#fff' size={50} speedMultiplier={0.5} loading={isFetching} />
        </ArticleNavbar>
      </S.SubCategoryArticleCont>
      <S.ArticleCont>
        {!isFetching && isSuccess && !isError ? (
          <>
            <S.Articletitle>{article?.title}</S.Articletitle>
            <S.Article dangerouslySetInnerHTML={{ __html: `${article?.description}` }} />
          </>
        ) : null}
        {isFetching ? (
          <S.SpinnerCont>
            <MoonLoader color='#fff' size={50} speedMultiplier={0.5} />
          </S.SpinnerCont>
        ) : null}
      </S.ArticleCont>
    </S.SupportSelectedArticle>
  );
};

export default SupportSelectedArticle;
