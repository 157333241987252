import * as S from './HomeBanner.styled';
import { WebPresentation } from 'infrastructure/components/';

const HomeBanner = () => {
  return (
    <S.Banner id='inicio'>
      <WebPresentation />
    </S.Banner>
  );
};

export default HomeBanner;
