import { createContext, CSSProperties, ReactElement } from 'react';
import * as S from './ClientInfo.styled';

interface Props {
  clientInfoProps?: ClientInfoProps;
  className?: string;
  style?: CSSProperties;
  children?: ReactElement | ReactElement[];
}

interface ClientInfoProps {
  name?: string;
  status?: number[] | null;
  identification?: string | false;
  email?: string;
  movil?: string;
  address?: string;
}

export const ClientInfoContext = createContext({} as ClientInfoProps);

export const ClientInfo = ({ clientInfoProps, className, style, children }: Props) => {
  const toContextValue: ClientInfoProps = clientInfoProps || {
    name: '',
    status: [],
    identification: '',
    email: '',
    movil: '',
    address: '',
  };

  return (
    <ClientInfoContext.Provider value={toContextValue}>
      <S.ClientInfo className={className} style={style}>
        {children}
      </S.ClientInfo>
    </ClientInfoContext.Provider>
  );
};
