import styled, { css } from 'styled-components';
import { ButtonProps } from 'react-html-props';

interface MainButtonProps extends ButtonProps {
  color: 'orange' | 'orangeGradient' | 'blue' | 'green' | 'none';
  borderRadius: 'round' | 'square';
  slimText?: boolean;
  useBorder?: boolean;
}

export const MainButton = styled.button.attrs((props: MainButtonProps) => {
  return props;
})`
  outline: none;
  border: none;
  font-size: 14px;
  padding: 10px 15px;
  text-transform: uppercase;
  font-family: inherit;
  font-weight: 500;
  cursor: pointer;
  color: var(--color-white-soft);
  border-radius: ${(props) => (props.borderRadius === 'round' ? '25px' : '15px')};
  ${({ color }) =>
    color === 'orange'
      ? css`
          background-color: var(--color-orange);
        `
      : color === 'orangeGradient'
      ? css`
          background: linear-gradient(90deg, rgba(255, 175, 81, 1) 0%, rgba(199, 73, 0, 1) 100%);
        `
      : color === 'blue'
      ? css`
          background-color: var(--color-blue-sea);
          border: solid 1px var(--color-white);
        `
      : color === 'green'
      ? css`
          background-color: transparent;
          color: var(--color-blue-dark);
          border: solid 2px #60cb60;
          padding: 15px 30px;
        `
      : css`
          background-color: transparent;
          box-shadow: inset 0px 0px 0px 1.5px var(--color-white-soft);
        `};

  ${({ slimText }) =>
    slimText &&
    css`
      font-weight: 400;
    `}

  @media screen and (min-width: 600px) {
    font-size: 20px;
    padding: 10px 20px;

    ${({ color }) =>
      color === 'green' &&
      css`
        padding: 10px 30px;
      `}
  }
`;
