import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import GlobalStyle from 'globalStyle';
import { store } from 'infrastructure/store';
import { Meta } from 'infrastructure/Meta';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';

createRoot(document.getElementById('root') as HTMLElement, {
  identifierPrefix: 'id-',
}).render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalStyle />
      <Meta
        author='IKUSAMEDIA'
        description='Tú navegación es nuestra responsabilidad'
        title='Wuipi telecomunicaciones'
      >
        <App />
      </Meta>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
