import { InstallationKitInfo } from 'infrastructure/components';
import { screwIcon, anthenaIcon, cableIcon, mastilIcon, workHand } from 'infrastructure/assets';

import { useInstallationKitSpringAnim } from './hooks';

import * as S from './InstallationKit.styled';

const InstallationKit = () => {
  const { mousePosListening, springDec1, springDec2, springDec3, springDec4, springDec5 } =
    useInstallationKitSpringAnim();

  return (
    <S.InstallationKit onMouseMove={mousePosListening}>
      <S.InnerCont>
        <InstallationKitInfo />
        <S.DecorationCont>
          <S.Decoration left={-20} top={40} size='medium' style={springDec1}>
            <S.Icons src={screwIcon} alt='screw' />
            <S.DecorationText top={110}>Tornillería</S.DecorationText>
          </S.Decoration>
          <S.Decoration left={180} top={-60} size='large' style={springDec2}>
            <S.Icons src={anthenaIcon} alt='anthena' />{' '}
            <S.DecorationText top={145}>Antena BEAMFORMING</S.DecorationText>
          </S.Decoration>
          <S.Decoration left={330} top={100} size='small' style={springDec3}>
            <S.Icons src={workHand} alt='work' />
            <S.DecorationText top={80}>Mano de obra</S.DecorationText>
          </S.Decoration>
          <S.Decoration left={270} top={270} size='large' style={springDec4}>
            <S.Icons src={mastilIcon} alt='mastil' />
            <S.DecorationText top={150}>Mástil de hasta 3 metros.</S.DecorationText>
          </S.Decoration>
          <S.Decoration left={20} top={290} size='medium' style={springDec5}>
            <S.Icons src={cableIcon} alt='cable' />
            <S.DecorationText top={110}>15 metros de cable UTP CAT 6 Ext </S.DecorationText>
            <S.DecorationText $soft top={138}>
              (Cableado adicional tiene <br /> un costo de $1 por metro)
            </S.DecorationText>
          </S.Decoration>
        </S.DecorationCont>
      </S.InnerCont>
    </S.InstallationKit>
  );
};

export default InstallationKit;
