import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const PanelNavbar = styled.nav`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin: 5px 0px 20px 0px;
  width: min(900px, 100%);

  & .active {
    background-color: #d77630;
  }

  & .active:hover {
    background-color: var(--color-orange);
  }

  & a:hover {
    background-color: var(--color-blue-deep);
  }

  @media screen and (min-width: 900px) {
    justify-content: center;
  }

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const NavLinks = styled(NavLink)`
  background-color: #384680;
  border-radius: 15px;
  margin: 5px 0px;
  transition: var(--transition);

  @media screen and (min-width: 900px) {
    margin: 5px 0px;
  }

  @media screen and (min-width: 1200px) {
    margin: 5px 20px;

    &:nth-child(1) {
      margin: 5px 20px 5px 0px;
    }

    &:nth-child(4) {
      margin: 5px 0px 5px 20px;
    }
  }
`;
