import styled from 'styled-components';

export const Support = styled.main``;

export const CategoriesInfoCont = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #09092a;
  padding: 50px 20px 0 20px;

  @media screen and (min-width: 600px) {
    padding: 50px 50px 50px 50px;
  }

  @media screen and (min-width: 1200px) {
    padding: 50px 100px 50px 100px;
  }
`;
