import { useEffect, useState } from 'react';
import { useSpring, config } from 'react-spring';
import { useAppSelector } from 'infrastructure/hooks';

interface Props {
  animContRef: React.MutableRefObject<any>;
}

const useIndividualServiceSpringAnim = ({ animContRef }: Props) => {
  const YScroll = useAppSelector((state) => state.animation.YOffset);
  const [animContYOffsetSpring, animContYOffsetSpringApi] = useSpring(() => ({
    YOffSet: 0,
  }));

  const [mousePos, setMousePos] = useState({
    x: 0,
    y: 0,
  });

  const [dec1Plx, SetDec1Plx] = useState({
    px: 0,
    py: 0,
  });
  const [dec2Plx, SetDec2Plx] = useState({
    px: 0,
    py: 0,
  });

  const mousePosListening = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const { left, top, height, width } = e.currentTarget.getBoundingClientRect();
    setMousePos({
      x: (e.clientX - left) / width - 0.5,
      y: (e.clientY - top) / height - 0.5,
    });
  };

  const springDec1 = useSpring({
    transform: `translate(calc(-50% + ${dec1Plx.px}px),calc(-50% + ${dec1Plx.py}px))`,
    config: config.slow,
  });

  const springDec2 = useSpring({
    transform: `translate(calc(-50% + ${dec2Plx.px}px),calc(-50% + ${dec2Plx.py}px))`,
    config: config.wobbly,
  });

  const [carMoveSpringStyles, carMoveSpringStylesApi] = useSpring(() => ({
    left: '-100%',
  }));

  //the final styles to be added

  carMoveSpringStylesApi.start(() => ({
    left: animContYOffsetSpring.YOffSet.to([-150, 0, 0 + 1], ['-100%', `0%`, `0%`]),
    config: config.gentle,
  }));

  useEffect(() => {
    SetDec1Plx({
      px: -mousePos.x * 60,
      py: -mousePos.y * 60,
    });
    SetDec2Plx({
      px: -mousePos.x * 120,
      py: -mousePos.y * 120,
    });
  }, [mousePos]);

  //this will get the component container or component element position on load
  useEffect(() => {
    const { top, height } = animContRef.current?.getBoundingClientRect();
    const compToTop = top - 67.5;
    const compHalf = height / 2;
    const windowHalf = window.innerHeight / 2;

    const YOffSet = ((compToTop - windowHalf + compHalf) / height) * -100;

    if (YOffSet >= -150 && YOffSet <= 100) {
      animContYOffsetSpringApi.start({
        YOffSet,
      });
    }
  }, [animContRef, animContYOffsetSpringApi, YScroll]);

  return { mousePosListening, springDec1, springDec2, carMoveSpringStyles };
};

export default useIndividualServiceSpringAnim;
