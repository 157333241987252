import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { GoCheck } from 'react-icons/go';

export const CheckMark = styled.div`
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  width: 28px;
  height: 28px;
  background-color: var(--color-white);
  border: 2px solid #60cb60;
  border-radius: 50%;
`;

export const PaymentMethodChoice = styled.div`
  width: 100px;
  height: 100px;

  & .active {
    background-color: #60cb6090;

    & ${CheckMark} {
      display: flex;
    }
  }

  & div[data-active='true'] {
    background-color: #60cb6090;

    & ${CheckMark} {
      display: flex;
    }
  }

  @media screen and (min-width: 600px) {
    &:last-child {
      border-bottom: 1px solid #b7b7b7;
    }
  }
`;

export const Check = styled(GoCheck)`
  color: #60cb60;
`;

export const InnerCont = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #b7b7b7;
  cursor: pointer;

  @media screen and (min-width: 600px) {
    border-bottom: none;
  }
`;

export const NavLinks = styled(NavLink)`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #b7b7b7;
  border-bottom: none;
`;

export const Icon = styled.img`
  max-width: 50px;
  height: auto;
`;
