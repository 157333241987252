import {
  PaymentMethodInfo as PaymentMethodInfoHOC,
  PaymentMethodInfoContext,
} from './PaymentMethodInfo';

import PaymentMethodInfoTitle from 'infrastructure/components/atoms/PaymentMethodInfoTitle/PaymentMethodInfoTitle';
import PaymentMethodInfoSummary from 'infrastructure/components/atoms/PaymentMethodInfoSummary/PaymentMethodInfoSummary';
import PaymentMethodCredentials from 'infrastructure/components/atoms/PaymentMethodCredentials/PaymentMethodCredentials';

export {
  PaymentMethodInfoContext,
  PaymentMethodInfoTitle,
  PaymentMethodInfoSummary,
  PaymentMethodCredentials,
};

export const PaymentMethodInfo = Object.assign(PaymentMethodInfoHOC, {
  Title: PaymentMethodInfoTitle,
  Summary: PaymentMethodInfoSummary,
  Credentials: PaymentMethodCredentials,
});

export default PaymentMethodInfo;
