import { ClientInfo as ClientInfoHOC, ClientInfoContext } from './ClientInfo';

//HOC component, dependencies components imports
import { default as ClientName } from 'infrastructure/components/atoms/ClientName/ClientName';
import { default as ClientStatus } from 'infrastructure/components/atoms/ClientStatus/ClientStatus';
import { default as ClientIdentification } from 'infrastructure/components/atoms/ClientIdentification/ClientIdentification';
import { default as ClientEmail } from 'infrastructure/components/atoms/ClientEmail/ClientEmail';
import { default as ClientMovil } from 'infrastructure/components/atoms/ClientMovil/ClientMovil';
import { default as ClientAddress } from 'infrastructure/components/atoms/ClientAddress/ClientAddress';

export {
  ClientInfoContext,
  ClientName,
  ClientStatus,
  ClientIdentification,
  ClientEmail,
  ClientMovil,
  ClientAddress,
};

export const CategoryCard = Object.assign(ClientInfoHOC, {
  Name: ClientName,
  Status: ClientStatus,
  Identification: ClientIdentification,
  Email: ClientEmail,
  Movil: ClientMovil,
  Address: ClientAddress,
});

export default CategoryCard;
