import { ClientInfoContext } from 'infrastructure/components/molecules/ClientInfo/ClientInfo';
import { useContext } from 'react';
import { CSSProperties } from 'styled-components';
import * as S from './ClientIdentification.styled';

interface Props {
  clientIdCardProps?: ClientIdCardProps;
  className?: string;
  style?: CSSProperties;
}

interface ClientIdCardProps {
  idInfo: string;
}

const ClientIdCard = ({ clientIdCardProps, className, style }: Props) => {
  const { identification } = useContext(ClientInfoContext);
  const identificationToShow: string = clientIdCardProps?.idInfo || identification || '---------';

  return (
    <S.ClientIdCard className={className} style={style}>
      <S.Title>Cédula</S.Title>
      <S.Identification>{identificationToShow}</S.Identification>
    </S.ClientIdCard>
  );
};

export default ClientIdCard;
