import styled from 'styled-components';
import { MdWifiTethering } from 'react-icons/md';
import { FaCloudsmith } from 'react-icons/fa';
import { BsHeadset } from 'react-icons/bs';

export const Information = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--color-white);
`;

export const InformationInnerCont = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  padding: 60px 0px;
  min-height: 42.5vh;
  width: min(100%, 1920px);

  & div:nth-child(3) {
    margin-bottom: 0;
  }

  @media screen and (min-width: 1920px) {
    min-height: auto;
    height: 550px;
  }
`;

export const SignalIcon = styled(MdWifiTethering)`
  color: var(--color-blue-deep);
  font-size: 80px;
  margin-bottom: 60px;
`;

export const CloudIcon = styled(FaCloudsmith)`
  color: var(--color-blue-deep);
  font-size: 80px;
  margin-bottom: 60px;
`;

export const HeadphonesIcon = styled(BsHeadset)`
  color: var(--color-blue-deep);
  font-size: 80px;
  margin-bottom: 60px;
`;
