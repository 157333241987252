import * as S from './Information.styled';
import { InfoCard } from 'infrastructure/components';

export const Information = () => {
  return (
    <S.Information>
      <S.InformationInnerCont>
        <InfoCard text='Brindamos soluciones de conectividad de banda ancha inalámbrica que incorporan tecnología innovadora para garantizar un rendimiento de servicio único.'>
          <S.SignalIcon />
        </InfoCard>
        <InfoCard text='Atención al cliente cercana y personalizada que garantiza respuestas inmediatas a cualquier solicitud, además de información por distintos canales sobre el estatus de la red en tiempo real.'>
          <S.HeadphonesIcon />
        </InfoCard>
        <InfoCard text='Nuestros planes Beam son esenciales para realizar diversas actividades tanto en tu hogar como en tu oficina. Adquiere el mejor que se adapte a tus necesidades a partir de $16 + IVA'>
          <S.CloudIcon />
        </InfoCard>
      </S.InformationInnerCont>
    </S.Information>
  );
};

export default Information;
