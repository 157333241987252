import { useAppSelector } from 'infrastructure/hooks';
import { useEffect, useMemo } from 'react';
import { useSprings, config, useSpring } from 'react-spring';

interface Props {
  animContRef: React.MutableRefObject<any>;
}

const useCircleSpringAnim = ({ animContRef }: Props) => {
  const YScroll = useAppSelector((state) => state.animation.YOffset);

  const [animContYOffsetSpring, animContYOffsetSpringApi] = useSpring(() => ({
    YOffSet: 0,
  }));

  const circleSpringInitialStyle = [
    {
      transform: 'translate(-150%, -50%)',
    },
    {
      transform: 'translate(-250%, -50%) ',
    },
    {
      transform: 'translate(250%, -50%) ',
    },
    {
      transform: 'translate(250%, -50%) ',
    },
  ];

  const [circleSpringStyles, circleSpringStylesApi] = useSprings(4, (index) => ({
    ...circleSpringInitialStyle[index],
  }));

  //the final styles to be added
  const circleSpringFinalStyle = useMemo(
    () => [
      {
        transform: animContYOffsetSpring.YOffSet.to(
          [-40 - 1, -40, -35, -35 + 1],
          [
            'translate(-150%, -50%)',
            'translate(-150%, -50%)',
            'translate(-50%, -50%)',
            'translate(-50%, -50%)',
          ]
        ),

        config: config.wobbly,
      },
      {
        transform: animContYOffsetSpring.YOffSet.to(
          [-20 - 1, -20, -15, -15 + 1],
          [
            'translate(-250%, -50%)',
            'translate(-250%, -50%)',
            'translate(-50%, -50%)',
            'translate(-50%, -50%)',
          ]
        ),
        config: config.wobbly,
      },
      {
        transform: animContYOffsetSpring.YOffSet.to(
          [-45 - 1, -45, -40, -40 + 1],
          [
            'translate(250%, -50%)',
            'translate(250%, -50%)',
            'translate(-50%, -50%)',
            'translate(-50%, -50%)',
          ]
        ),

        config: config.gentle,
      },
      {
        transform: animContYOffsetSpring.YOffSet.to(
          [-10 - 1, -10, -5, -5 + 1],
          [
            'translate(250%, -50%)',
            'translate(250%, -50%)',
            'translate(-50%, -50%)',
            'translate(-50%, -50%)',
          ]
        ),
        config: config.gentle,
      },
    ],
    [animContYOffsetSpring]
  );

  //final style used
  circleSpringStylesApi.start((index) => ({
    ...circleSpringFinalStyle[index],
  }));

  //this will get the component container or component element position everytime the scroll move
  useEffect(() => {
    const { top, height } = animContRef.current?.getBoundingClientRect();
    const compToTop = top - 67.5;
    const compHalf = height / 2;
    const windowHalf = window.innerHeight / 2;

    const YOffSet = ((compToTop - windowHalf + compHalf) / height) * -100;

    if (YOffSet >= -100 && YOffSet <= 100) {
      animContYOffsetSpringApi.start({
        YOffSet,
      });
    }
  }, [animContRef, animContYOffsetSpringApi, YScroll]);

  return { circleSpringStyles };
};

export default useCircleSpringAnim;
