import * as S from './SupportArticleTitle.styled';

interface Props {
  SupportArticleTitleProps?: SupportArticleTitleProps;
}

interface SupportArticleTitleProps {
  title: string | false;
}

const SupportArticleTitle = ({ SupportArticleTitleProps }: Props) => {
  let titleToShow;

  if (SupportArticleTitleProps) {
    titleToShow = SupportArticleTitleProps.title;
  } else {
    titleToShow = 'Lorem ipsum dolor sit amet.';
  }

  return <S.SupportArticleTitle>{titleToShow}</S.SupportArticleTitle>;
};

export default SupportArticleTitle;
