import { LazyExoticComponent, lazy } from 'react';
import { Home } from 'infrastructure/pages';
import {
  PopularArticles,

  // ClientInvoice,
  // PaymentMethodZelle,
  // PaymentMethodBofa,
  // PaymentMethodBanesco,
  // PaymentMethodBolivares,
  // PaymentMethodCash,
  // PaymentMethodPaypal,
} from 'infrastructure/components';
import { LayoutRoot, LayoutMain } from 'infrastructure/layout';

type ReactComponent = () => JSX.Element;

interface RoutesProps {
  path: string;
  Component: LazyExoticComponent<ReactComponent> | ReactComponent;
  onlyAllowAccessTo?: string[];
  nestedRoutes?: RoutesProps[];
  routeMetaData?: {
    contentType: string;
    contentId: string;
    fbqEventName: string;
  };
}

//This are the sub routes of the Support component, and are represented as a outlet in the Support component
const articlesRoutes: RoutesProps[] = [
  {
    path: '',
    Component: PopularArticles,
  },
  {
    path: ':categoryId',
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: "lazySelectedCategory"  */ 'infrastructure/components/templates/SupportSelectedCategory/SupportSelectedCategory'
        )
    ),
  },
  {
    path: 'articles/:subCategoryId/:articleId',
    Component: lazy(
      () =>
        import(
          /*  webpackChunkName: "lazySelectedArticle" */ 'infrastructure/components/templates/SupportSelectedArticle/SupportSelectedArticle'
        )
    ),
  },
];

//This are the sub routes of the LayoutMain component, and are represented as a outlet in the LayoutMain component
const homeRoutes: RoutesProps[] = [
  {
    path: '/',
    Component: Home,
    routeMetaData: {
      contentId: 'home',
      contentType: 'home',
      fbqEventName: 'ViewContent',
    },
  },
  {
    path: '/thanks-contact',
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: "lazySupport"  */ 'infrastructure/components/templates/ContactThanks/ContactThanks'
        )
    ),
    routeMetaData: {
      contentId: 'thanks-content',
      contentType: 'thanks-content',
      fbqEventName: 'ViewContent',
    },
  },
  {
    path: '/thanks-service',
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: "lazySupport"  */ 'infrastructure/components/templates/ContactThanks/ContactThanks'
        )
    ),
    routeMetaData: {
      contentId: 'thanks-service',
      contentType: 'thanks-service',
      fbqEventName: 'ViewContent',
    },
  },
  {
    path: '/support',
    Component: lazy(
      () => import(/* webpackChunckName: "lazySupport" */ 'infrastructure/pages/Support/Support')
    ),
    nestedRoutes: articlesRoutes,
    routeMetaData: {
      contentId: 'support',
      contentType: 'support',
      fbqEventName: 'ViewContent',
    },
  },
  {
    path: '/service',
    Component: lazy(
      () => import(/* webpackChunckName: "lazySupport" */ 'infrastructure/pages/Services/Services')
    ),
    nestedRoutes: articlesRoutes,
    routeMetaData: {
      contentId: 'service',
      contentType: 'service',
      fbqEventName: 'ViewContent',
    },
  },
];

// const PaymentsRoutes: RoutesProps[] = [
//   { path: 'zelle', Component: PaymentMethodZelle },
//   { path: 'bofa', Component: PaymentMethodBofa },
//   {
//     path: 'banesco',
//     Component: PaymentMethodBanesco,
//   },
//   {
//     path: 'bolivares',
//     Component: PaymentMethodBolivares,
//   },
//   {
//     path: 'cash',
//     Component: PaymentMethodCash,
//   },
//   {
//     path: 'paypal',
//     Component: PaymentMethodPaypal,
//   },
// ];

//This are the sub routes of the LayoutClients component, and are represented as a outlet in the LayoutClients component
// const ClientsRoutes: RoutesProps[] = [
//   {
//     path: 'invoice',
//     Component: ClientInvoice,
//   },
//   {
//     path: 'invoice/:invoiceId/:amountTotal',
//     Component: lazy(
//       () =>
//         import(
//           /* webpackChunckName: "lazyPayOrReport" */ 'infrastructure/components/organism/ClientPayOrReport/ClientPayOrReport'
//         )
//     ),
//   },
//   {
//     path: 'payments',
//     Component: lazy(
//       () =>
//         import(
//           /* webpackChunckName: "lazySupport" */ 'infrastructure/components/organism/ClientsPaymentMethods/ClientsPaymentMethods'
//         )
//     ),
//     nestedRoutes: PaymentsRoutes,
//   },
//   {
//     path: 'client-plans',
//     Component: lazy(
//       () =>
//         import(
//           /* webpackChunckName: "lazySupport" */ 'infrastructure/components/organism/ClientPlans/ClientsPlans'
//         )
//     ),
//   },
//   {
//     path: 'client-support',
//     Component: lazy(
//       () =>
//         import(
//           /* webpackChunckName: "lazySupport" */ 'infrastructure/components/organism/ClientsSupport/ClientsSupport'
//         )
//     ),
//   },
// ];

//This are the sub routes of the LayoutRoot, and are represented as a outlet in the LayoutRoot component
const mainRoutes: RoutesProps[] = [
  // {
  //   path: '/sign-in',
  //   Component: lazy(
  //     () =>
  //       import(/* webpackChunckName: "lazySignIn" */ 'infrastructure/layout/SignIn/LayoutSignIn')
  //   ),
  // },
  // {
  //   path: '/reset-pwd/:userId/:userToken',
  //   Component: lazy(
  //     () =>
  //       import(
  //         /* webpackChunckName: "lazySignIn" */ 'infrastructure/layout/PasswordReset/LayoutPasswordReset'
  //       )
  //   ),
  // },
  // {
  //   path: '/password-recovery',
  //   Component: lazy(
  //     () =>
  //       import(
  //         /* webpackChunckName: "lazySignIn" */ 'infrastructure/layout/PasswordRecovery/LayoutPasswordRecovery'
  //       )
  //   ),
  // },
  // {
  //   path: '/finish-sign-up',
  //   Component: lazy(
  //     () =>
  //       import(
  //         /* webpackChunckName: "lazySignIn" */ 'infrastructure/layout/FinishSignup/LayoutFinishSignup'
  //       )
  //   ),
  // },
  // {
  //   path: '/unauthorized',
  //   Component: lazy(
  //     () =>
  //       import(
  //         /* webpackChunckName: "lazyUnauthorized" */ 'infrastructure/layout/Unauthorized/LayoutUnauthorized'
  //       )
  //   ),
  // },
  // {
  //   path: '/clients',
  //   Component: lazy(
  //     () =>
  //       import(/* webpackChunckName: "lazyClients" */ 'infrastructure/layout/Clients/LayoutClients')
  //   ),
  //   onlyAllowAccessTo: [`${process.env.REACT_APP_ROLE7}`, `${process.env.REACT_APP_ROLE6}`],
  //   nestedRoutes: ClientsRoutes,
  // },
  {
    path: '/',
    Component: LayoutMain,
    nestedRoutes: homeRoutes,
  },
];

//this are the root routes for the application.
const rootRoutes: RoutesProps[] = [
  {
    path: '/',
    Component: LayoutRoot,
    nestedRoutes: mainRoutes,
  },
];

export default rootRoutes;
