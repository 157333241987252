import { animated } from 'react-spring';
import styled from 'styled-components';

export const Products = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  min-height: calc(100vh - 121px);
  padding: 60px 0;
  overflow-x: hidden;

  @media screen and (min-width: 1920px) {
    min-height: auto;
    height: min(100vh, 800px);
  }
`;

export const InnerCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(1710px, 86%);
  height: 100%;

  & h2 {
    text-align: center;
  }

  @media screen and (min-width: 1920px) {
    height: auto;
  }
`;

export const ContentCont = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin-top: 50px;
  width: min(1000px, 100%);

  @media screen and (min-width: 900px) {
    justify-content: space-between;
    margin-top: 100px;
  }
`;

export const ProductImgCont = styled.div`
  position: relative;
  width: min(350px, 100%);
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 40px;

  @media screen and (min-width: 900px) {
    margin-bottom: 0px;
  }
`;

export const ImgDetailBlue = styled(animated.div)`
  position: absolute;
  left: 0;
  top: 0;
  width: 220px;
  height: 220px;
  background-color: var(--color-blue-sea);
  border-radius: 60px;
`;

export const ImgDetailOrange = styled(animated.div)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 150px;
  height: 150px;
  background-color: var(--color-orange);
  border-radius: 40px;
`;

export const ProductImg = styled(animated.img)`
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  image-rendering: auto;
  filter: drop-shadow(0px 15px 8px rgba(0, 0, 0, 0.17));
`;
