import styled from 'styled-components';

export const ClientsDashboard = styled.div`
  width: 100%;
  height: 100%;
  display: grid;

  grid:
    [row1-start] 'clientInfo' [row1-end]
    [row2-start] 'clientInvoice' [row2-end]
    / auto;

  @media screen and (min-width: 900px) {
    grid:
      [row1-start] 'clientInfo clientInvoice' [row1-end]
      / 4.5fr 5.5fr;
  }

  @media screen and (min-width: 1200px) {
    grid:
      [row1-start] 'clientInfo clientInvoice' [row1-end]
      / 3.5fr 6.5fr;
  }
`;
