import moment from 'moment';
import { useEffect, useState } from 'react';

interface Props {
  date: string;
}

const useFormatDate = ({ date }: Props) => {
  const [newDate, setNewDate] = useState(date);

  const formatDate = (date: string) => {
    const formatedDate = moment(date).format('DD-MM-YYYY');
    setNewDate(formatedDate);
  };

  useEffect(() => {
    formatDate(date);
  }, [date]);

  return { newDate };
};

export default useFormatDate;
