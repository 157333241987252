import styled from 'styled-components';

export const ProductsInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: min(420px, 100%);
  padding-left: 15px;

  @media screen and (min-width: 1200px) {
    width: 480px;
    padding-left: 0;
  }
`;

export const MinorTitle = styled.h3`
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 20px;
  color: var(--color-blue-dark);
  text-align: right;

  @media screen and (min-width: 600px) {
    font-size: 50px;
  }
`;

export const ProductSpan = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: var(--color-blue-dark);
  text-align: right;
`;
