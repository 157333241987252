import { useRef } from 'react';
import { SectionTitle, ContactForm } from 'infrastructure/components';
import { fullSquare, emptySquare } from 'infrastructure/assets';
import { useSquareSpringAnim } from './hooks';

import * as S from './Contact.styled';

const Contact = () => {
  const animContRef = useRef(null);
  const { squareSpringStyles } = useSquareSpringAnim({ animContRef });

  return (
    <S.Contact id='contacto' ref={animContRef}>
      <S.FullSquare src={fullSquare} alt='decoration img' style={squareSpringStyles[0]} />
      <SectionTitle SectionTitleProps={{ text: 'CONTÁCTANOS', color: 'blue', weight: 'fat' }} />
      <S.Line />
      <S.Phrase>Déjanos tus dudas</S.Phrase>
      <ContactForm />
      <S.emptySquare src={emptySquare} alt='decoration img' style={squareSpringStyles[1]} />
    </S.Contact>
  );
};

export default Contact;
