import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const OurPlansInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: min(425px, 100%);
  margin-bottom: 30px;

  & h2 {
    text-align: center;
  }

  & p {
    text-align: start;
    width: 80%;
  }

  @media screen and (min-width: 600px) {
    margin-bottom: 0;
  }

  @media screen and (min-width: 900px) {
    & h2 {
      text-align: left;
    }
  }
`;

export const LinkTo = styled(Link)``;

export const PlanText = styled.p`
  margin: 0;
  color: var(--color-blue-dark);
  font-weight: 600;
`;

export const SpinnerCont = styled.div`
  width: 150px;
  margin-left: 40px;
`;
