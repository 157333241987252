import { useEffect, useState } from 'react';
import { useSpring, config } from 'react-spring';

const useProductsSpringAnim = () => {
  const [mousePos, setMousePos] = useState({
    x: 0,
    y: 0,
  });

  const [dec4Plx, SetDec4Plx] = useState({
    px: 0,
    py: 0,
  });

  const mousePosListening = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const { left, top, height, width } = e.currentTarget.getBoundingClientRect();
    setMousePos({
      x: (e.clientX - left) / width - 0.5,
      y: (e.clientY - top) / height - 0.5,
    });
  };

  const springDec4 = useSpring({
    transform: `translate(calc(${dec4Plx.px}px),calc(${dec4Plx.py}px))`,
    config: config.wobbly,
  });

  useEffect(() => {
    SetDec4Plx({
      px: -mousePos.x * 100,
      py: -mousePos.y * 100,
    });
  }, [mousePos]);

  return { mousePosListening, springDec4 };
};

export default useProductsSpringAnim;
