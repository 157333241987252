import styled from 'styled-components';

export const CategoryCardsSetCont = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CategoryCardsSet = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;

  @media screen and (min-width: 900px) {
    justify-content: space-between;
  }
`;

export const SeeMore = styled.button`
  align-self: center;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 18px;
  padding: 8px 25px;
  border-radius: 2px;
  margin-right: 5px;
  background-color: var(--color-orange);
  color: var(--color-white-soft);
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  margin-top: 20px;
`;

export const LoadingCont = styled.div`
  align-self: center;
`;
