import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const MobileNavbar = styled.div`
  display: flex;
  @media screen and (min-width: 900px) {
    display: none;
  }
`;

interface MobileNavbarFocusProps {
  show: boolean;
}

export const MobileNavbarFocus = styled.div<MobileNavbarFocusProps>`
  position: absolute;
  transition: var(--transition-slow);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  ${({ show }) => {
    return show
      ? css`
          background-color: hsl(0, 0%, 0%, 10%);
          backdrop-filter: blur(5px);
          left: 50%;
          width: 100%;
          height: 100%;
        `
      : css``;
  }};
`;

export const MobileNavLinkCont = styled.div<MobileNavbarFocusProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  padding: 200px 25px 60px 60px;
  background-color: var(--color-white-light);
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  height: 100%;
  transition: var(--transition-slow);
  z-index: 1;

  @media screen and (min-width: 600px) {
    padding: 200px 50px 60px 60px;
  }

  @media screen and (max-height: 600px) {
    padding: 120px 50px 10px 10px;
  }

  ${({ show }) => {
    return show
      ? css`
          right: 0px;
        `
      : css`
          right: -100%;
        `;
  }};
`;

export const Links = styled(Link)``;
