import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Header = styled.header`
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 0 20px;
  z-index: 100;
  min-height: 100%;
  width: min(100%, 1920px);

  @media screen and (min-width: 600px) {
    padding: 15px 50px 0 50px;
  }

  @media screen and (min-width: 1200px) {
    padding: 15px 100px 0 100px;
  }
`;

export const HeaderInnerCont = styled.div``;

export const LogoCont = styled.div`
  width: 60px;

  @media screen and (min-width: 900px) {
    width: 120px;
  }
`;

export const Links = styled(Link)``;
