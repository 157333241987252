import { useId } from 'react';
import { NavbarLink } from 'infrastructure/components';
import * as S from './NavbarFooterMobile.styled';

interface NavbarFooterMobileProps {
  links: string[];
}

const NavbarFooterMobile = ({ links }: NavbarFooterMobileProps) => {
  const id = useId();

  return (
    <S.NavbarFooterMobile>
      {links.map((link) => {
        return (
          <NavbarLink
            NavbarLinkProps={{ to: `/#${link}`, text: link, color: 'white' }}
            key={`${id}-${link}`}
          />
        );
      })}
    </S.NavbarFooterMobile>
  );
};

export default NavbarFooterMobile;
