import styled, { css } from 'styled-components';

interface MenuButtonProps {
  show: boolean;
}

export const UpperLine = styled.div<MenuButtonProps>`
  height: 4px;
  border-radius: 20px;
  position: absolute;
  background-color: var(--color-orange);
  transition: var(--transition);
  ${({ show }) =>
    show
      ? css`
          top: 39%;
          transform: rotate(135deg) translateY(-50%) translateX(3%);
          width: 90%;
        `
      : css`
          top: 0;
          transform: translateY(-50%);
          width: 90%;
        `}
`;

export const MiddleLine = styled.div<MenuButtonProps>`
  width: 70%;
  height: 4px;
  border-radius: 20px;
  background-color: whitesmoke;
  position: absolute;
  top: 50%;
  transition: var(--transition);
  ${({ show }) =>
    show
      ? css`
          transform: translateX(500%) translateY(-50%);
        `
      : css`
          transform: translateY(-50%);
        `}
`;

export const BottomLine = styled.div<MenuButtonProps>`
  height: 4px;
  border-radius: 20px;
  position: absolute;
  background-color: var(--color-orange);
  transition: var(--transition);
  ${({ show }) =>
    show
      ? css`
          top: 39%;
          transform: rotate(-135deg) translateY(-50%) translateX(-3%);
          width: 90%;
        `
      : css`
          top: 100%;
          transform: translateY(-50%);
          width: 100%;
        `}
`;

export const MenuButton = styled.button`
  height: 25px;
  width: 50px;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 2;
`;
