import styled from 'styled-components';

export const CategoryCardSummary = styled.p`
  color: var(--color-white-soft);
  text-align: end;
  font-size: 22px;
  word-wrap: anywhere;
  padding-left: 10px;
  margin: 0;
  margin-bottom: 20px;

  @media screen and (min-width: 600px) {
    font-size: 24px;
    word-wrap: normal;
  }
`;
