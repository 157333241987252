import { useCallback } from 'react';
import { useAppDispatch } from 'infrastructure/hooks';
import { setYOffsetSpring } from 'infrastructure/store/slices/animations';

const useScrollWatcher = () => {
  const dispatch = useAppDispatch();

  const scrollWatcher = useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      //get the scroll position of the selected container
      const YOffset = event.currentTarget.scrollTop;
      dispatch(setYOffsetSpring(YOffset));
    },
    [dispatch]
  );

  return { scrollWatcher };
};

export default useScrollWatcher;
