import { useRef } from 'react';
import { SectionTitle, ServiceRequestForm } from 'infrastructure/components';
import { fullCircle, emptyCircle } from 'infrastructure/assets';
import { useSquareSpringAnim } from './hooks';

import * as S from './ServiceRequest.styled';

const ServiceRequest = () => {
  const animContRef = useRef(null);
  const { circleSpringStyles } = useSquareSpringAnim({ animContRef });

  return (
    <S.ServiceRequest id='contacto' ref={animContRef}>
      <S.EmptyCircle
        src={emptyCircle}
        alt='decoration img'
        style={circleSpringStyles[0]}
        size='large'
        top='40%'
        left='0%'
      />

      <S.FullCircle
        src={fullCircle}
        alt='decoration img'
        style={circleSpringStyles[1]}
        size='medium'
        top='75%'
        left='0%'
      />
      <SectionTitle
        SectionTitleProps={{ text: 'SOLICITUD DE SERVICIO', color: 'blue', weight: 'fat' }}
      />
      <S.Line />
      <S.Phrase>
        Para dar inicio al proceso de solicitud es necesario responder con los siguientes datos, a
        fin de gestionar el mismo:
      </S.Phrase>
      <ServiceRequestForm />
      <S.FullCircle
        src={fullCircle}
        alt='decoration img'
        style={circleSpringStyles[2]}
        size='medium'
        top='25%'
        left='95%'
      />

      <S.EmptyCircle
        src={emptyCircle}
        alt='decoration img'
        style={circleSpringStyles[3]}
        size='medium'
        top='65%'
        left='95%'
      />
    </S.ServiceRequest>
  );
};

export default ServiceRequest;
