import { CSSProperties } from 'react';
import * as S from './WebSubTitle.styled';

interface WebSubTitleProps {
  text: string;
  style?: CSSProperties;
  className?: string;
}

const WebSubTitle = ({ text, ...restProps }: WebSubTitleProps) => {
  return <S.WebSubTitle {...restProps}>{text}</S.WebSubTitle>;
};

export default WebSubTitle;
