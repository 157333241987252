import { ClientInfoContext } from 'infrastructure/components/molecules/ClientInfo/ClientInfo';
import { useContext } from 'react';
import * as S from './ClientStatus.styled';

interface Props {
  clientsStatus?: ClientsStatus;
}

interface ClientsStatus {
  status: number[];
}

const ClientStatus = ({ clientsStatus }: Props) => {
  const { status } = useContext(ClientInfoContext);
  const statusToShow: number[] = clientsStatus?.status || status || [4];

  return (
    <S.ClientStatus>
      <S.Title>Estado</S.Title>
      <S.IsActiveCont>
        <S.Dot status={statusToShow[0]} />

        <S.Status>
          {statusToShow[0] === 1
            ? 'Activo'
            : statusToShow[0] === 2
            ? 'Suspendido'
            : statusToShow[0] === 3
            ? 'Retirado'
            : '-------'}
        </S.Status>
      </S.IsActiveCont>
    </S.ClientStatus>
  );
};

export default ClientStatus;
