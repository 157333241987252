import styled from 'styled-components';

export const Clients = styled.main`
  height: 100%;
  overflow-y: auto;

  scrollbar-color: #555 #fff;
  scrollbar-width: thin;

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 1px;
  }
`;
