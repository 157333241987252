import styled from 'styled-components';

export const ClientIdCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
`;

export const Title = styled.h4`
  font-size: 20px;
  color: var(--color-blue-dark);
`;

export const Identification = styled.span`
  font-size: 18px;
  margin-top: 10px;
  color: var(--color-blue-dark);
`;
