import styled, { css } from 'styled-components';

export const PlansChoice = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  /* border-bottom: 1px solid var(--color-blue-dark); */
  padding: 15px 0px;
  margin-bottom: 15px;
  width: 100%;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const RadioCont = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (min-width: 600px) {
    margin-bottom: 0px;
  }
`;

export const Radio = styled.input`
  height: 18px;
  width: 18px;
  margin-right: 15px;
  cursor: pointer;
  z-index: 2;
`;

export const Label = styled.label`
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--color-blue-dark);
  width: max-content;
`;

interface OptionalInfoProps {
  selected?: boolean;
}

export const OptionalInfo = styled.span<OptionalInfoProps>`
  display: block;
  min-width: max-content;
  margin-left: 20px;
  margin-right: 15px;
  z-index: 2;
  transition: var(--transition-slow);

  ${({ selected }) =>
    selected
      ? css`
          color: #0060df;
          text-shadow: 1px 1px 5px #0060df;
        `
      : css`
          color: var(--color-blue-dark);
        `}

  @media screen and (min-width: 600px) {
    ${({ selected }) =>
      selected
        ? css`
            color: var(--color-white-soft);
          `
        : css`
            color: var(--color-blue-dark);
          `}
  }
`;

interface BlueEffectProps {
  selected?: boolean;
}

export const BlueEffect = styled.div<BlueEffectProps>`
  display: none;
  position: absolute;
  z-index: 1;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #0060df;
  transition: var(--transition);

  @media screen and (min-width: 600px) {
    display: block;
    ${({ selected }) =>
      selected
        ? css`
            right: 0%;
            width: 50%;
            height: 25px;
            border-radius: 15px;
          `
        : css`
            right: 100%;
            opacity: 0;
          `}
  }
`;
