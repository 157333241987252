import styled from 'styled-components';

export const PlansAndPricesBadge = styled.div`
  background-color: #f46800;
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 180px;
  border-radius: 30px;
  margin-bottom: 30px;
  color: var(--color-white);
  padding: 20px 10px;

  @media screen and (min-width: 600px) {
    padding: 10px 50px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 480px;
    height: 110px;
  }
`;

export const PlanNameCont = styled.div`
  height: 50%;
  width: 100%;
  border-radius: 30px 30px 0 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 600px) {
    align-items: flex-start;
  }
`;

export const PlanName = styled.p`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 26px;
  display: inline-block;
  margin-bottom: 5px;

  @media screen and (min-width: 600px) {
    font-size: 30px;
  }
`;

export const PlanDescription = styled.span`
  font-size: 18px;
  display: inline-block;

  @media screen and (min-width: 600px) {
    font-size: 20px;
  }
`;

export const PlanPriceCont = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Price = styled.p`
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0px;

  @media screen and (min-width: 600px) {
    font-size: 36px;
  }
`;
