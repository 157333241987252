import { createContext, CSSProperties, ReactElement } from 'react';
import * as S from './CategoryCard.styled';

interface Props {
  CategoryCardProps?: CategoryCardProps;
  to: string;
  className?: string;
  style?: CSSProperties;
  children?: ReactElement | ReactElement[];
}

interface CategoryCardProps {
  title: string;
  summary: string;
  createdAt: string;
}

export const CategoryCardContext = createContext({} as CategoryCardProps);

export const CategoryCard = ({ CategoryCardProps, to, children, ...restProps }: Props) => {
  const toContextValue: CategoryCardProps = CategoryCardProps || {
    title: '',
    summary: '',
    createdAt: '',
  };

  return (
    <CategoryCardContext.Provider value={toContextValue}>
      <S.CategoryCard to={to} {...restProps}>
        {children}
      </S.CategoryCard>
    </CategoryCardContext.Provider>
  );
};
