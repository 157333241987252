import { ReactElement } from 'react';
import * as S from './ArticleNavbar.styled';

interface Props {
  className?: string;
  style?: object;
  children?: ReactElement | ReactElement[] | any;
}

const ArticleNavbar = ({ children }: Props) => {
  return <S.ArticleNavbar>{children}</S.ArticleNavbar>;
};

export default ArticleNavbar;
