import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface homeStateInitialState {
  YOffset: number;
}

const initialState: homeStateInitialState = {
  YOffset: 0,
};

export const AnimationSlice = createSlice({
  name: 'animation',
  initialState,
  reducers: {
    setYOffsetSpring: (state, action: PayloadAction<number>) => {
      state.YOffset = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setYOffsetSpring } = AnimationSlice.actions;
