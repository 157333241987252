import styled, { css } from 'styled-components';

interface OurPlansLabelProps {
  fill: 'filled' | 'empty';
}

export const OurPlansLabel = styled.div<OurPlansLabelProps>`
  padding: 6px 12px;
  border-radius: 12px;
  cursor: pointer;
  transition: var(--transition);

  ${({ fill }) =>
    fill === 'filled'
      ? css`
          color: var(--color-white-soft);
          background-color: var(--color-orange);
        `
      : css`
          color: var(--color-blue-dark);
        `}
`;

export const OurPlansLabelText = styled.span`
  font-size: 18px;
  font-weight: 500;
`;
