import { useEffect, useState } from 'react';

interface FormInputProps {
  id: string;
  name: string;
  placeholder?: string;
  type: string;
  required?: boolean;
  label?: string;
  min?: number;
  max?: number;
  value?: string | number;
  errorMessage?: string;
  disabled?: boolean;
  isValidating?: boolean;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const useInput = (props: FormInputProps) => {
  const { label, errorMessage, isValidating, ...inputProps } = props;

  const [focused, setFocused] = useState(false);

  const handleFocusedBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocused(true);
  };

  const handleFocusedFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    inputProps.name === 'confirmPassword' && setFocused(true);
  };

  useEffect(() => {
    if (!isValidating) {
      setFocused(false);
    }
  }, [isValidating]);

  return {
    label,
    errorMessage,
    inputProps,
    focused,
    handleFocusedBlur,
    handleFocusedFocus,
  };
};

export default useInput;
