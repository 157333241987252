import * as S from './MobileNavbar.styled';
import { MainButton, MenuButton, NavbarLink } from 'infrastructure/components';
import { useId } from 'react';
import { useState } from 'react';

import ReactGA from 'react-ga';

interface MobileNavbarProps {
  links: string[];
}

const MobileNavbar = ({ links }: MobileNavbarProps) => {
  const [show, setShow] = useState<boolean>(false);
  const id = useId();

  const gaClickCliente = () => {
    ReactGA.event({
      category: 'Botón',
      action: 'Click',
      label: 'Botón de cliente',
    });
  };

  const gaClickSupport = () => {
    ReactGA.event({
      category: 'Botón',
      action: 'Click',
      label: 'Botón de support',
    });
  };

  return (
    <S.MobileNavbar>
      <MenuButton setShow={() => setShow(!show)} show={show} />
      <S.MobileNavLinkCont show={show}>
        {links.map((link) => {
          return (
            <NavbarLink
              NavbarLinkProps={{
                to: `/#${link}`,
                text: link,
                color: 'black',
                size: 'large',
                onClick: () => setShow(false),
              }}
              key={`${id}-${link}`}
            />
          );
        })}

        <NavbarLink
          NavbarLinkProps={{
            to: '/support',
            text: 'soporte',
            color: 'orange',
            size: 'large',
            weight: 600,
            onClick: () => {
              setShow(false);
              gaClickSupport();
            },
          }}
        />

        {/* <S.Links to='/clients/invoice'> */}
        <S.Links onClick={gaClickCliente} to='https://clientes.wuipi.net/#/' rel='noreferrer'>
          <MainButton
            MainButtonProps={{
              text: 'cliente',
              color: 'orangeGradient',
              borderRadius: 'round',
              type: 'button',
            }}
          />
        </S.Links>
        {/* </S.Links> */}
      </S.MobileNavLinkCont>

      <S.MobileNavbarFocus show={show} tabIndex={-1} onClick={() => setShow(false)} />
    </S.MobileNavbar>
  );
};

export default MobileNavbar;
