import { useGetPopularArticlesQuery } from 'infrastructure/store/apis/support';
import { CategoryCard } from 'infrastructure/components';
import { MoonLoader } from 'react-spinners';

import * as S from './CategoryCardsSet.styled';

const CategoryCardsSet = () => {
  const { data, isSuccess, isError, isFetching } = useGetPopularArticlesQuery(null);

  return (
    <S.CategoryCardsSetCont>
      <S.CategoryCardsSet>
        {isSuccess && !isError ? (
          <>
            {data.map(({ id, folder_id, title, description_text, created_at }) => {
              return (
                <CategoryCard
                  to={`articles/${folder_id}/${id}/#article`}
                  CategoryCardProps={{
                    title,
                    summary: description_text,
                    createdAt: created_at,
                  }}
                  key={`${id}-${title}-${folder_id}`}
                >
                  <CategoryCard.Title />
                  <CategoryCard.Summary />
                  <CategoryCard.Date />
                </CategoryCard>
              );
            })}
          </>
        ) : null}
        {isError ? <div>ha ocurrido un error</div> : null}
      </S.CategoryCardsSet>
      {isFetching ? (
        <S.LoadingCont>
          <MoonLoader color='#fff' size={50} speedMultiplier={0.5} />
        </S.LoadingCont>
      ) : null}
    </S.CategoryCardsSetCont>
  );
};

export default CategoryCardsSet;
