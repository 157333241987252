import { Props } from './interfaces/interfaces';
import { useFormatAmounts } from './hooks';

import * as S from './InvoiceTableRow.styled';

const InvoiceTableRow = ({ invoiceTableRowProps, onClick, ...restProps }: Props) => {
  const {
    status,
    invoiceNumber,
    invoiceToCheck,
    currencyId,
    exchangeRate,
    amountTotal,
    amountTotalSigned,
    amountResidual,
    amountResidualSigned,
  } = invoiceTableRowProps;
  const { amount } = useFormatAmounts({
    currencyId,
    exchangeRate,
    amountTotal,
    amountTotalSigned,
    amountResidual,
    amountResidualSigned,
  });

  return (
    <S.InvoiceTableRow {...restProps} onClick={onClick}>
      <S.FirstColumn>
        <S.StatusSignal invoiceStatus={status} />
      </S.FirstColumn>
      <S.SecondColumn>Factura {invoiceNumber}</S.SecondColumn>
      <S.ThirdColumn invoiceStatus={status}>
        <S.ReportOrPayCont>
          <S.Links to={`${invoiceNumber}/${amount.USD}`}>
            <S.ReportOrPay>Reportar/Pagar Factura</S.ReportOrPay>
          </S.Links>
        </S.ReportOrPayCont>
      </S.ThirdColumn>
      <S.ForthColumn></S.ForthColumn>
      <S.FifthColumn>
        {status === 'not_paid'
          ? `(${amount.CVEF} Bs)  $${amount.USD}`
          : status === 'partial'
          ? `${amount.RVEF === null ? '' : `(${amount.VEF! - amount.RVEF!}Bs de ${amount.VEF}Bs) `} 
          
          $${amount.USD! - amount.RUSD!} de $${amount.USD}`
          : `${amount.VEF === null ? '' : `(${amount.VEF} Bs)`} $${amount.USD}`}{' '}
        ·{' '}
        {status === 'in_payment' || status === 'paid'
          ? 'Pagado'
          : status === 'partial'
          ? 'Pago parcial'
          : status === 'not_paid'
          ? 'Vencido'
          : '-----'}
      </S.FifthColumn>
      <S.SixthColumn>
        <S.Arrow data-selected={invoiceToCheck === invoiceNumber} />
      </S.SixthColumn>
    </S.InvoiceTableRow>
  );
};

export default InvoiceTableRow;
