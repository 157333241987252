import { CSSProperties } from 'react';
import { useInput } from './hooks';

import * as S from './FormInput.styled';

interface Props {
  FormInputProps: FormInputProps;
  style?: CSSProperties;
  className?: string;
}

interface FormInputProps {
  id: string;
  name: string;
  placeholder?: string;
  type: string;
  required?: boolean;
  label?: string;
  min?: number;
  max?: number;
  step?: string;
  value?: string | number;
  errorMessage?: string;
  disabled?: boolean;
  isValidating?: boolean;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormInput = ({ FormInputProps, ...rest }: Props) => {
  const { label, errorMessage, focused, inputProps, handleFocusedBlur, handleFocusedFocus } =
    useInput(FormInputProps);

  return (
    <S.FormInput {...rest} data-ischeckbox={inputProps.type === 'checkbox'}>
      <S.Label htmlFor={FormInputProps.id}>{label}</S.Label>
      <S.Input
        {...inputProps}
        onBlur={handleFocusedBlur}
        onFocus={handleFocusedFocus}
        data-focused={errorMessage && focused}
      />

      <S.Span>{errorMessage}</S.Span>
    </S.FormInput>
  );
};

export default FormInput;
