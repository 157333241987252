import styled from 'styled-components';

export const InvoiceTableRowWithDetails = styled.tr``;

export const InnerColumn = styled.td``;

export const InnerTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const InnerTableBody = styled.tbody``;
