import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const WebPresentation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ButtonCont = styled.div`
  display: flex;
  justify-content: space-between;
  width: min(270px, 90%);

  @media screen and (min-width: 600px) {
    width: 380px;
  }
`;

export const NetelipButtonCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 152px;

  & > img {
    max-width: 99%;
    height: auto;
    margin: 0 auto;
  }

  @media screen and (min-width: 600px) {
    width: 174px;
    border: 2px solid white;
    border-radius: 30px;

    & img {
      max-width: 99%;
      height: 99%;
    }
  }
`;

export const LinkTo = styled(Link)``;
