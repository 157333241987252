import { CategoryCard as CategoryCardHOC, CategoryCardContext } from './CategoryCard';

//HOC component, dependencies components imports
import CategoryCardTitle from 'infrastructure/components/atoms/CategoryCardTitle/CategoryCardTitle';
import CategoryCardSummary from 'infrastructure/components/atoms/CategoryCardSummary/CategoryCardSummary';
import CategoryCardDate from 'infrastructure/components/atoms/CategoryCardDate/CategoryCardDate';

export { CategoryCardContext, CategoryCardTitle, CategoryCardSummary, CategoryCardDate };

export const CategoryCard = Object.assign(CategoryCardHOC, {
  Title: CategoryCardTitle,
  Summary: CategoryCardSummary,
  Date: CategoryCardDate,
});

export default CategoryCard;
