import styled from 'styled-components';
import { animated } from 'react-spring';

export const ServiceRequest = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--color-white);
  min-height: calc(100vh - 121px);
  padding: 60px 20px;
  overflow: hidden;
`;

export const Line = styled.hr`
  background-color: var(--color-blue-sea);
  width: 85px;
  height: 3px;
  margin-bottom: 10px;
`;

export const Phrase = styled.p`
  font-size: 18px;
  margin-bottom: 0px;
  width: min(100%, 550px);
  text-align: center;
  color: var(--color-blue-dark);

  @media screen and (min-width: 900px) {
    margin-bottom: 20px;
  }
`;

interface EmptyCircleProps {
  size: 'small' | 'medium' | 'large';
  top: string;
  left: string;
}

export const EmptyCircle = styled(animated.img)<EmptyCircleProps>`
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};

  width: ${({ size }) =>
    size === 'large'
      ? '500px'
      : size === 'medium'
      ? '350px'
      : size === 'small'
      ? '200px'
      : '150px'};
  height: auto;
`;

interface FullCircleProps {
  size: 'small' | 'medium' | 'large';
  top: string;
  left: string;
}

export const FullCircle = styled(animated.img)<FullCircleProps>`
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};

  width: ${({ size }) =>
    size === 'large'
      ? '500px'
      : size === 'medium'
      ? '350px'
      : size === 'small'
      ? '200px'
      : '150px'};
  height: auto;
`;
