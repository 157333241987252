import styled from 'styled-components';

export const WebSubTitle = styled.h2`
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 40px;
  text-align: center;
  color: var(--color-white-soft);
  width: min(634px, 90%);
  letter-spacing: 0px;
  text-transform: none;

  @media screen and (min-width: 600px) {
    font-size: 24px;
  }

  @media screen and (min-width: 900px) {
    font-size: 26px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 30px;
  }
`;
