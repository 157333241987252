import * as S from './Home.styled';
import {
  HomeBanner,
  Information,
  Campaign,
  CheckSpeed,
  OurPlans,
  // OurApp,
  IndividualService,
  Support,
  Products,
  SmartHome,
  LocationMap,
  Contact,
} from 'infrastructure/components/';

const Home = () => {
  return (
    <S.Home data-component-name='Main Content'>
      <HomeBanner />
      <Information />
      <Campaign />
      <CheckSpeed />
      <OurPlans />
      {/* <OurApp /> */}
      <IndividualService />
      <Support />
      <Products />
      <SmartHome />
      <LocationMap />
      <Contact />
    </S.Home>
  );
};

export default Home;
