import { clientsApi } from 'infrastructure/store/apis/clients';

interface StandarResponseProps {
  status: 'idle' | 'loading' | 'success' | 'error';
  message: string;
}

interface SupportQuestionProps {
  subject: string;
  message: string;
  name: string;
  phoneNumber: string;
}

interface GetUserDataResponseProps extends StandarResponseProps {
  data: {
    id: number;
    name: string;
    display_name: string;
    phone_sanitized: string;
    is_company: boolean;
    email: string;
    contact_address_complete: string;
    credit: number;
    debit: number;
    total_invoiced: number;
    invoice_ids: number[];
    unpaid_invoices: number[];
    vat: string | false;
    cedula: string | false;
    total_due: number;
    total_overdue: number;
    category_id: number[];
    exchange_rate: number;
  };
}

interface ChangeSubscriptionProps {
  plan: string;
  name: string;
  phoneNumber: string;
  token: string;
}

export interface InvoiceItem {
  id: number | false;
  date: string | false;
  partner_id: [number, string] | false;
  invoice_date: string | false;
  invoice_date_due: string | false;
  invoice_line_ids: number[] | false;
  invoice_partner_display_name: string | false;
  narration: string | false;
  state: string | false;
  move_type: string | false;
  payment_state: 'not_paid' | 'in_payment' | 'paid' | 'partial' | false;
  amount_untaxed: number | false;
  amount_tax: number | false;
  amount_total: number | false;
  amount_total_signed: number | false;
  amount_residual: number | false;
  amount_residual_signed: number | false;
  total_due: number | false;
  exchange_rate: number | false;
  currency_id: [number, string];
  invoiced_lines: {
    id: number | false;
    partner_id: [number, string] | false;
    name: string | false;
    code: string | false;
    display_name: string | false;
    description: string | false;
    recurring_invoice_line_ids: number[] | false;
    recurring_interval: number | false;
    recurring_invoice_day: number | false;
    recurring_next_date: string | false;
    recurring_tax: number | false;
    recurring_total: number | false;
    recurring_total_incl: number | false;
    to_renew: boolean;
    total_due: number | false;
    plan: {
      id: number | false;
      code: string | false;
      name: string | false;
      display_name: string | false;
      invoice_state: string | false;
      type: string | false;
      recurring_invoice: boolean;
    };
  }[];
}

interface GetAllInvoicesResponseProps extends StandarResponseProps {
  contact: any;
  data: InvoiceItem[];
}

interface InvoicePaymentProps {
  paymentAmount: string;
  reference: string;
  paymentMethod: string;
  message: string;
  invoiceId: string;
  name: string;
  email: string;
  phoneNumber: string;
}

interface GetInvoicesLenghtProps extends StandarResponseProps {
  data: number;
}

interface ReportPaypalPaymentProps {
  PaymentInfo: string;
}

export const clientApi = clientsApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserData: builder.mutation<GetUserDataResponseProps, null>({
      query: () => ({
        url: '/contact',
        method: 'GET',
      }),
    }),

    getUserAllInvoices: builder.query<
      GetAllInvoicesResponseProps,
      { page: number; limit: number; petitionCategory: string }
    >({
      query: ({ page, limit, petitionCategory }) =>
        `/invoice?limit=${limit}&page=${page}&category=${petitionCategory}`,
      keepUnusedDataFor: 300,
    }),

    getInvoicesLength: builder.query<GetInvoicesLenghtProps, { petitionCategory: string }>({
      query: ({ petitionCategory }) => `/invoice/items?category=${petitionCategory}`,
      keepUnusedDataFor: 300,
    }),

    invoiceReportPayment: builder.mutation<StandarResponseProps, InvoicePaymentProps>({
      query: ({
        paymentAmount,
        reference,
        paymentMethod,
        message,
        invoiceId,
        name,
        email,
        phoneNumber,
      }) => ({
        url: '/sendgrid/payment',
        method: 'POST',
        body: {
          paymentAmount,
          reference,
          paymentMethod,
          message,
          invoiceId,
          name,
          email,
          phoneNumber,
        },
      }),
    }),

    supportQuestion: builder.mutation<StandarResponseProps, SupportQuestionProps>({
      query: ({ subject, message, name, phoneNumber }) => ({
        url: '/sendgrid/support',
        method: 'POST',
        body: { subject, message, name, phoneNumber },
      }),
    }),

    subscriptionChangeCode: builder.mutation<StandarResponseProps, null>({
      query: () => ({
        url: '/sendgrid/send-token',
        method: 'POST',
      }),
    }),

    changeSubscription: builder.mutation<StandarResponseProps, ChangeSubscriptionProps>({
      query: ({ plan, name, phoneNumber, token }) => ({
        url: '/sendgrid/subscription',
        method: 'POST',
        body: { plan, name, phoneNumber, token },
      }),
    }),

    reportPayment: builder.mutation<StandarResponseProps, SupportQuestionProps>({
      query: ({ subject, message }) => ({
        url: '/sendgrid/payment',
        method: 'POST',
        body: { subject, message },
      }),
    }),

    reportPaypalPayment: builder.mutation<StandarResponseProps, ReportPaypalPaymentProps>({
      query: ({ PaymentInfo }) => ({
        url: `/paypal`,
        method: 'POST',
        body: {
          ppToken: PaymentInfo,
        },
      }),
    }),
  }),
});

export const {
  useGetUserAllInvoicesQuery,
  useGetInvoicesLengthQuery,
  useInvoiceReportPaymentMutation,
  useSupportQuestionMutation,
  useGetUserDataMutation,
  useChangeSubscriptionMutation,
  useSubscriptionChangeCodeMutation,
  useReportPaymentMutation,
  useReportPaypalPaymentMutation,
} = clientApi;
