import * as S from './CheckSpeed.styled';
import { MainButton } from 'infrastructure/components';
import { bannerSpeedTest } from 'infrastructure/assets';

const CheckSpeed = () => {
  return (
    <S.CheckSpeed>
      <S.LeftBgImg src={bannerSpeedTest} alt='background style' />
      <S.Info>¿Sabes como medir la velocidad de tu conexión?</S.Info>
      <S.LinkTo to='/support/articles/73000559314/73000575193/#article'>
        <MainButton
          MainButtonProps={{
            text: 'VER ARTÍCULO',
            borderRadius: 'round',
            color: 'orange',
            type: 'button',
            slimText: true,
          }}
        />
      </S.LinkTo>

      <S.RightBgImg src={bannerSpeedTest} alt='background style' />
    </S.CheckSpeed>
  );
};

export default CheckSpeed;
