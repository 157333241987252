import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './slices/auth';
import { clientsSlice } from './slices/clients';
import { AnimationSlice } from './slices/animations';
import { authInterceptorApi } from './apis/auth';
import { supportApi } from './apis/support';
import { homeApi } from './apis/home';
import { telegramApi } from './apis/telegram';
import { clientApi } from './apis/clients';
import { serviceApi } from './apis/service';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    client: clientsSlice.reducer,
    animation: AnimationSlice.reducer,
    [authInterceptorApi.reducerPath]: authInterceptorApi.reducer,
    [supportApi.reducerPath]: supportApi.reducer,
    [homeApi.reducerPath]: homeApi.reducer,
    [clientApi.reducerPath]: clientApi.reducer,
    [telegramApi.reducerPath]: telegramApi.reducer,
    [serviceApi.reducerPath]: serviceApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authInterceptorApi.middleware,
      supportApi.middleware,
      homeApi.middleware,
      clientApi.middleware,
      telegramApi.middleware,
      serviceApi.middleware
    ),
  devTools: false,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
