import * as S from './OurPlans.styled';
import { OurPlansInfo } from 'infrastructure/components';
import ReactPlayer from 'react-player/lazy';
import { conectionWeb, conectionWebImg } from 'infrastructure/assets';

const OurPlans = () => {
  return (
    <S.OurPlans id='planes'>
      <S.InnerCont>
        <OurPlansInfo />

        <S.OurPlansVideo>
          <ReactPlayer
            url={conectionWeb}
            fallback={<S.VideoFallBackImg src={conectionWebImg} alt='fallback' />}
            width='100%'
            height='100%'
            playing
            playsinline
            loop
            volume={0}
            muted
            autoplay
            config={{
              file: {
                attributes: {
                  preload: 'auto',
                },
              },
            }}
          />
        </S.OurPlansVideo>
      </S.InnerCont>
    </S.OurPlans>
  );
};

export default OurPlans;
