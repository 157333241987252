import styled, { css } from 'styled-components';

export const LayoutMain = styled.div`
  position: relative;
  display: grid;
  grid-area: page;
  grid:
    [row1-start] 'header' 90px [row1-end]
    [row2-start] 'mainContent' calc(100vh - 135px) [row2-end]
    / 100%;
  min-height: 100%;
  overflow: hidden;
  justify-items: center;
`;

export const ContentCont = styled.div`
  grid-area: mainContent;
  overflow-y: auto;
  scroll-behavior: smooth;
  min-height: 100%;
  width: 100%;
  z-index: 1;

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--color-blue-sea);
    border-radius: 1px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 1px;
  }
`;

export const VideoCont = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(-45px + 100vh);
  width: 100%;

  @media screen and (max-height: 420px) {
    height: 140%;
  }
  @media screen and (max-height: 320px) {
    height: 150%;
  }

  & video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    height: 100%;
    width: 100%;
    object-fit: cover;

    @media screen and (max-height: 420px) {
      height: 140%;
    }
    @media screen and (max-height: 320px) {
      height: 150%;
    }
  }
`;

export const FallbackImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  outline: none;
  object-fit: cover;

  @media screen and (max-height: 420px) {
    height: 140%;
  }
  @media screen and (max-height: 320px) {
    height: 150%;
  }
`;

export const VideoBackground = styled.video`
  outline: none;
  height: 100%;
  width: 100%;
  object-fit: cover;

  @media screen and (max-height: 420px) {
    height: 140%;
  }
  @media screen and (max-height: 320px) {
    height: 150%;
  }
`;

export const ChatBot = styled.img`
  position: absolute;
  max-width: 60px;
  height: auto;
  bottom: 5%;
  right: 5%;
  z-index: 1;
  cursor: pointer;
`;

interface NetelipButtonContProps {
  $haveChildrenImg: boolean;
}

export const NetelipButtonCont = styled.div<NetelipButtonContProps>`
  position: absolute;
  right: 10px;
  bottom: 150px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  opacity: 0;

  ${({ $haveChildrenImg }) =>
    $haveChildrenImg
      ? css`
          animation-name: fadeIn;
          animation-duration: 0.5s;
          animation-fill-mode: forwards;

          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        `
      : css``}
  & > img {
    z-index: 1;
    opacity: 0;
    width: 58px;
    height: 58px;
    margin: 0 auto;
  }

  @media screen and (min-width: 600px) {
    bottom: 100px;
  }
`;

export const FreshDeskCont = styled.div`
  z-index: 1;
  position: absolute;
  right: 30px;
  bottom: 200px;
  width: 58px;
  height: 58px;

  background-color: red;
`;
