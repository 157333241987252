import { CSSProperties } from 'react';
import * as S from './WebTitle.styled';

interface Props {
  WebTitleProps: WebTitleProps;
  className?: string;
  style?: CSSProperties;
}

interface WebTitleProps {
  text: string;
  size?: 'small' | 'medium';
}

const WebTitle = ({ WebTitleProps, className, style }: Props) => {
  const { text, ...rest } = WebTitleProps;

  return (
    <S.WebTitle {...rest} className={className} style={style}>
      {text}
    </S.WebTitle>
  );
};

export default WebTitle;
