import * as S from './Campaign.styled';

const Campaign = () => {
  return (
    <S.Campaign>
      <S.Title>
        Wuipi se une a la campaña por el uso <br /> correcto del Internet
      </S.Title>
      <S.info>
        Para dar cumplimiento al <span>Oficio 1487</span>, emanado por la{' '}
        <span>Comisión Nacional de Telecomunicaciones (CONATEL)</span>.
      </S.info>
      <S.info>
        Informamos que no está permitido que el servicio contratado sea empleado de forma directa o
        a través de terceros para la reventa del mismo, podría ser sancionado por incurrir en
        delitos tipificados en la legislación vigente.
      </S.info>
    </S.Campaign>
  );
};

export default Campaign;
