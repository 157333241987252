import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

export const InstallationKit = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  min-height: calc(100vh - 121px);
  padding: 60px 0;
  overflow-x: hidden;

  @media screen and (min-width: 1920px) {
    min-height: auto;
    height: min(100vh, 800px);
  }
`;

export const InnerCont = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: min(1000px, 86%);
  height: 100%;

  @media screen and (min-width: 900px) {
    justify-content: space-between;
    align-items: center;
  }
`;

export const DecorationCont = styled(animated.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 260px;
  margin-top: 50px;

  @media screen and (min-width: 600px) {
    width: 310px;
  }

  @media screen and (min-width: 900px) {
    height: 300px;
    width: 350px;
    margin-top: 0;
  }
`;

interface DecorationProps {
  left: number;
  top: number;
  size: 'small' | 'medium' | 'large';
}

export const Decoration = styled(animated.div)<DecorationProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  width: 107px;
  height: 107px;

  & img {
    max-width: 45px;
    height: auto;
  }

  @media screen and (min-width: 900px) {
    position: absolute;
    left: ${({ left }) => `${left}px`};
    top: ${({ top }) => `${top}px`};
    transform: translate(-50%, -50%);

    ${({ size }) =>
      size === 'small'
        ? css`
            width: 72px;
            height: 72px;

            & img {
              max-width: 35px;
              height: auto;
            }
          `
        : size === 'medium'
        ? css`
            width: 107px;
            height: 107px;

            & img {
              max-width: 45px;
              height: auto;
            }
          `
        : css`
            width: 138px;
            height: 138px;

            & img {
              max-width: 70px;
              height: auto;
            }
          `}
  }

  border-radius: 50%;

  background-color: #0f78f2;
`;

export const Icons = styled.img``;

interface DecorationTextProps {
  top: number;
  $soft?: boolean;
}

export const DecorationText = styled.p<DecorationTextProps>`
  position: absolute;
  width: max-content;
  color: var(--color-blue-dark);
  font-weight: 600;
  z-index: 1;
  top: 110px;

  ${({ $soft }) =>
    $soft
      ? css`
          color: #828299;
          top: 140px;
        `
      : null}

  @media screen and (min-width: 900px) {
    top: ${({ top }) => `${top}px`};
  }
`;
