import styled from 'styled-components';
import { animated } from 'react-spring';
import { Link } from 'react-router-dom';

export const LayoutSignIn = styled.main`
  min-height: calc(100vh - 121px);
  display: grid;
  grid-area: page;
  grid:
    [row1-start] 'WuipiSideBg' 30% [row1-end]
    [row2-start] 'SignInCont' [row2-end]
    / 100%;

  @media screen and (min-width: 600px) {
    grid:
      [row1-start] 'WuipiSideBg SignInCont' [row1-end]
      / 3fr 7fr;
  }
`;

export const WuipiSideBg = styled.div`
  grid-area: WuipiSideBg;
  background-color: var(--color-blue-deep);
  min-height: 100%;
  position: relative;
  overflow: hidden;
`;

export const SignInCont = styled.div`
  position: relative;
  grid-area: SignInCont;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--color-white-soft);
  padding: 40px;
`;

export const contentCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: min(100%, 400px);
  margin-top: 100px;

  @media screen and (min-width: 600px) {
    margin-top: 0px;
  }
`;

export const TitleCont = styled.div`
  width: 220px;
`;

export const GoBackButton = styled(Link)`
  position: absolute;
  font-family: inherit;
  border: none;
  outline: none;
  right: 0%;
  top: 10%;
  transform: translate(-50%, -50%);
  padding: 5px 10px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 30px;
  color: var(--color-white-soft);
  background-color: #282828;

  &:hover {
    background-color: var(--color-blue-dark);
  }

  @media screen and (min-width: 600px) {
    top: 5%;
  }
`;

export const WuipiLogo = styled(animated.img)`
  position: absolute;
  width: 200%;
  left: 80%;
  top: 30%;
`;

export const Phrase = styled.p`
  font-size: 16px;
  margin: 0;
  color: var(--color-blue-dark);
  padding-left: 10px;
  font-weight: 500;
`;

export const Links = styled(Link)``;
