import * as S from './OurPlansLabel.styled';

interface OurPlansLabelProps {
  text: string;
  fill: 'filled' | 'empty';
  onClick?: () => void;
}

const OurPlansLabel = ({ text, fill, onClick }: OurPlansLabelProps) => {
  return (
    <S.OurPlansLabel fill={fill} onClick={onClick}>
      <S.OurPlansLabelText>{text}</S.OurPlansLabelText>
    </S.OurPlansLabel>
  );
};

export default OurPlansLabel;
