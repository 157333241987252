import { useRef, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useScrollWatcher, useScrollTopOrHash, useNetelipScript } from 'infrastructure/hooks';
import { Header, Footer, CallBot } from 'infrastructure/components';
import {
  wuipiBackgroundMovHome,
  wuipiBackgroundMovSupport,
  wuipiBackgroundMovFallbackImgHome,
  wuipiBackgroundMovFallbackImgSupport,
} from 'infrastructure/assets';

import * as S from './LayouMain.styled';
import ReactPlayer from 'react-player/lazy';

const LayoutMain = () => {
  const [imgIsAdded, setImgIsAdded] = useState(false);
  const callBotCont = useRef<HTMLDivElement>(null);
  const { setBeenClicked, beenClicked } = useNetelipScript({
    url: '//apps.netelip.com/clicktocall/api2/js/api2.js?btnid=3750&atk=994d34ecbbc8a18e84be0663e20a885f',
    parent: callBotCont,
  });

  const scrollCont = useRef(null);

  useScrollTopOrHash({ scrollCont });
  const { scrollWatcher } = useScrollWatcher();
  const { pathname } = useLocation();

  useEffect(() => {
    const netelipCont = callBotCont.current;
    if (!netelipCont) return;

    const observer = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0) {
          const addedNode = mutation.addedNodes[0];
          if (addedNode instanceof HTMLImageElement) {
            setImgIsAdded(true);
          }
        }
      });
    });
    observer.observe(netelipCont, { childList: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <S.LayoutMain data-component-name='Layout main'>
      <Header />
      <S.ContentCont
        data-component-name='Content Container'
        onScroll={scrollWatcher}
        ref={scrollCont}
      >
        <Outlet />
        <Footer />
      </S.ContentCont>
      <S.NetelipButtonCont
        $haveChildrenImg={imgIsAdded}
        id='netelipCont'
        ref={callBotCont}
        onClick={() => setBeenClicked(!beenClicked)}
      >
        <CallBot />
      </S.NetelipButtonCont>

      {/^\/support(.*)/.test(pathname) ? (
        <S.VideoCont>
          <ReactPlayer
            url={wuipiBackgroundMovSupport}
            fallback={<S.FallbackImg src={wuipiBackgroundMovFallbackImgSupport} alt='' />}
            width='100%'
            height='100%'
            volume={0}
            autoPlay
            playing
            playsinline
            loop
            muted
            config={{
              file: {
                attributes: {
                  preload: 'auto',
                },
              },
            }}
          />
        </S.VideoCont>
      ) : (
        <S.VideoCont>
          <ReactPlayer
            url={wuipiBackgroundMovHome}
            fallback={<S.FallbackImg src={wuipiBackgroundMovFallbackImgHome} alt='' />}
            width='100%'
            height='100%'
            volume={0}
            autoPlay
            playing
            playsinline
            loop
            muted
            config={{
              file: {
                attributes: {
                  preload: 'auto',
                },
              },
            }}
          />
        </S.VideoCont>
      )}
    </S.LayoutMain>
  );
};

export default LayoutMain;
