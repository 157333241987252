import { Link } from 'react-router-dom';
import { BsShare } from 'react-icons/bs';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  border-radius: 10px;
  width: min(100%, 450px);
  z-index: 1;

  & button {
    margin-left: auto;
  }

  & button:disabled {
    cursor: not-allowed;
    display: flex;
    background-color: #ae6a38;
  }
`;

export const TextCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Text = styled.label`
  font-size: 16px;
  font-weight: 500;
  margin-right: auto;
  color: var(--color-blue-dark);
  margin-bottom: 20px;
`;

export const LinkTo = styled(Link)``;

export const GoToGoogle = styled.button`
  width: 150px;
  height: 40px;
  border-radius: 30px;
  border: none;
  font-size: 15px;
  font-family: inherit;
  font-weight: 500;
  color: var(--color-white);
  margin-bottom: 20px;
  cursor: pointer;
  background: var(--color-orange);
`;

export const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin-right: auto;
  margin-bottom: 10px;
  color: var(--color-blue-dark);
`;

export const ShareIcon = styled(BsShare)`
  display: inline-block;
  margin-left: 5px;

  font-size: 18px;
  color: #1a73e8;
`;

export const FieldSet = styled.fieldset`
  width: 100%;
  border: none;
  position: relative;
  margin-bottom: 40px;
`;

export const Error = styled.span`
  position: absolute;
  bottom: -20px;
  color: red;
`;

export const SpinnerCont = styled.div`
  margin: 0 auto;
`;

export const CiOrRif = styled.div`
  position: relative;
  width: 100%;
`;
