import * as S from './Logo.styled';
import { wuipiLogoBlue, wuipiLogoWhite, wuipiLogoShort } from 'infrastructure/assets';
import { useWindowSize } from 'react-use';

interface LogoProps {
  size?: string;
  color: 'white' | 'blue';
}

const Logo = ({ size, color }: LogoProps) => {
  const { width } = useWindowSize();

  return (
    <S.Logo
      alt='Wuipi Logo'
      src={color === 'white' ? (width > 899 ? wuipiLogoWhite : wuipiLogoShort) : wuipiLogoBlue}
    />
  );
};

export default Logo;
