import styled from 'styled-components';
import { Props } from './interfaces';

export const OurPlansRange = styled.div.attrs((props: Props) => {
  return props;
})`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;

  @media screen and (min-width: 600px) {
    flex-direction: row;

    & div {
      margin: 0 5px;
    }

    & div:nth-child(1) {
      margin: 0;
      margin-right: 5px;
    }

    & div:nth-child(4) {
      margin: 0;
      margin-left: 5px;
    }
  }
`;
