import { animated } from 'react-spring';
import styled from 'styled-components';

export const Support = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-blue-sea);
  min-height: calc(100vh - 121px);
  padding: 60px 0;
  overflow-x: hidden;

  @media screen and (min-width: 1920px) {
    min-height: auto;
    height: min(100vh, 700px);
  }
`;

export const InnerCont = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: min(1600px, 86%);
  height: 100%;

  @media screen and (min-width: 900px) {
    justify-content: space-between;
  }

  @media screen and (min-width: 1920px) {
    height: auto;
  }
`;

export const WuipiSignal = styled(animated.img)`
  margin-top: 80px;
  transform: scale(0.1);
  max-width: 100%;
  height: auto;
  object-fit: contain;
`;
