import { CSSProperties, useContext } from 'react';
import { ClientInfoContext } from 'infrastructure/components/molecules/ClientInfo/ClientInfo';

import * as S from './ClientName.styled';

interface Props {
  clientNameProps?: ClientNameProps;
  className?: string;
  style?: CSSProperties;
}

interface ClientNameProps {
  text: string;
}

const ClientName = ({ clientNameProps, className, style }: Props) => {
  const { name } = useContext(ClientInfoContext);
  const nameToShow: string = clientNameProps?.text || name || '-----';

  return (
    <S.ClientName className={className} style={style}>
      Hola, <br />
      <S.Name>{nameToShow}</S.Name>
    </S.ClientName>
  );
};

export default ClientName;
