import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ClientDataHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const LogoCont = styled.div`
  width: 100px;
`;

export const GoBackButton = styled(Link)`
  font-family: inherit;
  border: none;
  outline: none;
  font-size: 14px;
  padding: 6px 12px;
  font-weight: 500;
  border-radius: 30px;
  color: var(--color-white-soft);
  background-color: #282828;
  transition: var(--transition);

  &:hover {
    background-color: var(--color-blue-dark);
  }
`;
