import { SectionTextProps } from './interfaces';

import * as S from './SectionText.styled';

const SectionText = ({ text, color, ...restProps }: SectionTextProps) => {
  return (
    <S.SectionText color={color} {...restProps}>
      {text}
    </S.SectionText>
  );
};

export default SectionText;
