import * as S from './SectionTitle.styled';

interface Props {
  SectionTitleProps: SectionTitleProps;
}

interface SectionTitleProps {
  text: string | (JSX.Element | string)[];
  color: 'white' | 'blue';
  weight?: 'slim' | 'normal' | 'fat';
  size?: 'small' | 'normal' | 'large';
}

const SectionTitle = ({ SectionTitleProps }: Props) => {
  const { text, color, weight, size } = SectionTitleProps;

  return (
    <S.SectionTitle color={color} weight={weight} size={size}>
      {text}
    </S.SectionTitle>
  );
};

export default SectionTitle;
