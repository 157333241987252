import { useEffect, useMemo } from 'react';
import { useSprings, config, useSpring } from 'react-spring';
import { useAppSelector } from 'infrastructure/hooks';

interface Props {
  animContRef: React.MutableRefObject<any>;
}

const useSquareSpringAnim = ({ animContRef }: Props) => {
  const YScroll = useAppSelector((state) => state.animation.YOffset);
  const [animContYOffsetSpring, animContYOffsetSpringApi] = useSpring(() => ({
    YOffSet: 0,
  }));

  const squareSpringInitialStyle = [
    {
      opacity: 0,
      transform: 'translate(-80%, -50%) rotate(-30deg)',
    },
    {
      opacity: 0,
      transform: 'translate(-20%, -50%) rotate(30deg)',
    },
  ];

  const [squareSpringStyles, squareSpringStylesApi] = useSprings(2, (index) => ({
    ...squareSpringInitialStyle[index],
  }));

  //the final styles to be added
  const squareSpringFinalStyle = useMemo(
    () => [
      {
        opacity: animContYOffsetSpring.YOffSet.to([-100, 0, 0 + 1], [0, 1, 1]),
        transform: animContYOffsetSpring.YOffSet.to(
          [-100, 0, 0 + 1],
          [
            'translate(-80%, -50%) rotate(-30deg)',
            'translate(-40%, -70%) rotate(5deg)',
            'translate(-40%, -70%) rotate(5deg)',
          ]
        ),

        config: config.wobbly,
      },
      {
        opacity: animContYOffsetSpring.YOffSet.to([-100, 0, 0 + 1], [0, 1, 1]),
        transform: animContYOffsetSpring.YOffSet.to(
          [-100, 0, 0 + 1],
          [
            'translate(-20%, -50%) rotate(30deg)',
            'translate(-70%, -70%) rotate(-5deg)',
            'translate(-70%, -70%) rotate(-5deg)',
          ]
        ),
        config: config.wobbly,
      },
    ],
    [animContYOffsetSpring]
  );

  squareSpringStylesApi.start((index) => ({
    ...squareSpringFinalStyle[index],
  }));

  //this will get the component container or component element position on load
  useEffect(() => {
    const { top, height } = animContRef.current?.getBoundingClientRect();
    const compToTop = top - 67.5;
    const compHalf = height / 2;
    const windowHalf = window.innerHeight / 2;

    const YOffSet = ((compToTop - windowHalf + compHalf) / height) * -100;

    if (YOffSet >= -100 && YOffSet <= 100) {
      animContYOffsetSpringApi.start({
        YOffSet,
      });
    }
  }, [animContRef, animContYOffsetSpringApi, YScroll]);

  return { squareSpringStyles };
};

export default useSquareSpringAnim;
