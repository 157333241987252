import * as S from './NavbarLink.styled';

interface Props {
  NavbarLinkProps: NavbarLinkProps;
}

interface NavbarLinkProps {
  size?: 'normal' | 'large';
  to: string;
  text: string;
  onClick?: () => void;
  weight?: number;
  color: 'white' | 'black' | 'orange';
}

const NavbarLink = ({ NavbarLinkProps }: Props) => {
  const { text, ...rest } = NavbarLinkProps;

  return <S.NavbarLink {...rest}>{text}</S.NavbarLink>;
};

export default NavbarLink;
