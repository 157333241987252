import { useGetCategoryQuery } from 'infrastructure/store/apis/support';
import { CategoryDescription, CategoryTitle, SubCategory } from 'infrastructure/components';
import { useParams } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';

import * as S from './SupportSelectedCategory.styled';

const SupportSelectedCategory = () => {
  const { categoryId } = useParams();
  const { data, isSuccess, isError, isFetching } = useGetCategoryQuery({ id: categoryId });

  return (
    <S.SupportSelectedCategory>
      {isSuccess && !isError ? (
        <>
          <CategoryTitle CategoryTitleProps={{ title: data.name }} />
          <CategoryDescription CategoryDescrptionProps={{ text: data.description }} />

          {data.folders.map(({ name, articles, id }) => {
            return (
              <SubCategory
                key={`${id}-${name}`}
                SubCategoryProps={{ title: name, articles, subCategoryId: id }}
              >
                <SubCategory.Title />
                <SubCategory.ArticlesSet />
              </SubCategory>
            );
          })}
        </>
      ) : null}
      {isError ? <div>ha ocurrido un error</div> : null}

      <S.SpinnerCont>
        <MoonLoader color='#fff' size={50} speedMultiplier={0.5} loading={isFetching} />
      </S.SpinnerCont>
    </S.SupportSelectedCategory>
  );
};

export default SupportSelectedCategory;
