import { SmarthHomeInfo } from 'infrastructure/components';
import { tabletImg, wuipiBlueSignal } from 'infrastructure/assets';
import * as S from './SmartHome.styled';
import { useSmarthHomeSpringAnim } from './hooks';

const SmartHome = () => {
  const { mousePosListening, springDec1, springDec2 } = useSmarthHomeSpringAnim();

  return (
    <S.SmartHome onMouseMove={mousePosListening}>
      <S.InnerCont>
        <SmarthHomeInfo />
        <S.ProductImgCont>
          <S.SignalImg src={wuipiBlueSignal} alt='blue signal img' style={springDec1} />
          <S.ProductImg src={tabletImg} alt='tablet img' style={springDec2} />
        </S.ProductImgCont>
      </S.InnerCont>
    </S.SmartHome>
  );
};

export default SmartHome;
