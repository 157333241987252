import { MainButton } from 'infrastructure/components';
import { fullSquare, emptySquare } from 'infrastructure/assets';

import { useRef } from 'react';
import { useSquareSpringAnim } from './hooks';
import * as S from './ContactThanks.styled';

const ContactThanks = () => {
  const animContRef = useRef(null);
  const { squareSpringStyles } = useSquareSpringAnim({ animContRef });

  return (
    <S.ContactThanks ref={animContRef}>
      <S.FullSquare src={fullSquare} alt='decoration img' style={squareSpringStyles[0]} />
      <S.ContactThanksText>¡Gracias por contactarnos!</S.ContactThanksText>
      <S.ContactThanksMessage>
        Nuestro equipo estará en comunicación con usted próximamente.
      </S.ContactThanksMessage>

      <S.LinkTo to='/' replace>
        <MainButton
          MainButtonProps={{
            text: 'Regresar',
            color: 'orange',
            borderRadius: 'round',
            type: 'button',
          }}
        />
      </S.LinkTo>
      <S.emptySquare src={emptySquare} alt='decoration img' style={squareSpringStyles[1]} />
    </S.ContactThanks>
  );
};

export default ContactThanks;
