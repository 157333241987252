import styled from 'styled-components';

export const ClientStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
`;

export const Title = styled.h4`
  font-size: 20px;
  color: var(--color-blue-dark);
`;

export const IsActiveCont = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

interface DotProps {
  status: number;
}

export const Dot = styled.div<DotProps>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${({ status }) =>
    status === 1 ? '#60cb60' : status === 2 ? '#FFBE45' : status === 3 ? '#d04343' : '#fff'};
`;

export const Status = styled.span`
  font-size: 18px;
  color: var(--color-blue-dark);
`;
