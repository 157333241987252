import { SectionText, SectionTitle } from 'infrastructure/components';

import * as S from './OurPlatformInfo.styled';

const OurPlatformInfo = () => {
  return (
    <S.OurPlatformInfo>
      <SectionTitle
        SectionTitleProps={{
          text: ['¿Nuestra', <br key='ourPlatformLineBreak' />, 'Plataforma?'],
          color: 'blue',
        }}
      />
      <SectionText
        color='blue'
        text='La red troncal de transmisión de Wi-Fi, la cual interconectan los diferentes sitios de repetición con el centro de datos desde donde se origina en nuestros servicios, funciona a través de radio enlaces de microondas IP Ethernet, que garantizan una excelente disponibilidad con un bajo rango de interrupciones del servicio por mes.'
      />
    </S.OurPlatformInfo>
  );
};

export default OurPlatformInfo;
