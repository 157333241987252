import styled from 'styled-components';
import { animated } from 'react-spring';

export const Contact = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--color-white);
  min-height: calc(100vh - 121px);
  padding: 60px 20px;
  overflow: hidden;

  @media screen and (min-width: 1920px) {
    min-height: auto;
    height: min(100vh, 800px);
  }
`;

export const Line = styled.hr`
  background-color: var(--color-blue-sea);
  width: 85px;
  height: 3px;
  margin-bottom: 10px;
`;

export const Phrase = styled.p`
  font-size: 18px;
  margin-bottom: 0px;
`;

export const FullSquare = styled(animated.img)`
  position: absolute;
  top: 50%;
  left: -8%;
  transform: translate(-80%, -50%) rotate(-30deg);
  width: 300px;
  height: auto;

  @media screen and (min-width: 600px) {
    width: 600px;
  }
`;

export const emptySquare = styled(animated.img)`
  position: absolute;
  top: 75%;
  left: 110%;
  transform: translate(-20%, -50%) rotate(30deg);
  width: 300px;
  height: auto;

  @media screen and (min-width: 600px) {
    width: 600px;
  }
`;
