import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const CheckSpeed = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-blue-dark);
  min-height: 19vh;
  padding: 30px 20px;
  position: relative;

  & button {
    z-index: 1;
  }

  @media screen and (min-width: 1920px) {
    min-height: auto;
    height: 260px;
  }
`;

export const Info = styled.span`
  font-size: 30px;
  color: var(--color-white-soft);
  margin-right: 10px;
  z-index: 1;

  @media screen and (min-width: 600px) {
    margin-right: 40px;
  }
`;

export const LeftBgImg = styled.img`
  position: absolute;
  left: 0;
  max-height: 100%;
  width: auto;
`;

export const RightBgImg = styled.img`
  position: absolute;
  right: 0;
  max-height: 100%;
  width: auto;
  transform: scaleX(-1);
`;

export const LinkTo = styled(Link)`
  z-index: 1;
`;
