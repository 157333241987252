import styled from 'styled-components';

export const ServiceVideoBanner = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: max(calc(100vh - 121px), 600px);
  position: relative;
`;

export const VideoBanner = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  height: 100%;
  width: 100%;
  object-fit: cover;

  /* @media screen and (max-height: 420px) {
    height: 140%;
  }
  @media screen and (max-height: 320px) {
    height: 150%;
  } */
`;
