import { useRef } from 'react';
import { InstallAndCostInfo } from 'infrastructure/components';
import { useInstallAndCostSpringAnim } from './hooks';
import { car } from 'infrastructure/assets';

import * as S from './InstallAndCost.styled';

const InstallAndCost = () => {
  const animContRef = useRef(null);
  const { mousePosListening, springDec1, springDec2, carMoveSpringStyles } =
    useInstallAndCostSpringAnim({
      animContRef,
    });

  return (
    <S.InstallAndCost onMouseMove={mousePosListening} id='servicio' ref={animContRef}>
      <S.InnerCont>
        <S.CarCont style={carMoveSpringStyles}>
          <S.Car src={car} alt='car' style={springDec2} />
          <S.DecorationCont style={springDec1}>
            <S.Decoration left={10} top={50} size='large' $isRing />
            <S.Decoration left={120} top={-20} size='medium' $isRing />
            <S.Decoration left={210} top={30} size='large' />
            <S.Decoration left={380} top={70} size='medium' $isRing />
            <S.Decoration left={410} top={100} size='small' />
          </S.DecorationCont>
        </S.CarCont>
        <InstallAndCostInfo />
      </S.InnerCont>
    </S.InstallAndCost>
  );
};

export default InstallAndCost;
