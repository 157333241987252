import * as S from './Notification.styled';

interface Props {
  NotificationProps: NotificationProps;
}

interface NotificationProps {
  message: string | undefined;
}

const Notification = ({ NotificationProps }: Props) => {
  const { message } = NotificationProps;

  return (
    <S.Notification data-component-name='Page notification'>
      <S.NotificationText>{message}</S.NotificationText>
    </S.Notification>
  );
};

export default Notification;
