import styled, { css } from 'styled-components';
import { BsCheckCircleFill } from 'react-icons/bs';
import { SelectProps } from 'react-html-props';

export const FormSelect = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
`;

export const Select = styled.select.attrs((props: SelectProps) => {
  return { ...props };
})`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: 0px 0px 25px 0px;
  border-radius: 15px;
  background-color: var(--color-white-light);
  border: 1px solid var(--color-blue-dark);
  color: var(--color-blue-dark);
  outline: none;
  transition: var(--transition);
  font-size: 16px;

  color-scheme: white;

  &:focus {
    border: 1px solid var(--color-blue-sea);
  }

  &:active {
    background-color: var(--color-soft-purple);
  }

  &[disabled] {
    background-color: var(--color-grey);
    color: #625e5e;
    cursor: not-allowed;
  }

  &[data-focused='true'] ~ span {
    display: block;
  }

  &[data-focused='true'] {
    border: 1px solid #ef4848;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--color-soft-white);
    box-shadow: 0 0 0px 1000px var(--color-soft-black) inset;
    -webkit-box-shadow: 0 0 0px 1000px var(--color-soft-black) inset;
  }

  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px var(--color-soft-black) inset;
    -webkit-box-shadow: 0 0 0px 1000px var(--color-greyish-black) inset;
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  color: var(--color-soft-white);
`;

export const Span = styled.span`
  position: absolute;
  font-size: 16px;
  padding: 3px;
  color: #ef4848;
  display: none;
  bottom: -2px;
`;

export const Option = styled.option``;

interface IsCheckProps {
  pass: 'true' | 'false';
  top?: 'true' | 'false';
}

export const IsCheck = styled(BsCheckCircleFill)<IsCheckProps>`
  position: absolute;
  padding-top: 1px;
  top: ${({ top }) => (top === 'true' ? '50%' : '33%')};
  right: 10px;
  font-size: 18px;
  transform: translate(-50%, -50%);
  color: #3bb94e;
  transition: var(--transition);
  ${({ pass }) =>
    pass === 'true'
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
`;
