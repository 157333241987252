import * as S from './MenuButton.styled';

interface MenuButtonProps {
  setShow: () => void;
  show: boolean;
}

const MenuButton = ({ setShow, show }: MenuButtonProps) => {
  return (
    <S.MenuButton type='button' title='Menu Button' onClick={setShow}>
      <S.UpperLine show={show} />
      <S.MiddleLine show={show} />
      <S.BottomLine show={show} />
    </S.MenuButton>
  );
};

export default MenuButton;
