import * as S from './CardText.styled';

interface CardTextProps {
  text: string;
}

const CardText = ({ text }: CardTextProps) => {
  return <S.CardText>{text}</S.CardText>;
};

export default CardText;
