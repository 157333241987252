import * as S from './SocialMediaIcon.styled';

interface Props {
  children: JSX.Element;
  href: string;
}

const SocialMediaIcon = ({ children, href }: Props) => {
  return (
    <S.SocialMediaIcon referrerPolicy='no-referrer' target='_blank' href={href}>
      {children}
    </S.SocialMediaIcon>
  );
};

export default SocialMediaIcon;
