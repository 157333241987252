import styled from 'styled-components';

export const Feasibility = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-blue-dark);
  min-height: 19vh;
  padding: 30px 100px;
  position: relative;

  @media screen and (min-width: 1920px) {
    min-height: auto;
    height: 310px;
  }
`;

export const InfoCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

export const InfoTitle = styled.h2`
  font-size: 30px;
  color: var(--color-white-soft);
  margin-bottom: 30px;
`;

export const Info = styled.p`
  font-size: 18px;
  color: var(--color-white-soft);
  margin-bottom: 20px;
  width: min(960px, 100%);
  text-align: center;
`;

export const LeftBgImg = styled.img`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  max-width: 120px;
  height: auto;
`;

export const RightBgImg = styled.img`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  max-width: 120px;
  height: auto;
`;
