import * as S from './InstallAndCostInfo.styled';
import { SectionTitle, SectionText } from 'infrastructure/components';

const InstallAndCostInfo = () => {
  return (
    <S.InstallAndCostInfo>
      <SectionTitle
        SectionTitleProps={{
          text: 'Instalación y costos',
          color: 'blue',
        }}
      />
      <SectionText
        color='blue'
        text='Nuestros planes distribuidos son de navegación ilimitada y garantizamos como mínimo 70% de la velocidad contratada.'
      />
    </S.InstallAndCostInfo>
  );
};

export default InstallAndCostInfo;
