import { useEffect, useMemo } from 'react';
import { useSprings, config } from 'react-spring';

interface Props {
  animContRef: React.MutableRefObject<any>;
}

const useSquareSpringAnim = ({ animContRef }: Props) => {
  const squareSpringInitialStyle = [
    {
      opacity: 0,
      transform: 'translate(-80%, -50%) rotate(-30deg)',
    },
    {
      opacity: 0,
      transform: 'translate(-20%, -50%) rotate(30deg)',
    },
  ];

  const [squareSpringStyles, squareSpringStylesApi] = useSprings(2, (index) => ({
    ...squareSpringInitialStyle[index],
  }));

  //the final styles to be added
  const squareSpringFinalStyle = useMemo(
    () => [
      {
        opacity: 0.4,
        transform: 'translate(-40%, -70%) rotate(5deg)',

        config: config.wobbly,
      },
      {
        opacity: 0.4,
        transform: 'translate(-70%, -70%) rotate(-5deg)',
        config: config.wobbly,
      },
    ],
    []
  );

  useEffect(() => {
    squareSpringStylesApi.start((index) => ({
      ...squareSpringFinalStyle[index],
    }));
  }, [squareSpringStylesApi, squareSpringFinalStyle]);

  return { squareSpringStyles };
};

export default useSquareSpringAnim;
