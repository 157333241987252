import { ClientInfoContext } from 'infrastructure/components/molecules/ClientInfo/ClientInfo';
import { useContext } from 'react';
import { CSSProperties } from 'styled-components';
import * as S from './ClientAddress.styled';

interface Props {
  clientDirectionProps?: ClientAddressProps;
  className?: string;
  style?: CSSProperties;
}

interface ClientAddressProps {
  address: string;
}

const ClientAddress = ({ clientDirectionProps, className, style }: Props) => {
  const { address } = useContext(ClientInfoContext);
  const addressToShow: string =
    clientDirectionProps?.address ||
    address ||
    '-------------------------------------------------------------------------';

  return (
    <S.ClientAddress className={className} style={style}>
      <S.Title>Dirección</S.Title>
      <S.Address>{addressToShow}</S.Address>
    </S.ClientAddress>
  );
};

export default ClientAddress;
