import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface ServiceRequestProps {
  fullname: string;
  email: string;
  identification: string;
  phone: string;
  phone_contact: string;
  plan: string;
  paymentMethod: string;
  address: string;
}

interface ServiceRequestResponseProps {
  status: 'idle' | 'loading' | 'success' | 'error';
  message: string;
}

export const serviceApi = createApi({
  reducerPath: 'serviceApi',
  baseQuery: fetchBaseQuery({
    //give the base url from .env
    baseUrl: process.env.REACT_APP_ROOT_ENDPOINT,
  }),

  endpoints: (builder) => ({
    ServiceRequest: builder.mutation<ServiceRequestResponseProps, ServiceRequestProps>({
      query: ({
        fullname,
        email,
        identification,
        phone,
        phone_contact,
        plan,
        paymentMethod,
        address,
      }) => ({
        url: '/sendgrid/service-request',
        method: 'POST',
        body: {
          fullname,
          email,
          identification,
          phone,
          phone_contact,
          plan,
          paymentMethod,
          address,
        },
        keepUnusedDataFor: 1,
      }),
    }),
  }),
});

export const { useServiceRequestMutation } = serviceApi;
