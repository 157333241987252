import { useGetSubCategoryQuery } from 'infrastructure/store/apis/support';
import { useParams } from 'react-router-dom';

const useGetArticle = () => {
  const { subCategoryId, articleId } = useParams();

  const { data, isSuccess, isError, isFetching } = useGetSubCategoryQuery({
    id: subCategoryId,
  });

  const relatedArticles =
    data?.articles.filter((article) => article.id.toString() !== articleId) || [];

  const article = data?.articles.find((article) => article.id.toString() === articleId);

  return { data, isSuccess, isError, isFetching, relatedArticles, article };
};

export default useGetArticle;
