import { wuipiAnthena } from 'infrastructure/assets';
import { OurPlatformInfo } from 'infrastructure/components';
import { useOurPlatformSpringAnim } from './hooks';

import * as S from './OurPlatform.styled';

const OurPlatform = () => {
  const { mousePosListening, springDec1, springDec2, springDec3, springDec4 } =
    useOurPlatformSpringAnim();
  return (
    <S.OurPlatform onMouseMove={mousePosListening}>
      <S.InnerCont>
        <S.OurPlatformImgCont>
          <S.BlueCircle style={springDec3} />
          <S.BlueDeepCircle style={springDec1} />
          <S.BlueRing style={springDec2} />
          <S.Anthena src={wuipiAnthena} alt='wuipi anthena img' style={springDec4} />
        </S.OurPlatformImgCont>

        <OurPlatformInfo />
      </S.InnerCont>
    </S.OurPlatform>
  );
};

export default OurPlatform;
