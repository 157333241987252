import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

interface NavbarLinksProps {
  size?: 'normal' | 'large';
  weight?: number;
  color: 'white' | 'black' | 'orange';
}

export const NavbarLink = styled(NavLink)<NavbarLinksProps>`
  margin-left: 18px;
  text-transform: uppercase;
  font-weight: ${({ weight }) => (weight ? weight : 500)};

  ${({ size }) =>
    size === 'large'
      ? css`
          font-size: 22px;
        `
      : css`
          font-size: 16px;
        `}

  ${({ color }) =>
    color === 'black'
      ? css`
          color: var(--color-blue-dark);
        `
      : color === 'orange'
      ? css`
          color: #ff872f;
        `
      : css`
          color: var(--color-white-soft);
        `}

        
  @media screen and (min-width: 1200px) {
    margin-left: 36px;

    ${({ size }) =>
      size === 'large'
        ? css`
            font-weight: 600;
            font-size: 26px;
          `
        : css`
            font-size: 18px;
          `}
  }
`;
