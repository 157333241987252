import styled from 'styled-components';

export const SupportSelectedCategory = styled.div`
  display: flex;
  flex-direction: column;
  width: min(100%, 1720px);
  min-height: 100vh;
  color: var(--color-white-soft);
`;

export const SpinnerCont = styled.div`
  display: flex;
  align-self: center;
`;
