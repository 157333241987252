import { useEffect, useState } from 'react';
import { useSpring, config } from 'react-spring';

const useProductsSpringAnim = () => {
  const [mousePos, setMousePos] = useState({
    x: 0,
    y: 0,
  });
  const [dec1Plx, SetDec1Plx] = useState({
    px: 0,
    py: 0,
  });
  const [dec2Plx, SetDec2Plx] = useState({
    px: 0,
    py: 0,
  });
  const [dec3Plx, SetDec3Plx] = useState({
    px: 0,
    py: 0,
  });
  const [dec4Plx, SetDec4Plx] = useState({
    px: 0,
    py: 0,
  });

  const mousePosListening = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const { left, top, height, width } = e.currentTarget.getBoundingClientRect();
    setMousePos({
      x: (e.clientX - left) / width - 0.5,
      y: (e.clientY - top) / height - 0.5,
    });
  };

  const springDec1 = useSpring({
    transform: `translate(calc(${dec1Plx.px}px),calc(${dec1Plx.py}px))`,
  });

  const springDec2 = useSpring({
    transform: `translate(calc(${dec2Plx.px}px),calc(${dec2Plx.py}px))`,
    config: config.slow,
  });

  const springDec3 = useSpring({
    transform: `translate(calc(${dec3Plx.px}px),calc(${dec3Plx.py}px))`,
    config: config.wobbly,
  });

  const springDec4 = useSpring({
    transform: `translate(calc(${dec4Plx.px}px),calc(${dec4Plx.py}px))`,
    config: config.wobbly,
  });

  useEffect(() => {
    SetDec1Plx({
      px: -mousePos.x * 70,
      py: -mousePos.y * 70,
    });
    SetDec2Plx({
      px: -mousePos.x * 50,
      py: -mousePos.y * 50,
    });
    SetDec3Plx({
      px: -mousePos.x * 40,
      py: -mousePos.y * 40,
    });

    SetDec4Plx({
      px: -mousePos.x * 100,
      py: -mousePos.y * 100,
    });
  }, [mousePos]);

  return { mousePosListening, springDec1, springDec2, springDec3, springDec4 };
};

export default useProductsSpringAnim;
