import styled from 'styled-components';

export const ClientName = styled.div`
  font-size: 36px;
  width: 100%;
  margin-top: 50px;
  color: var(--color-blue-dark);
`;

export const Name = styled.span`
  font-weight: 600;
`;
