import * as S from './Header.styled';
import { Logo, Navbar, MobileNavbar } from 'infrastructure/components';

const Header = () => {
  const links = ['inicio', 'planes', 'servicio', 'productos', 'contacto'];

  return (
    <S.Header data-component-name='Header'>
      <S.LogoCont>
        <S.Links to='/'>
          <Logo color='white' />
        </S.Links>
      </S.LogoCont>
      <Navbar links={links} />
      <MobileNavbar links={links} />
    </S.Header>
  );
};

export default Header;
