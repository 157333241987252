import * as S from './SupportInfo.styled';
import { SectionTitle, SectionText, MainButton } from 'infrastructure/components';

const SupportInfo = () => {
  return (
    <S.SupportInfo>
      <SectionTitle
        SectionTitleProps={{
          text: [
            '¿Alguna duda?',
            <br key='tbr1' />,
            '¡Consulta nuestra',
            <br key='tbr2' />,
            'base de conocimiento!',
          ],
          color: 'white',
        }}
      />
      <SectionText
        color='white'
        text=' Accede y encuentra solución a problemas comunes e información general del servicio'
      />
      <S.Links to='/support'>
        <MainButton
          MainButtonProps={{
            text: 'CONSULTAR',
            color: 'orange',
            borderRadius: 'round',
            type: 'button',
          }}
        />
      </S.Links>
    </S.SupportInfo>
  );
};

export default SupportInfo;
