import styled from 'styled-components';

export const TableButtonFilter = styled.button`
  cursor: pointer;
  padding: 6px 15px;
  background-color: var(--color-white-soft);
  font-family: inherit;
  font-size: 18px;
  border-radius: 10px;
  margin: 0 5px;
  color: var(--color-blue-dark);
  border: 1px solid var(--color-blue-dark);
  transition: var(--transition-fast);
  margin-bottom: 10px;

  @media screen and (min-wdith: 600px) {
    margin-bottom: 0;

    &:first-child {
      margin: 0 5px 0 0;
    }

    &:last-child {
      margin: 0 0 0 5px;
    }
  }

  &[data-selected='true'] {
    border: 1px solid var(--color-orange);
    background-color: #ecc597;
  }
`;
