import * as S from './SmarthHomeInfo.styled';

const SmarthHomeInfo = () => {
  return (
    <S.SmarthHomeInfo>
      <S.MinorTitle>¿Hogar Inteligente? ¡Solo con la mejor conexión!</S.MinorTitle>
      <S.ProductSpan>Amazon Echo Dot Show 10</S.ProductSpan>
    </S.SmarthHomeInfo>
  );
};

export default SmarthHomeInfo;
