import styled from 'styled-components';

export const OurAppInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: min(390px, 100%);

  @media screen and (min-width: 1200px) {
    width: 450px;
  }

  & h2 {
    text-align: end;
  }

  & p {
    text-align: end;
    width: 100%;
  }
`;

export const PlayStoreBadge = styled.img`
  margin: 20px 10px 0 0;
  max-width: 115px;
  height: auto;
`;
