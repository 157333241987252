import styled from 'styled-components';
import { IoLogoTwitter, IoIosMail } from 'react-icons/io';
import { SiTiktok } from 'react-icons/si';
import { FaInstagram, FaFacebookF } from 'react-icons/fa';
import { AiOutlineCopyrightCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-blue-dark);
`;

export const FooterInnerCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 40%;
  padding: 40px 20px 40px 20px;
  width: min(100%, 1920px);

  @media screen and (min-width: 600px) {
    align-items: center;
    padding: 40px 50px 40px 50px;
  }

  @media screen and (min-width: 900px) {
    align-items: center;
    padding: 20px 100px;
  }
`;

export const FooterUpper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--color-white-light);
  padding-bottom: 20px;
  margin-bottom: 40px;
  width: 100%;
`;

export const LogoCont = styled.div`
  width: 100px;

  @media screen and (min-width: 900px) {
    width: 160px;
  }
`;

export const FooterSocialLink = styled.div`
  display: flex;
  justify-content: space-around;
  width: min(280px, 100%);
`;

export const TwitterLogo = styled(IoLogoTwitter)`
  color: var(--color-white-light);
  font-size: 22px;
`;

export const TiktokLogo = styled(SiTiktok)`
  color: var(--color-white-light);
  font-size: 22px;
`;

export const InstagramLogo = styled(FaInstagram)`
  color: var(--color-white-light);
  font-size: 22px;
`;

export const FacebookLogo = styled(FaFacebookF)`
  color: var(--color-white-light);
  font-size: 22px;
`;

export const EmailLogo = styled(IoIosMail)`
  color: var(--color-white-light);
  font-size: 22px;
`;

export const AdressCont = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 0 0;
  max-width: 300px;
`;

export const AdressText = styled.p`
  display: block;
  margin: 0;
  font-size: 14px;
  color: var(--color-white-soft);
`;

export const AllRightCont = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const AllRightIcon = styled(AiOutlineCopyrightCircle)`
  display: block;
  margin: 0;
  font-size: 16px;
  color: var(--color-white-soft);
`;

export const AllRightText = styled.p`
  display: block;
  margin: 0;
  font-size: 14px;
  color: var(--color-white-soft);
`;

export const Links = styled(Link)``;
