import styled from 'styled-components';

export const SupportArticleTitle = styled.h4`
  font-weight: 400;
  font-size: 18px;
  word-wrap: anywhere;

  @media screen and (min-width: 600px) {
    font-size: 20px;
    word-wrap: normal;
  }
`;
