import { ClientInfoContext } from 'infrastructure/components/molecules/ClientInfo/ClientInfo';
import { CSSProperties, useContext } from 'react';
import * as S from './ClientMovil.styled';

interface Props {
  clientMovilProps?: ClientMovilProps;
  className?: string;
  style?: CSSProperties;
}

interface ClientMovilProps {
  movilNumber: string;
}

const ClientMovil = ({ clientMovilProps, className, style }: Props) => {
  const { movil } = useContext(ClientInfoContext);
  const numberToShow: string = clientMovilProps?.movilNumber || movil || '-------------';

  return (
    <S.ClientMovil className={className} style={style}>
      <S.Title>Móvil</S.Title>
      <S.MovilNumber>{numberToShow}</S.MovilNumber>
    </S.ClientMovil>
  );
};

export default ClientMovil;
