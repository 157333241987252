import styled from 'styled-components';

interface SectionTitleProps {
  color: 'white' | 'blue';
  weight?: 'slim' | 'normal' | 'fat';
  size?: 'small' | 'normal' | 'large';
}

export const SectionTitle = styled.h2<SectionTitleProps>`
  font-size: ${({ size }) => (size === 'small' ? '28px' : size === 'large' ? '44px' : '36px')};
  word-wrap: anywhere;
  margin-bottom: 40px;
  letter-spacing: -1px;
  color: ${({ color }) =>
    color === 'white' ? 'var(--color-white-soft)' : 'var(--color-blue-dark)'};
  font-weight: ${({ weight }) => (weight === 'slim' ? 400 : weight === 'fat' ? 600 : 500)};

  @media screen and (min-width: 600px) {
    word-wrap: normal;
    font-size: ${({ size }) => (size === 'small' ? '42px' : size === 'large' ? '58px' : '50px')};
  }
`;
