import styled from 'styled-components';
import { IoSearchSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';

export const Search = styled.div`
  width: min(500px, 90%);
  margin-bottom: 100px;
  position: relative;
`;

export const SearchBar = styled.div`
  position: relative;
  background-color: var(--color-white-soft);
  border-radius: 30px;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 5px 5px 5px 0;
  width: 100%;
`;

export const SearchIcon = styled(IoSearchSharp)`
  width: 50px;
  height: 20px;
  color: #646464;
`;

//*************  by hand  */

export const SearchInput = styled.input`
  height: 100%;
  width: 100%;
  background-color: var(--color-white-soft);
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  font-size: 20px;
  padding: 5px 10px;
`;

export const ListBox = styled.div`
  background-color: var(--color-white-soft);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  padding: 10px;
  max-height: 350px;
  width: 90%;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  overflow-y: auto;
`;

export const ListBoxItem = styled.span`
  display: block;
  width: 100%;
  padding: 5px 10px;
  border-top: solid 1px #bbb0b042;
  border-bottom: 1px solid #bbb0b042;
  cursor: pointer;

  &:hover {
    background-color: #e4e2e2;
  }
`;

export const LinkTo = styled(Link)``;

export const LoadingCont = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;
