import styled from 'styled-components';

export const WhatInstall = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  min-height: calc(100vh - 121px);
  padding: 60px 0;
  overflow-x: hidden;

  @media screen and (min-width: 1920px) {
    min-height: auto;
    height: min(100vh, 800px);
  }
`;

export const InnerCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: min(1000px, 90%);
  height: 100%;

  & h2 {
    margin-bottom: 0;
    text-align: center;

    @media screen and (min-width: 900px) {
      text-align: start;
    }
  }
`;
