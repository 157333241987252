import { SectionText, SectionTitle } from 'infrastructure/components';
import * as S from './InstallationKitInfo.styled';

const InstallationKitInfo = () => {
  return (
    <S.IndividualService>
      <SectionTitle
        SectionTitleProps={{
          text: 'Kit de instalación',
          color: 'blue',
        }}
      />

      <SectionText
        color='blue'
        text='Nuestro kit de instalación incluye todo lo que necesitas para tener una conexión rápida y segura. Además de un equipo de profesionales que se encargará de instalar y configurar el sistema.'
      />
    </S.IndividualService>
  );
};

export default InstallationKitInfo;
