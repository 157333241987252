import { InvoiceTable as InvoiceTableHOC, InvoiceTableContext } from './InvoiceTable';

import InvoiceTableRowFull from 'infrastructure/components/molecules/InvoiceTableRowFull/InvoiceTableRowFull';

export { InvoiceTableContext };

export const PaymentMethodInfo = Object.assign(InvoiceTableHOC, {
  AllRows: InvoiceTableRowFull,
});

export default PaymentMethodInfo;
