import styled from 'styled-components';

export const ArticleNavbar = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;

  @media screen and (min-width: 900px) {
    padding: 0 20px;
  }
`;
