import { PaymentMethodInfoContext } from 'infrastructure/components/molecules/PaymentMethodInfo/PaymentMethodInfo';
import { CSSProperties, useContext } from 'react';
import * as S from './PaymentMethodInfoSummary.styled';

interface Props {
  paymentMethodInfoSummary?: PaymentMethodInfoTitleProps;
  className?: string;
  style?: CSSProperties;
}

interface PaymentMethodInfoTitleProps {
  summary: string;
}

const PaymentMethodInfoSummary = ({ paymentMethodInfoSummary, className, style }: Props) => {
  const { title } = useContext(PaymentMethodInfoContext);
  const summaryToShow =
    paymentMethodInfoSummary?.summary ||
    title ||
    'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Explicabo optio sint dolor labore tempore? Consequuntur, repudiandae? beatae neque? Quas modi cumque eos fuga ut!';

  return (
    <S.PaymentMethodInfoSummary className={className} style={style}>
      {summaryToShow}
    </S.PaymentMethodInfoSummary>
  );
};

export default PaymentMethodInfoSummary;
