import styled from 'styled-components';

interface WebTitleProps {
  size?: 'small' | 'medium';
}

export const WebTitle = styled.h1<WebTitleProps>`
  text-align: center;
  color: var(--color-white-soft);
  font-size: ${({ size }) => (size === 'small' ? '18px' : size === 'medium' ? '30px' : '40px')};
  width: ${({ size }) =>
    size === 'small' ? 'fit-content' : size === 'medium' ? 'min(1200px, 90%)' : 'min(900px, 90%)'};
  margin-bottom: 30px;
  letter-spacing: -1px;
  text-transform: none;
  transition: var(--transition);

  @media screen and (min-width: 600px) {
    font-size: ${({ size }) => (size === 'small' ? '24px' : size === 'medium' ? '30px' : '60px')};
  }

  @media screen and (min-width: 900px) {
    font-size: ${({ size }) => (size === 'small' ? '32px' : size === 'medium' ? '40px' : '80px')};
  }

  @media screen and (min-width: 1200px) {
    font-size: ${({ size }) => (size === 'small' ? '40px' : size === 'medium' ? '50px' : '100px')};
  }
`;
