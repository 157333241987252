import { PaymentMethodInfoContext } from 'infrastructure/components/molecules/PaymentMethodInfo/PaymentMethodInfo';
import { CSSProperties, useContext } from 'react';
import * as S from './PaymentMethodInfoTitle.styled';

interface Props {
  paymentMethodInfoTitleProps?: PaymentMethodInfoTitleProps;
  className?: string;
  style?: CSSProperties;
}

interface PaymentMethodInfoTitleProps {
  title: string;
}

const PaymentMethodInfoTitle = ({ paymentMethodInfoTitleProps, className, style }: Props) => {
  const { title } = useContext(PaymentMethodInfoContext);
  const titleToShow = paymentMethodInfoTitleProps?.title || title || 'Example';

  return (
    <S.PaymentMethodInfoTitle className={className} style={style}>
      <S.MethodName>{titleToShow}</S.MethodName>
      <S.Line />
    </S.PaymentMethodInfoTitle>
  );
};

export default PaymentMethodInfoTitle;
