import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  border-radius: 10px;
  width: min(100%, 500px);
  z-index: 1;

  & button:disabled {
    cursor: not-allowed;
    display: flex;
    color: #0e0e35ab;
  }
`;

export const SpinnerCont = styled.div`
  margin: 0 auto;
`;
