import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Navbar = styled.nav`
  display: none;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 900px) {
    display: flex;
  }

  & button {
    margin-left: 36px;
  }
`;

export const Links = styled(Link)``;
