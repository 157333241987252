import * as S from './ArticleNavLink.styled';

interface Props {
  ArticleNavLinkProps?: ArticleNavLinkProps;
}

interface ArticleNavLinkProps {
  to: string;
  text: string;
}

const ArticleNavLink = ({ ArticleNavLinkProps }: Props) => {
  let textToShow;
  let to: string;

  if (ArticleNavLinkProps) {
    textToShow = ArticleNavLinkProps.text;
    to = ArticleNavLinkProps.to;
  } else {
    textToShow = 'Lorem ipsum dolor sit amet.';
    to = '';
  }

  return <S.ArticleNavLink to={to}>{textToShow}</S.ArticleNavLink>;
};

export default ArticleNavLink;
