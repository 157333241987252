import { Props } from './interfaces/interfaces';
import useFormatDate from './hooks/useFormatDate';

import * as S from './InvoiceTableRowDetails.styled';

const InvoiceTableRowDetails = ({ invoiceTableRowDetailsProps, ...rest }: Props) => {
  const { emision, expiration, description, invoiceToCheck, invoiceNumber } =
    invoiceTableRowDetailsProps;

  const { suspension } = useFormatDate({ date: expiration });

  return (
    <S.InvoiceTableRowDetails {...rest}>
      <S.FullColumn colSpan={6}>
        <S.DetailsInnerCont data-selected={invoiceToCheck === invoiceNumber}>
          <S.Fields>
            <S.FieldName>Emitida</S.FieldName> <S.FieldContent>{emision}</S.FieldContent>
          </S.Fields>
          <S.Fields>
            <S.FieldName>Vencimiento</S.FieldName> <S.FieldContent>{expiration}</S.FieldContent>
          </S.Fields>
          <S.Fields>
            <S.FieldName>Descripción</S.FieldName>
            <S.Description>
              <S.DescriptionField>{description.title}</S.DescriptionField>
              <S.DescriptionField>Fecha de Suspensión: {suspension} </S.DescriptionField>
              <S.DescriptionField>Plan internet: {description.internetPlan}</S.DescriptionField>
              <S.DescriptionField>Descarga: {description.descarga}</S.DescriptionField>
              <S.DescriptionField>Subida: {description.subida}</S.DescriptionField>
            </S.Description>
          </S.Fields>
        </S.DetailsInnerCont>
      </S.FullColumn>
    </S.InvoiceTableRowDetails>
  );
};

export default InvoiceTableRowDetails;
