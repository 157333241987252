import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const InstallAndCostInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 900px) {
    width: 315px;
  }

  @media screen and (min-width: 1200px) {
    width: 380px;
    align-items: flex-start;
  }

  & h2 {
    text-align: center;

    @media screen and (min-width: 900px) {
      text-align: right;
    }
  }

  & p {
    width: 60%;
    text-align: center;

    @media screen and (min-width: 900px) {
      text-align: right;
      width: 100%;
    }
  }
`;

export const Links = styled(Link)`
  @media screen and (min-width: 900px) {
    margin-left: auto;
  }
`;
