import type { RootState } from 'infrastructure/store';
import { createApi, fetchBaseQuery, FetchArgs } from '@reduxjs/toolkit/query/react';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { setCredentials, clearCredentials } from 'infrastructure/store/slices/auth';

const baseQuery = fetchBaseQuery({
  //the baseQuery will set the base url, the credential a put the access token in the header
  baseUrl: process.env.REACT_APP_ROOT_ENDPOINT,

  credentials: 'include',

  prepareHeaders: (headers, { getState }) => {
    const { auth } = getState() as RootState;
    const { accessToken } = auth;

    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return headers;
  },
});

type args = string | FetchArgs;
type refreshResultData = {
  status: 'idle' | 'loading' | 'success' | 'error';
  message: string;
  accessToken: string;
  user: {
    userId: string;
    partnerId: number;
    role: string;
  };
};
//?creating reAuthLogic
export const baseQueryWithReAuth = async (args: args, api: BaseQueryApi, extraOptions: {}) => {
  let result = await baseQuery(args, api, extraOptions);

  //it should be originalStatusCode === 401
  if (result?.error?.status === 401) {
    //send the refresh token to the backend and get a new access token
    const refreshResult = await baseQuery('/auth/refreshToken', api, extraOptions);
    if (refreshResult?.data) {
      const { status, message, accessToken, user } = refreshResult.data as refreshResultData;

      //update the access token in the store
      api.dispatch(setCredentials({ status, message, accessToken, user }));
      //rerun the query
      result = await baseQuery(args, api, extraOptions);
    } else {
      //clear the credentials in the store
      api.dispatch(clearCredentials());
    }
  }

  return result;
};
//creating the API
export const authInterceptorApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({}),
});
