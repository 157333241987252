import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const SupportArticleTitleSet = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const ArticleLink = styled(Link)`
  margin-bottom: 60px;
  width: fit-content;
  color: #b5b5b5;
  transition: var(--transition-fast);

  &:hover {
    color: var(--color-white);
  }
`;
