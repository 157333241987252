import { useEffect, useState } from 'react';
import { useSpring, config } from 'react-spring';
import { useMedia } from 'react-use';

const useProductsSpringAnim = () => {
  const IsCentering = useMedia('(min-width: 900px)');

  const [mousePos, setMousePos] = useState({
    x: 0,
    y: 0,
  });

  const [dec1Plx, SetDec1Plx] = useState({
    px: 0,
    py: 0,
  });
  const [dec2Plx, SetDec2Plx] = useState({
    px: 0,
    py: 0,
  });
  const [dec3Plx, SetDec3Plx] = useState({
    px: 0,
    py: 0,
  });
  const [dec4Plx, SetDec4Plx] = useState({
    px: 0,
    py: 0,
  });

  const [dec5Plx, SetDec5Plx] = useState({
    px: 0,
    py: 0,
  });

  const mousePosListening = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const { left, top, height, width } = e.currentTarget.getBoundingClientRect();
    setMousePos({
      x: (e.clientX - left) / width - 0.5,
      y: (e.clientY - top) / height - 0.5,
    });
  };

  const springDec1 = useSpring({
    transform: IsCentering
      ? `translate(calc(-50% + ${dec1Plx.px}px),calc(-50% + ${dec1Plx.py}px))`
      : `translate(${dec1Plx.px}px,${dec1Plx.py}px)`,
  });

  const springDec2 = useSpring({
    transform: IsCentering
      ? `translate(calc(-50% + ${dec2Plx.px}px),calc(-50% + ${dec2Plx.py}px))`
      : `translate(${dec2Plx.px}px,${dec2Plx.py}px)`,

    config: config.slow,
  });

  const springDec3 = useSpring({
    transform: IsCentering
      ? `translate(calc(-50% + ${dec3Plx.px}px),calc(-50% + ${dec3Plx.py}px))`
      : `translate(${dec3Plx.px}px,${dec3Plx.py}px)`,

    config: config.wobbly,
  });

  const springDec4 = useSpring({
    transform: IsCentering
      ? `translate(calc(-50% + ${dec4Plx.px}px),calc(-50% + ${dec4Plx.py}px))`
      : `translate(${dec4Plx.px}px,${dec4Plx.py}px)`,

    config: config.wobbly,
  });

  const springDec5 = useSpring({
    transform: IsCentering
      ? `translate(calc(-50% + ${dec5Plx.px}px),calc(-50% + ${dec5Plx.py}px))`
      : `translate(${dec5Plx.px}px,${dec5Plx.py}px)`,
    config: config.wobbly,
  });

  useEffect(() => {
    SetDec1Plx({
      px: mousePos.x * 30,
      py: mousePos.y * 30,
    });
    SetDec2Plx({
      px: -mousePos.x * 10,
      py: mousePos.y * 10,
    });
    SetDec3Plx({
      px: mousePos.x * 20,
      py: -mousePos.y * 20,
    });
    SetDec4Plx({
      px: -mousePos.x * 5,
      py: -mousePos.y * 5,
    });
    SetDec5Plx({
      px: mousePos.x * 15,
      py: mousePos.y * 15,
    });
  }, [mousePos]);

  return { mousePosListening, springDec1, springDec2, springDec3, springDec4, springDec5 };
};

export default useProductsSpringAnim;
