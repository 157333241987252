import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  scrollCont: React.MutableRefObject<any>;
}

const useScrollTopOrHash = ({ scrollCont }: Props) => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === '') {
      // if not a hash link, scroll to top
      const timeOut = setTimeout(() => {
        scrollCont.current.scrollTo(0, 0);
      }, 0);

      return () => clearTimeout(timeOut);
    } else {
      // else scroll to id
      const timeOut = setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);

      return () => clearTimeout(timeOut);
    }

    // do this on route change
  }, [pathname, hash, key, scrollCont]);
};

export default useScrollTopOrHash;
