import { FormInput, FormTextArea, MainButton } from 'infrastructure/components';
import { useId } from 'react';
import { useForm } from './hooks';
import MoonLoader from 'react-spinners/MoonLoader';
import ReactGA from 'react-ga';

import * as S from './ContactForm.styled';

const ContactForm = () => {
  const id = useId();
  const { values, errors, disableSubmit, isLoading, isValidating, handleSubmit, handleChange } =
    useForm(
      {
        name: '',
        email: '',
        message: '',
      },
      {
        name: '',
        email: '',
        message: '',
      }
    );

  const gaClickContact = () => {
    ReactGA.event({
      category: 'Botón',
      action: 'Click',
      label: 'Botón de contact',
    });
  };

  return (
    <S.Form onSubmit={handleSubmit}>
      <FormInput
        FormInputProps={{
          id: `${id}-nameInput`,
          name: 'name',
          label: 'nombre',
          type: 'text',
          errorMessage: errors.name,
          onChange: handleChange,
          value: values.name,
          required: true,
          isValidating,
        }}
      />
      <FormInput
        FormInputProps={{
          id: `${id}-emailInput`,
          name: 'email',
          label: 'correo',
          type: 'email',
          errorMessage: errors.email,
          onChange: handleChange,
          value: values.email,
          required: true,
          isValidating,
        }}
      />
      <FormTextArea
        FormTextAreaProps={{
          id: `${id}-textArea`,
          name: 'message',
          label: 'mensaje',
          errorMessage: errors.message,
          onChange: handleChange,
          value: values.message,
          required: true,
          isValidating,
        }}
      />
      {isLoading ? (
        <S.SpinnerCont>
          <MoonLoader color='#1F3487' size={50} speedMultiplier={0.5} />
        </S.SpinnerCont>
      ) : (
        <MainButton
          MainButtonProps={{
            onClick: gaClickContact,
            color: 'orange',
            borderRadius: 'round',
            text: 'ENVIAR',
            disabled: disableSubmit,
            type: 'submit',
          }}
        />
      )}
    </S.Form>
  );
};

export default ContactForm;
