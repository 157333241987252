import { CategoryCardsSet, SectionTitle } from 'infrastructure/components';

import * as S from './PopularArticles.styled';

const PopularArticles = () => {
  return (
    <S.PopularArticles>
      <SectionTitle
        SectionTitleProps={{ text: 'Articulos Populares', color: 'white', size: 'small' }}
      />
      <CategoryCardsSet />
    </S.PopularArticles>
  );
};

export default PopularArticles;
