import * as S from './IndividualServiceInfo.styled';
import { SectionTitle, SectionText, MainButton } from 'infrastructure/components';

const IndividualServiceInfo = () => {
  return (
    <S.IndividualServiceInfo>
      <SectionTitle
        SectionTitleProps={{
          text: 'Instalación en menos de 48 horas.',
          color: 'blue',
        }}
      />

      <SectionText color='blue' text='¡Adquiérelo ya!' style={{ marginBottom: '10px' }} />
      <SectionText
        color='blue'
        text='Brindamos el mejor servicio de Internet con anchos de banda dedicados o distribuidos según sea su requerimiento.'
      />
      <S.Links to='/service'>
        <MainButton
          MainButtonProps={{
            text: 'Contrátanos',
            color: 'orange',
            borderRadius: 'round',
            type: 'button',
          }}
        />
      </S.Links>
    </S.IndividualServiceInfo>
  );
};

export default IndividualServiceInfo;
