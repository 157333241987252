import styled from 'styled-components';

export const CategoryCardTitle = styled.h3`
  color: var(--color-white-soft);
  font-size: 24px;
  word-wrap: anywhere;
  width: 80%;
  margin-bottom: 20px;

  @media screen and (min-width: 600px) {
    font-size: 26px;
    word-wrap: normal;
  }
`;
