import styled from 'styled-components';

export const SupportSelectedArticle = styled.div`
  width: min(100%, 1720px);
  min-height: 100vh;
  display: grid;

  grid:
    [row1-start] 'article' [row1-end]
    [row1-start] 'categoryArticles' [row1-end]
    / auto;
  grid-row-gap: 30px;

  @media screen and (min-width: 900px) {
    grid:
      [row1-start] 'categoryArticles article' [row1-end]
      / 2fr 8fr;
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    padding: 25px;
    grid-row-gap: 0px;
  }
`;

export const SubCategoryArticleCont = styled.aside`
  grid-area: categoryArticles;
  padding: 0px 0px 40px 0px;
  display: flex;
  justify-content: center;
  min-width: 250px;
  overflow-y: auto;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  padding: 25px;
  margin-bottom: 25px;

  @media screen and (min-width: 900px) {
    border-right: 1px solid rgba(242, 242, 242, 0.3);
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    margin-bottom: 0;
  }
`;

export const ArticleCont = styled.div`
  grid-area: article;
  color: white;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  padding: 25px;

  @media screen and (min-width: 900px) {
    box-shadow: none;
    border-radius: 0;
    padding: 0px 30px;
  }
`;

export const Article = styled.div`
  width: 100%;
  height: 100%;

  ol {
    padding: 0 0 0 20px;
    word-wrap: anywhere !important;

    @media screen and (min-width: 600px) {
      word-wrap: normal;
    }
  }

  table {
    background-color: var(--color-white-soft) !important;
    color: var(--color-blue-dark) !important;

    & th,
    td {
      border: 1px solid var(--color-blue-dark) !important;
    }
  }

  img {
    width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
    background-color: #f1f1f1;
    border-radius: 10px;
    padding: 10px;
  }

  & p {
    word-wrap: anywhere !important;
    line-height: normal !important;
    margin-bottom: 0px !important;

    span {
      color: var(--color-white-soft) !important;
      margin-bottom: 40px !important;
      display: block;
    }

    img {
      width: 100% !important;
      max-width: 100% !important;
      height: auto !important;
      background-color: #f1f1f1 !important;
      border-radius: 10px !important;
      padding: 10px !important;
    }

    a {
      font-weight: 600 !important;
      font-size: 16px !important;
      color: var(--color-orange-clear) !important;
    }
  }

  & span {
    color: var(--color-white-soft) !important;
    font-size: 18px !important;
    word-wrap: anywhere;

    @media screen and (min-width: 600px) {
      font-size: 20px !important;
      word-wrap: normal;
    }
  }
`;

export const Articletitle = styled.h2`
  font-size: calc(16px + 16 * ((100vw - 280px) / 320));
  font-family: inherit;
  margin-bottom: 50px;

  @media screen and (min-width: 600px) {
    font-size: 32px;
  }
`;

export const NavTitle = styled.span`
  color: var(--color-white-soft);
  font-size: 24px;
  margin-bottom: 40px;
  text-align: start;
  font-weight: 500;

  @media screen and (min-width: 900px) {
    text-align: center;
  }
`;

export const SpinnerCont = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
