import styled from 'styled-components';

export const SmarthHomeInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: min(380px, 100%);
  margin-bottom: 60px;
  z-index: 1;

  @media screen and (min-width: 900px) {
    margin-bottom: 0px;
  }
`;

export const MinorTitle = styled.h3`
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 20px;
  color: var(--color-blue-dark);

  @media screen and (min-width: 600px) {
    font-size: 50px;
  }
`;

export const ProductSpan = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: var(--color-blue-dark);
`;
