import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface StandarResponseProps {
  status: 'idle' | 'loading' | 'success' | 'error';
  message: string;
}

interface CategoriesResponse extends StandarResponseProps {
  data: CategoryShortProps[];
}

interface CategoryShortProps {
  id: number;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
}

interface CategoryResponse extends StandarResponseProps {
  data: CategoryFullProps;
}

interface CategoryFullProps extends CategoryShortProps {
  folders: FolderProps[];
}

interface FolderProps {
  id: number;
  name: string;
  description: string;
  articles_count: number;
  created_at: string;
  updated_at: string;
  platforms: any[];
  tags: any[];
  icon_url: null;
  visibility: number;
  articles: ArticlesProps[];
}

interface ArticlesProps {
  id: number;
  type: number;
  status: number;
  agent_id: number;
  created_at: string;
  category_id: number;
  folder_id: number;
  title: string;
  updated_at: string;
  description: string;
  description_text: string;
  seo_data: SEOData;
  tags: any[];
  attachments: any[];
  cloud_files: any[];
  thumbs_up: number;
  thumbs_down: number;
  hits: number;
  suggested: number;
  feedback_count: number;
  platforms: any[];
}

export interface SEOData {
  meta_title: string;
  meta_description: string;
}

interface PopularArticlesResponse extends StandarResponseProps {
  data: ArticlesProps[];
}

interface ArticlesResponse extends StandarResponseProps {
  data: ArticlesProps;
}

interface FolderResponse extends StandarResponseProps {
  data: FolderProps;
}

interface SearchArticlesResponse extends StandarResponseProps {
  data: ArticlesProps[];
}

export const supportApi = createApi({
  reducerPath: 'supportApi',

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ROOT_ENDPOINT,
  }),

  endpoints: (builder) => ({
    getAllCategories: builder.query({
      query: () => `/freshdesk/categories`,
      transformResponse(response: CategoriesResponse, meta, arg: null) {
        return response.data;
      },
    }),

    getPopularArticles: builder.query({
      query: () => '/freshdesk/popular',
      transformResponse(response: PopularArticlesResponse, meta, arg: null) {
        return response.data;
      },
    }),

    getCategory: builder.query({
      query: ({ id }) => `/freshdesk/categories/${id}`,
      transformResponse(response: CategoryResponse, meta, arg: { id: string }) {
        return response.data;
      },
    }),

    searchAllArticles: builder.query({
      query: ({ query }) => `/freshdesk/search?q=${query}`,
      transformResponse(response: SearchArticlesResponse, meta, arg: { query?: string }) {
        return response.data;
      },
    }),

    getArticle: builder.query({
      query: ({ id }) => `/freshdesk/articles/${id}`,
      transformResponse(response: ArticlesResponse, meta, arg: { id: string }) {
        return response.data;
      },
    }),

    getSubCategory: builder.query({
      query: ({ id }) => `/freshdesk/folders/${id}`,
      transformResponse(response: FolderResponse, meta, arg: { id: string }) {
        return response.data;
      },
    }),
  }),
});

export const {
  useGetAllCategoriesQuery,
  useGetPopularArticlesQuery,
  useGetCategoryQuery,
  useGetSubCategoryQuery,
  useGetArticleQuery,
  useSearchAllArticlesQuery,
} = supportApi;
