import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md';
import styled, { css } from 'styled-components';

export const ServiceCarousel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonCarouselLeft = styled(MdOutlineArrowBackIos)`
  color: var(--color-orange);
  font-size: 25px;
  margin-right: 10px;
  cursor: pointer;
`;

export const ButtonCarouselRight = styled(MdOutlineArrowForwardIos)`
  color: var(--color-orange);
  font-size: 25px;
  margin-left: 10px;
  cursor: pointer;
`;

export const CarouselImgCont = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  height: 600px;
  overflow-x: hidden;

  & img {
    position: absolute;
  }
`;

interface ImagesProps {
  position?: 'active' | 'left' | 'right' | 'hiddenleft' | 'hiddenRight' | '';
}

export const Images = styled.img<ImagesProps>`
  transition: all 1s linear;
  box-shadow: 0px 5px 10px 0px #00000060;
  max-width: 190px;
  height: 340px;

  @media screen and (min-width: 600px) {
    box-shadow: 0px 5px 15px 0px #00000060;
    max-width: 260px;
    height: 400px;
  }

  @media screen and (min-width: 1200px) {
    box-shadow: 0px 5px 20px 0px #00000060;
    max-width: 600px;
    height: 425px;
  }

  ${({ position }) =>
    position === 'active'
      ? css`
          transform: translate(-50%, 0);
          border-radius: 25px;
          z-index: 5;
          opacity: 1;
          left: 50%;
          object-fit: cover;
        `
      : position === 'left'
      ? css`
          transform: translate(-50%, 0);
          z-index: 3;

          opacity: 1;
          object-fit: cover;
          left: -200%;

          @media screen and (min-width: 600px) {
            left: 11%;
            max-width: 100px;
            height: 340px;
            border-radius: 15px;
          }

          @media screen and (min-width: 1200px) {
            left: 8%;
            max-width: 140px;
            height: 390px;
            border-radius: 30px;
          }
        `
      : position === 'right'
      ? css`
          transform: translate(-50%, 0);
          z-index: 3;

          opacity: 1;
          object-fit: cover;
          left: 200%;

          @media screen and (min-width: 600px) {
            left: 89%;
            max-width: 100px;
            height: 340px;
            border-radius: 15px;
          }

          @media screen and (min-width: 1200px) {
            left: 92%;
            max-width: 140px;
            height: 390px;
            border-radius: 30px;
          }
        `
      : position === 'hiddenleft'
      ? css`
          transform: translate(-50%, 0);
          z-index: -1;
          border-radius: 30px;
          object-fit: cover;
          opacity: 0;
          left: -200%;

          @media screen and (min-width: 1200px) {
            left: 8%;
            max-width: 140px;
            height: 390px;
          }
        `
      : position === 'hiddenRight'
      ? css`
          transform: translate(-50%, 0);
          z-index: -1;
          border-radius: 30px;
          object-fit: cover;
          opacity: 0;
          left: 200%;

          @media screen and (min-width: 1200px) {
            max-width: 140px;
            height: 390px;
            left: 92%;
          }
        `
      : css`
          transform: translate(-50%, 0);
          width: 140px;
          height: 390px;
          z-index: -1;
          opacity: 0;
        `}
`;

export const CarouselPointsCont = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  top: 90%;
  transform: translate(-50%, -50%);
`;

interface CarouselPointProps {
  $active?: boolean;
}

export const CarouselPoint = styled.div<CarouselPointProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: ${({ $active }) => ($active ? 'var(--color-orange)' : '#99aff2')};
`;
