import styled from 'styled-components';

export const PaymentMethodBanesco = styled.div`
  width: 100%;
  margin-bottom: 40px;

  @media screen and (min-width: 600px) {
    width: auto;
    margin-bottom: 0;
  }
`;

export const SummaryCont = styled.div`
  max-width: 400px;
  margin-bottom: 20px;
`;
