import styled from 'styled-components';

export const InvoiceTableFilterTab = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    align-items: center;
  }

  @media screen and (min-width: 900px) {
    align-items: flex-end;
    flex-direction: column;
  }

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    align-items: center;
  }
`;
