import { useEffect, useState } from 'react';

interface NetelipScriptProps {
  url: string;
  parent: React.RefObject<HTMLDivElement>;
}
const useNetelipScript = ({ url, parent }: NetelipScriptProps) => {
  const [beenClicked, setBeenClicked] = useState(false);

  useEffect(() => {
    const parentToappend = parent?.current;

    if (!parentToappend) return;
    const script = document.createElement('script');

    script.src = url;
    script.id = 'c2c-button';
    script.type = 'text/javascript';
    script.async = true;

    parentToappend?.appendChild(script);

    return () => {
      const parent = document.querySelector('#netelipCont');
      if (!parent || !parent.hasChildNodes()) return;

      parent.removeChild(script);
    };
  }, [url, parent]);

  useEffect(() => {
    const netelipComponent = document.querySelector('#netelip_form_c2c0') as HTMLDivElement | null;

    if (!netelipComponent) return;

    netelipComponent.style.border = 'none';
    netelipComponent.style.padding = '0';
    netelipComponent.style.boxShadow = '-2px 5px 13px 0px rgba(179, 179, 179, 0.75)';

    const header = netelipComponent.querySelector('div');

    if (!header) return;

    header.style.backgroundColor = '#1A396C';

    const buttons = header.querySelectorAll('div');

    buttons.forEach((div) => {
      div.style.paddingTop = '1px';
      div.style.borderRadius = '50%';

      const buttonsImg = div.querySelector('img');

      if (!buttonsImg) return;

      buttonsImg.style.borderRadius = '50%';
    });

    const form = netelipComponent.querySelector('form');

    if (!form) return;

    form.style.padding = '0 15px';

    const formDivs = form.querySelectorAll('div');

    const formBody = formDivs[1];

    formBody.style.textAlign = 'center';

    const inputCont = formBody.querySelector('div');

    if (!inputCont) return;

    inputCont.style.display = 'flex';
    inputCont.style.flexDirection = 'column';
    inputCont.style.justifyContent = 'center';

    const input = inputCont.querySelector('input');

    if (!input) return;

    input.style.fontFamily = 'inherit';
    input.style.color = '#183247';
    input.style.border = '1px solid grey';
    input.style.borderRadius = '2px';
    input.style.padding = '10px';
    input.style.marginBottom = '10px';

    const button = inputCont.querySelector('button');

    if (!button) return;

    button.style.padding = '5px';
    button.style.backgroundColor = '#1A396C';
    button.style.fontFamily = 'inherit';
    button.style.border = 'none';
    button.style.color = 'white';
    button.style.fontWeight = '600';

    return () => {};
  }, [beenClicked]);

  return {
    setBeenClicked,
    beenClicked,
  };
};

export default useNetelipScript;
