import { SectionTitle } from 'infrastructure/components';

import * as S from './ImportantNote.styled';

const ImportantNote = () => {
  return (
    <S.ImportantNote>
      <S.InnerCont>
        <SectionTitle
          SectionTitleProps={{ text: 'Nota importante', color: 'blue', weight: 'fat' }}
        />
        <S.Text>
          Trabajamos con una sola visita donde se realizará la factibilidad e instalación.
        </S.Text>
        <S.Text> El pago se efectuará en remoto a la visita.</S.Text>
        <S.Text>
          Al momento de la instalación debe poseer router y mantener los equipos conectados a un
          protector de corriente. En caso de no contar con router, le recomendamos comunicarse con
          nuestros aliados comerciales Wuitrade al 0412-6090473 quienes realizan una visita para una
          evaluación y recomendación totalmente gratuita.
        </S.Text>
        <S.Text>
          Nuestros precios no incluyen IVA, en caso de cancelar en divisa debe incluir el 3% de
          IGTF.
        </S.Text>
        <S.Text>
          De acuerdo a la Cláusula Décimo Tercera. Terminación Anticipada: Sección B. EL PRESTADOR
          podrá dar por terminado el CONTRATO previa notificación al ABONADO de la causa de dicha
          terminación y en consecuencia, suspenderá el SERVICIO cuando se demuestre que EL ABONADO
          utiliza el SERVICIO con fines diferentes a aquellos para lo que le es prestado o explote
          comercialmente el SERVICIO permitiendo su utilización por terceros en cualquier otra
          forma. Dicha determinación estará sujeta a los resultados de nuestros equipos de
          monitoreo.
        </S.Text>
      </S.InnerCont>
    </S.ImportantNote>
  );
};

export default ImportantNote;
