import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

export const InstallAndCost = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  min-height: calc(100vh - 121px);
  padding: 60px 0;
  overflow-x: hidden;

  @media screen and (min-width: 1920px) {
    min-height: auto;
    height: min(100vh, 800px);
  }
`;

export const InnerCont = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: min(1000px, 86%);
  height: 100%;

  @media screen and (min-width: 900px) {
    justify-content: space-between;
    align-items: center;
  }
`;

export const CarCont = styled(animated.div)`
  position: relative;
  width: 280px;
  height: 300px;
  margin-bottom: 40px;
  left: -100%;

  @media screen and (min-width: 600px) {
    width: 400px;
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 0;
    width: 500px;
  }
`;

export const Car = styled(animated.img)`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export const DecorationCont = styled(animated.div)`
  position: relative;
  width: 260px;
  height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (min-width: 600px) {
    width: 310px;
    height: 100%;
  }

  @media screen and (min-width: 900px) {
    width: 350px;
  }

  @media screen and (min-width: 1200px) {
    width: 420px;
  }
`;

interface DecorationProps {
  left: number;
  top: number;
  size: 'small' | 'medium' | 'large';
  $isRing?: boolean;
}

export const Decoration = styled.div<DecorationProps>`
  position: absolute;
  left: ${({ left }) => `${left}px`};
  top: ${({ top }) => `${top}px`};

  ${({ size }) =>
    size === 'small'
      ? css`
          width: 22px;
          height: 22px;
        `
      : size === 'medium'
      ? css`
          width: 34px;
          height: 34px;
        `
      : css`
          width: 80px;
          height: 80px;
        `}

  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: #0f78f2;

  ${({ $isRing, size }) =>
    $isRing
      ? css`
          &::after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            background-color: white;

            ${size === 'small'
              ? css`
                  width: 18px;
                  height: 18px;
                `
              : size === 'medium'
              ? css`
                  width: 28px;
                  height: 28px;
                `
              : css`
                  width: 74px;
                  height: 74px;
                `}
          }
        `
      : ''}
`;
