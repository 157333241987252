import styled, { css } from 'styled-components';

interface TransitionPageProps {
  showTransition?: boolean;
  fadeOut?: boolean;
}

export const TransitionPage = styled.div<TransitionPageProps>`
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1000;

  display: ${({ showTransition }) => (showTransition ? 'flex' : 'none')};
  ${({ fadeOut }) =>
    fadeOut === true &&
    css`
      animation: fadeOut 1s ease-in-out forwards;
      @keyframes fadeOut {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }
    `}
`;
