import styled from 'styled-components';

export const LocationMap = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  overflow-x: hidden;
  padding-bottom: 100px;
`;

export const InnerCont = styled.div`
  display: flex;

  flex-flow: wrap-reverse;
  width: min(1000px, 100%);

  justify-content: center;
  align-items: center;

  @media screen and (min-width: 900px) {
    height: 350px;
    justify-content: space-between;
  }
`;

export const WuipiLocationMap = styled.iframe`
  border: none;
  width: 100%;

  @media screen and (min-width: 900px) {
    width: 60%;
    height: 100%;
  }
`;

export const InfoCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding: 20px 10px;

  @media screen and (min-width: 900px) {
    width: 40%;
    padding-right: 35px;
    align-items: flex-end;
    justify-content: center;
  }

  & h2 {
    text-align: center;

    @media screen and (min-width: 900px) {
      text-align: right;
    }
  }

  & p {
    width: 90%;
    text-align: center;
    margin-bottom: 10px;

    @media screen and (min-width: 900px) {
      text-align: right;
    }
  }
`;
