import * as S from './ProductsInfo.styled';

const ProductsInfo = () => {
  return (
    <S.ProductsInfo>
      <S.MinorTitle>La forma sencilla de mejorar tu conexión Wi‑Fi</S.MinorTitle>
      <S.ProductSpan>Google Wifi Router</S.ProductSpan>
    </S.ProductsInfo>
  );
};

export default ProductsInfo;
