import { CSSProperties, useContext } from 'react';
import { SubCategoryContext } from 'infrastructure/components/molecules/SubCategory/SubCategory';
import { SupportArticleTitle } from 'infrastructure/components';

import * as S from './SupportArticleTitleSet.styled';
interface Props {
  supportArticleTitleSetProps?: SupportArticleTitleSetProps;
  className?: string;
  style?: CSSProperties;
}

interface SupportArticleTitleSetProps {
  articles?: ArticlesProps[];
}

interface ArticlesProps {
  id: number | false;
  type: number | false;
  status: number | false;
  agent_id: number | false;
  created_at: string | false;
  category_id: number | false;
  folder_id: number | false;
  title: string | false;
  updated_at: string | false;
  description: string | false;
  description_text: string | false;
  seo_data: SEOData | false;
  tags: any[] | false;
  attachments: any[] | false;
  cloud_files: any[] | false;
  thumbs_up: number | false;
  thumbs_down: number | false;
  hits: number | false;
  suggested: number | false;
  feedback_count: number | false;
  platforms: any[] | false;
}

interface SEOData {
  meta_title: string;
  meta_description: string;
}

const SupportArticleTitleSet = ({ supportArticleTitleSetProps, className, style }: Props) => {
  const { articles } = useContext(SubCategoryContext);

  let articlesToShow: ArticlesProps[] = supportArticleTitleSetProps?.articles ||
    articles || [
      {
        id: false,
        type: false,
        status: false,
        agent_id: false,
        created_at: false,
        category_id: false,
        folder_id: false,
        title: false,
        updated_at: false,
        description: false,
        description_text: false,
        seo_data: false,
        tags: false,
        attachments: false,
        cloud_files: false,
        thumbs_up: false,
        thumbs_down: false,
        hits: false,
        suggested: false,
        feedback_count: false,
        platforms: false,
      },
    ];

  return (
    <S.SupportArticleTitleSet className={className} style={style}>
      {articlesToShow.map(({ folder_id, id, title }) => {
        return (
          <S.ArticleLink
            to={`/support/articles/${folder_id}/${id}/#article`}
            key={`${id}-${title}`}
          >
            <SupportArticleTitle SupportArticleTitleProps={{ title }} />
          </S.ArticleLink>
        );
      })}
    </S.SupportArticleTitleSet>
  );
};

export default SupportArticleTitleSet;
