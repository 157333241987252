import styled from 'styled-components';

export const FormInput = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;

  &[data-ischeckbox='true'] {
    flex-direction: row-reverse;
    justify-content: flex-end;

    & label {
      font-size: 14px;
      cursor: pointer;
      font-weight: 500;
    }
  }
`;

export const Input = styled.input`
  padding: 15px;
  margin: 0px 0px 25px 0px;
  border-radius: 15px;
  background-color: var(--color-white-light);
  border: 1px solid var(--color-blue-dark);
  color: var(--color-blue-dark);
  outline: none;
  transition: var(--transition);
  font-size: 16px;

  &:focus {
    border: 1px solid var(--color-blue-sea);
  }

  &:active {
    background-color: var(--color-soft-purple);
  }

  &[disabled] {
    background-color: var(--color-grey);
    color: #625e5e;
    cursor: not-allowed;
  }

  &[data-focused='true'] ~ span {
    display: block;
  }

  &[data-focused='true'] {
    border: 1px solid #ef4848;
  }

  &[type='checkbox'] {
    margin-right: 10px;
    cursor: pointer;
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: var(--color-blue-dark);
`;

export const Span = styled.span`
  position: absolute;
  font-size: 16px;
  padding: 3px;
  color: #ef4848;
  display: none;
  bottom: -2px;
`;
