interface IValidation {
  name: string;
  email: string;
  message: string;
}

const validation = (props: IValidation) => {
  const errors = {
    name: '',
    email: '',
    message: '',
  };

  //Validation for fullName
  if (!props.name.trim()) {
    errors.name = 'Este campo es requerido';
  } else if (props.name.trim() && (props.name.trim().length < 3 || props.name.trim().length > 16)) {
    errors.name = 'Este campo solo acepta de 3 a 16 caracteres!';
  } else if (props.name.trim() && !/^[a-zA-Z ]*$/.test(props.name.trim())) {
    errors.name = 'Este campo no acepta numeros o caracteres especiales!';
  } else {
    errors.name = '';
  }

  //Validation for email
  if (!props.email.trim()) {
    errors.email = 'Este campo es requerido';
  } else if (
    props.email.trim() &&
    !props.email
      .trim()
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    errors.email = 'Por favor introduzca un email valido';
  } else {
    errors.email = '';
  }

  //Validation for message
  if (!props.message.trim()) {
    errors.message = 'Este campo es requerido';
  } else if (props.message.trim() && props.message.trim().length < 10) {
    errors.message = 'El campo mensaje debe ser minimo 10 caracteres!';
  } else {
    errors.message = '';
  }

  return errors;
};

export default validation;
