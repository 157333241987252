import { authInterceptorApi } from 'infrastructure/store/apis/auth';
import { clearCredentials } from 'infrastructure/store/slices/auth';

interface LoginProps {
  email: string;
  password: string;
}

interface FinishSignupProps {
  email: string;
  password: string;
  partnerId: string;
}

interface AuthResponseProps {
  status: 'idle' | 'loading' | 'success' | 'error';
  message: string;
  accessToken: string;
  user: {
    userId: string;
    partnerId: number;
    role: string;
  };
}

interface PasswordRecoveryProps {
  email: string;
}

interface PasswordRecoveryResponseProps {
  status: string;
}

interface PasswordResetProps {
  newPassword: string;
  userId: string;
  token: string;
}

interface CleanCookieResponseProps {
  status: 'idle' | 'loading' | 'success' | 'error';
  message: string;
}

//now i can make diferent http request and all of then will have the access token, and if the access token is expired,
//the authInterceptor will use the cookie to get a new access token from the backend
export const authApiEndPoints = authInterceptorApi.injectEndpoints({
  endpoints: (builder) => ({
    //here i can add all the endpoints that i need
    login: builder.mutation<AuthResponseProps, LoginProps>({
      query: ({ email, password }) => ({
        url: '/auth/login',
        method: 'POST',
        body: { email, password },
        keepUnusedDataFor: 1,
      }),
    }),

    finishSignup: builder.mutation<AuthResponseProps, FinishSignupProps>({
      query: ({ email, password, partnerId }) => ({
        url: '/auth/register',
        method: 'POST',
        body: { email, password, partnerId },
        keepUnusedDataFor: 1,
      }),
    }),

    passwordRecovery: builder.mutation<PasswordRecoveryResponseProps, PasswordRecoveryProps>({
      query: ({ email }) => ({
        url: '/resetPwd',
        method: 'POST',
        body: { email },
      }),
    }),

    passwordReset: builder.mutation<PasswordRecoveryResponseProps, PasswordResetProps>({
      query: ({ newPassword, userId, token }) => ({
        url: `/resetPwd/${userId}/${token}`,
        method: 'POST',
        body: { newPassword },
      }),
    }),

    cleanCookie: builder.mutation<CleanCookieResponseProps | null, null>({
      query: () => ({
        url: 'auth/logout',
        method: 'GET',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          dispatch(clearCredentials());
          setTimeout(() => {
            dispatch(authInterceptorApi.util.resetApiState());
          }, 1000);
        } catch (error) {
          console.log('clean Cookie onQueryStarted', error);
        }
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useFinishSignupMutation,
  usePasswordRecoveryMutation,
  usePasswordResetMutation,
  useCleanCookieMutation,
} = authApiEndPoints;
