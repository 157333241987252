import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

export const IndividualService = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  min-height: calc(100vh - 121px);
  padding: 60px 0;
  overflow-x: hidden;

  @media screen and (min-width: 1920px) {
    min-height: auto;
    height: min(100vh, 800px);
  }
`;

export const InnerCont = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: min(1000px, 86%);
  height: 100%;

  @media screen and (min-width: 900px) {
    justify-content: space-between;
    align-items: center;
  }
`;

export const CarCont = styled(animated.div)`
  position: relative;
  width: 280px;
  height: 300px;
  margin-bottom: 40px;
  left: -100%;

  @media screen and (min-width: 600px) {
    width: 400px;
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 0;
    width: 500px;
  }
`;

export const Car = styled(animated.img)`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export const DecorationCont = styled(animated.div)`
  position: relative;
  width: 260px;
  height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (min-width: 600px) {
    width: 310px;
    height: 100%;
  }

  @media screen and (min-width: 900px) {
    width: 350px;
  }

  @media screen and (min-width: 1200px) {
    width: 420px;
  }
`;

export const LeftDecoration = styled(animated.div)`
  position: absolute;
  top: 50%;
  left: 25%;
  width: 115px;
  height: 100px;
  border-radius: 25%;
  transform: translate(-50%, -50%);
  background-color: #99aff2;
  z-index: 1;

  @media screen and (min-width: 600px) {
    width: 150px;
    height: 130px;
    border-radius: 25%;
  }

  @media screen and (min-width: 900px) {
    width: 160px;
    height: 140px;
    border-radius: 18%;
  }

  @media screen and (min-width: 1200px) {
    width: 190px;
    height: 170px;
  }
`;

export const RightDecoration = styled(animated.div)`
  position: absolute;
  top: 50%;
  left: 70%;
  width: 140px;
  height: 120px;
  transform: translate(-50%, -50%);
  border-radius: 28%;
  background-color: #0f71f2;

  @media screen and (min-width: 600px) {
    width: 160px;
    height: 150px;
  }

  @media screen and (min-width: 900px) {
    width: 190px;
    height: 170px;
    border-radius: 22%;
  }

  @media screen and (min-width: 1200px) {
    width: 250px;
    height: 220px;
  }
`;

interface DecorationProps {
  left: number;
  top: number;
  size: 'small' | 'medium' | 'large';
}

export const Decoration = styled.div<DecorationProps>`
  position: absolute;
  left: ${({ left }) => `${left}px`};
  top: ${({ top }) => `${top}px`};
  ${({ size }) =>
    size === 'small'
      ? css`
          width: 60px;
          height: 20px;
        `
      : size === 'medium'
      ? css`
          width: 110px;
          height: 35px;
        `
      : css`
          width: 150px;
          height: 50px;
        `}

  border-radius: 50px;
  transform: translate(-50%, -50%) rotate(15deg);
  background-color: #99aff2;
`;
