import { PaymentMethodInfoContext } from 'infrastructure/components/molecules/PaymentMethodInfo/PaymentMethodInfo';
import { CSSProperties, useContext } from 'react';
import * as S from './PaymentMethodCredentials.styled';

interface Props {
  paymentMethodCredentialsProps?: PaymentMethodCredentialsProps;
  className?: string;
  style?: CSSProperties;
}

interface PaymentMethodCredentialsProps {
  credential: string;
}

const PaymentMethodCredentials = ({ paymentMethodCredentialsProps }: Props) => {
  const { credentials } = useContext(PaymentMethodInfoContext);
  const credentialToShow =
    paymentMethodCredentialsProps?.credential || credentials || 'Lorem, ipsum dolor sit amet odio!';

  return <S.PaymentMethodCredentials>{credentialToShow}</S.PaymentMethodCredentials>;
};

export default PaymentMethodCredentials;
