import styled from 'styled-components';

export const LayoutRoot = styled.div`
  display: grid;
  grid:
    [row1-start] 'notification' 45px [row1-end]
    [row2-start] 'page' calc(100vh - 45.5px) [row2-end]
    / auto;
  min-height: 100%;

  margin: 0 auto;
`;
