import styled from 'styled-components';

export const InvoiceTable = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  width: 100%;

  scrollbar-color: #555 #fff;
  scrollbar-width: thin;

  /* width */
  ::-webkit-scrollbar {
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 1px;
  }
`;

export const Table = styled.table`
  width: max(100%, 850px);
  margin-top: 15px;
  border-collapse: collapse;
`;

export const InvoiceTableBody = styled.tbody``;
