import * as S from './CategoryTitle.styled';

interface Props {
  className?: string;
  style?: {};
  CategoryTitleProps?: CategoryTitleProps;
}

interface CategoryTitleProps {
  title?: string;
}

const CategoryTitle = ({ CategoryTitleProps }: Props) => {
  const title = CategoryTitleProps?.title;
  let titleContent;

  if (title) {
    titleContent = title;
  } else {
    titleContent = 'Lorem ipsum dolor sit amet consectetur adipisicing elit.';
  }

  return <S.CategoryTitle>{titleContent}</S.CategoryTitle>;
};

export default CategoryTitle;
