import { useEffect, useState } from 'react';
import { RowDataProps } from '../interfaces/interfaces';

const useSeeDetails = (dataToshow: RowDataProps[]) => {
  const [invoiceToCheck, setInvoiceToCheck] = useState<number>(-1);

  const openDetails = (invoiceNumber: number | false) => {
    if (invoiceNumber) {
      setInvoiceToCheck(invoiceNumber);
    }
  };

  useEffect(() => {
    if (dataToshow[0]?.invoiceNumber) {
      setInvoiceToCheck(dataToshow[0]?.invoiceNumber);
    }
  }, [dataToshow]);

  return { openDetails, invoiceToCheck };
};

export default useSeeDetails;
