import * as S from './SupportMainButton.styled';

interface Props {
  SupportMainButtonProps: SupportMainButtonProps;
  type: 'button' | 'reset' | 'submit';
}

interface SupportMainButtonProps {
  text: string;
}

const SupportMainButton = ({ SupportMainButtonProps, type }: Props) => {
  const { text } = SupportMainButtonProps;

  return <S.SupportMainButton type={type}>{text}</S.SupportMainButton>;
};

export default SupportMainButton;
