import styled from 'styled-components';

export const ClientsPaymentMethods = styled.div`
  padding: 40px 0 0 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;
