import * as S from './Support.styled';
import { SupportBanner } from 'infrastructure/components';
import { Outlet } from 'react-router-dom';

const Support = () => {
  return (
    <S.Support data-component-name='Main Content'>
      <SupportBanner />
      <S.CategoriesInfoCont id='article'>
        <Outlet />
      </S.CategoriesInfoCont>
    </S.Support>
  );
};

export default Support;
