import styled from 'styled-components';

export const SubCategoryTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.h3`
  font-size: 20px;
  margin: 0px 0 20px 0;
  word-wrap: anywhere;

  @media screen and (min-width: 600px) {
    font-size: 28px;
    word-wrap: normal;
  }
`;

export const Line = styled.hr`
  width: 100%;
  margin-bottom: 80px;
`;
