import { useRef } from 'react';
import { IndividualServiceInfo } from 'infrastructure/components';
import { usePhonesSpringAnim } from './hooks';
import { car } from 'infrastructure/assets';

import * as S from './IndividualService.styled';

const IndividualService = () => {
  const animContRef = useRef(null);
  const { mousePosListening, springDec1, springDec2, carMoveSpringStyles } = usePhonesSpringAnim({
    animContRef,
  });

  return (
    <S.IndividualService onMouseMove={mousePosListening} id='servicio' ref={animContRef}>
      <S.InnerCont>
        <S.CarCont style={carMoveSpringStyles}>
          <S.Car src={car} alt='car' style={springDec2} />
          <S.DecorationCont style={springDec1}>
            <S.Decoration left={10} top={-20} size='large' />
            <S.Decoration left={230} top={50} size='medium' />
            <S.Decoration left={-120} top={20} size='medium' />
            <S.Decoration left={-40} top={85} size='small' />
            <S.Decoration left={0} top={145} size='large' />
          </S.DecorationCont>
        </S.CarCont>
        <IndividualServiceInfo />
      </S.InnerCont>
    </S.IndividualService>
  );
};

export default IndividualService;
