import styled from 'styled-components';

export const InvoiceTableRowDetails = styled.tr`
  border-bottom: 1px solid var(--color-grey);
`;

export const FullColumn = styled.td``;

export const DetailsInnerCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 0px;
  overflow: hidden;
  transition: var(--transition);

  &[data-selected='true'] {
    height: 210px;
  }
`;

export const Fields = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 13px;
  width: 100%;

  @media screen and (min-width: 900px) {
    padding: 5px 40px;
  }

  &:nth-child(1) {
    padding-top: 5px;
  }
`;

export const FieldName = styled.span`
  color: var(--color-blue-dark);
  font-size: 16px;
`;

export const FieldContent = styled.span`
  color: var(--color-blue-dark);
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: end;
`;

export const DescriptionField = styled.span`
  color: var(--color-blue-dark);
  font-family: inherit;
  font-size: 16px;
  padding: 4px 0;
`;
