import { CSSProperties, useContext } from 'react';
import { SubCategoryContext } from 'infrastructure/components/molecules/SubCategory/SubCategory';

import * as S from './SubCategory.styled';
interface Props {
  SubCategoryProps?: SubCategoryProps;
  className?: string;
  style?: CSSProperties;
}

interface SubCategoryProps {
  title?: string;
  subCategoryId?: number;
}

const SubCategoryTitle = ({ SubCategoryProps, className, style }: Props) => {
  const { title } = useContext(SubCategoryContext);
  const titleToShow: string = SubCategoryProps?.title || title || 'Lorem ipsum dolor sit amet.';

  return (
    <S.SubCategoryTitle className={className} style={style}>
      <S.Title>{titleToShow}</S.Title>
      <S.Line />
    </S.SubCategoryTitle>
  );
};

export default SubCategoryTitle;
