import * as S from './CallBot.styled';

const CallBot = () => {
  return (
    <S.CallBot>
      <S.Phone />
    </S.CallBot>
  );
};

export default CallBot;
