import { CSSProperties, useRef } from 'react';
import { MoonLoader } from 'react-spinners';
import { useSearchFilter } from './hooks';

import * as S from './SearchBar.styled';

interface Props {
  SearchBarProps: SearchBarProps;
  className?: string;
  style?: CSSProperties;
}

interface SearchBarProps {
  placeholder: string;
}

const SearchBar = ({ SearchBarProps, ...resProps }: Props) => {
  const listBoxRef = useRef<HTMLDivElement | null>(null);
  const { placeholder } = SearchBarProps;
  const { values, resultData, isFetching, cleanOnSearch, preventBlur, handleChange, cleanOnBlur } =
    useSearchFilter();

  return (
    <S.Search {...resProps}>
      <S.SearchBar>
        <S.SearchIcon />
        <S.SearchInput
          placeholder={placeholder}
          value={values.text}
          onChange={handleChange}
          onBlur={cleanOnBlur}
        />
        {isFetching ? (
          <S.LoadingCont>
            <MoonLoader color='blue' size={25} inputMode='search' speedMultiplier={0.5} />
          </S.LoadingCont>
        ) : null}
      </S.SearchBar>
      {resultData.length > 0 ? (
        <S.ListBox data-name='listBox' ref={listBoxRef}>
          {resultData.map(({ value, id, folder_id }) => (
            <S.LinkTo
              to={`/support/articles/${folder_id}/${id}/#article`}
              key={`${folder_id}/${id}`}
              onMouseDown={preventBlur}
              onClick={cleanOnSearch}
            >
              <S.ListBoxItem key={value}>{value}</S.ListBoxItem>
            </S.LinkTo>
          ))}
        </S.ListBox>
      ) : null}
    </S.Search>
  );
};

export default SearchBar;
