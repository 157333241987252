import styled from 'styled-components';

export const CategoryTitle = styled.h2`
  font-size: 26px;
  word-wrap: anywhere;
  margin: 40px 0 80px 0;

  @media screen and (min-width: 600px) {
    word-wrap: normal;
    font-size: 38px;
  }
`;
