import { BsTelephoneFill } from 'react-icons/bs';

import styled from 'styled-components';

export const CallBot = styled.div`
  position: absolute;
  background-color: #1c3e75;
  width: 58px;
  height: 58px;
  border-radius: 50% 5px 50% 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.15), 0 5px 4px 0 rgba(0, 0, 0, 0.26);
`;

export const Phone = styled(BsTelephoneFill)`
  font-size: 24px;
`;
