import { useAppSelector } from 'infrastructure/hooks';
import { useEffect, useState } from 'react';
import { FormatAmoutsProps } from '../interfaces/interfaces';

const useFormatAmounts = ({
  currencyId,
  amountTotal,
  amountTotalSigned,
  amountResidual,
  amountResidualSigned,
}: FormatAmoutsProps) => {
  const [amount, setAmount] = useState<{
    USD: number | null;
    VEF: number | null;
    CVEF: number | null;
    RVEF: number | null;
    RUSD: number | null;
  }>({
    USD: null,
    CVEF: null,
    VEF: null,
    RVEF: null,
    RUSD: null,
  });
  const {
    clientData: { exchangeRate: currentExchangeRate },
  } = useAppSelector((state) => state.client);

  const currencyIdName = currencyId[1];

  const unformatedRawAmount = amountTotal || 0;
  const unformatedAmountTotalSigned = amountTotalSigned || 0;
  const unformatedCurrentExchangeRate = currentExchangeRate || 0;
  const unformatedAmountResidual = amountResidual || 0;
  const unformatedAmountResidualSigned = amountResidualSigned || 0;

  useEffect(() => {
    if (currencyIdName === 'USD') {
      const unformatedCurrentVEFAmount = unformatedRawAmount * unformatedCurrentExchangeRate;
      const formatedCurrentVEFAmount = Math.floor(unformatedCurrentVEFAmount * 100) / 100;
      const formatedRawAmount = Math.floor(unformatedRawAmount * 100) / 100;

      const formatedAmountResidualSigned = Math.floor(unformatedAmountResidualSigned * 100) / 100;

      setAmount({
        USD: formatedRawAmount,
        VEF: null,
        CVEF: formatedCurrentVEFAmount,
        RUSD: formatedAmountResidualSigned,
        RVEF: null,
      });
    } else if (currencyIdName === 'VEF') {
      const formatedUSDAmount = Math.floor(unformatedAmountTotalSigned * 100) / 100;
      const formatedRawAmount = Math.floor(unformatedRawAmount * 100) / 100;
      const formatedAmountResidual = Math.floor(unformatedAmountResidual * 100) / 100;
      const formatedAmountResidualSigned = Math.floor(unformatedAmountResidualSigned * 100) / 100;

      setAmount({
        USD: formatedUSDAmount,
        VEF: formatedRawAmount,
        CVEF: formatedRawAmount,
        RUSD: formatedAmountResidualSigned,
        RVEF: formatedAmountResidual,
      });
    }
  }, [
    currencyId,
    currencyIdName,
    unformatedRawAmount,
    unformatedAmountTotalSigned,
    unformatedCurrentExchangeRate,
    unformatedAmountResidual,
    unformatedAmountResidualSigned,
  ]);

  return { amount };
};

export default useFormatAmounts;
