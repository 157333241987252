import styled from 'styled-components';
import { BsArrowLeft } from 'react-icons/bs';
import { BsArrowRight } from 'react-icons/bs';

export const InvoiceTablePagination = styled.div`
  display: flex;
  align-self: end;
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
`;

export const InvoiceTableArrowCont = styled.div`
  display: flex;
  align-items: center;
`;

export const LeftArrow = styled(BsArrowLeft)`
  margin-right: 10px;
  font-size: 20px;
  cursor: pointer;
`;

export const RightArrow = styled(BsArrowRight)`
  margin-left: 10px;
  font-size: 20px;
  cursor: pointer;

  &[data-maxed='true'] {
    cursor: not-allowed;
    color: grey;
  }
`;

export const ActualPage = styled.div`
  box-shadow: 0 0 1px 1px grey;
  padding: 2px 5px;
  border-radius: 2px;
`;

export const HowManyCont = styled.div`
  font-size: 16px;
`;
