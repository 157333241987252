import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

:root {
  /*
* =============== 
* Variables
* ===============
*/

/*
* Page Main color 
*/
  --color-white: #FFFFFF;
  --color-white-soft: #F2F2F2;
  --color-white-light: #FAFAFA;
  --color-grey: #00000029;
  --color-solid-grey: #B3B3B3;
  --color-blue: #6398F5;
  --color-blue-sky: #669DF7;
  --color-blue-deep: #1F3487;
  --color-blue-dark: #0E0E35;
  --color-blue-sea: #4270ED;
  --color-purple: #1D183F;
  --color-orange: #D66818;
  --color-orange-dark: #C74900;
  --color-orange-clear: #FFAF51;
  --color-black: #000000;
  
  



/*
* Trinkes 
*/
  --transition-fast: all 0.2s ease-in-out;
  --transition: all 0.3s ease-in-out;
  --transition-slow: all 0.6s ease-in-out;
  --transition-very-slow: all 0.9s ease-in-out;
  --spacing: 0.1rem;
  --subtitle-spacing: 3px;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/*
* =============== 
* Global Styles
* ===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html,
body {
  height: 100%;
}

:root {
  font-family:  'Roboto', 'Helvetica', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell,
    'Open Sans',  sans-serif;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  line-height: 1.25;
}

button {
  &:focus {
    outline: 2px solid rgba(0,0,0,0.50);
    outline: none;
  }
}

a {
  &:focus {
    outline: 2px solid rgba(0,0,0,0.50);
    outline: none;
  }
}

input[type='radio'] {
  outline: none
}


h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 0.875rem;
}

p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}

/*
* ==================== 
* Custom Project Style
* ====================
*/

#root {
  min-height: 100%;
}


`;

export default GlobalStyle;
