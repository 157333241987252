import { ReactNode } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

interface Props {
  children: ReactNode;
  title: string;
  author: string;
  description: string;
}

const Meta = ({ children, title, author, description }: Props) => {
  return (
    <HelmetProvider>
      <>
        <Helmet prioritizeSeoTags>
          <title>{title}</title>
          <meta name='author' content={author} />
          <meta name='description' content={description} />
        </Helmet>
        <Helmet>
          <meta charSet='utf-8' />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <meta name='theme-color' content='#000000' />
        </Helmet>
        <Helmet>
          <link rel='preconnect' href='https://fonts.googleapis.com' />
          <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin='' />
          <link
            href='https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'
            rel='stylesheet'
          />
        </Helmet>
        <Helmet>
          <link rel='icon' href='./favicon.ico' />
          <link rel='manifest' href='./manifest.json' />
          <link rel='apple-touch-icon' href='./logo192.png' />
        </Helmet>
        <Helmet>
          {/* Freshdesk */}
          <script typeof='text/javascript'>
            {`
            (function (d, w, c) {
              if (!d.getElementById('spd-busns-spt')) {
                var n = d.getElementsByTagName('script')[0],
                  s = d.createElement('script');
                var loaded = false;
                s.id = 'spd-busns-spt';
                s.async = 'async';
                s.setAttribute('data-self-init', 'false');
                s.setAttribute('data-init-type', 'opt');
                s.src = 'https://cdn.freshbots.ai/assets/share/js/freshbots.min.js';
                s.setAttribute('data-client', '3fa37ba337047fd212cd1113f763f493fa92b35b');
                s.setAttribute('data-bot-hash', '896c1bb5ac0f28c50796eefec5756d07367b7997');
                s.setAttribute('data-env', 'prod');
                s.setAttribute('data-region', 'us');
                if (c) {
                  s.onreadystatechange = s.onload = function () {
                    if (!loaded) {
                      c();
                    }
                    loaded = true;
                  };
                }
                n.parentNode.insertBefore(s, n);
              }
            })(document, window, function () {
              Freshbots.initiateWidget(
                {
                  autoInitChat: false,
                  getClientParams: function () {
                    return;
                  },
                },
                function (successResponse) {},
                function (errorResponse) {}
              );
            });
            `}
          </script>
        </Helmet>
        <Helmet>
          {/* Google tag (gtag.js) */}
          <script async src='https://www.googletagmanager.com/gtag/js?id=G-JNNR41CH70'></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag() {
              dataLayer.push(arguments);
              }
              gtag('js', new Date());
              gtag('config', 'G-JNNR41CH70');
            `}
          </script>
        </Helmet>
        <Helmet>
          {/* Meta Pixel Code */}
          <script>
            {`
            !(function (f, b, e, v, n, t, s) {
              if (f.fbq) return;
              n = f.fbq = function () {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
              };
              if (!f._fbq) f._fbq = n;
              n.push = n;
              n.loaded = !0;
              n.version = '2.0';
              n.queue = [];
              t = b.createElement(e);
              t.async = !0;
              t.src = v;
              s = b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t, s);
            })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '254452353914723');
            fbq('track', 'PageView');
              `}
          </script>
          <noscript>
            {`
            <img
              height='1'
              width='1'
              style='display: none'
              src='https://www.facebook.com/tr?id=254452353914723&ev=PageView&noscript=1'
            />
              `}
          </noscript>
        </Helmet>
        {children}
      </>
    </HelmetProvider>
  );
};

export default Meta;
