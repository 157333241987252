import * as S from './Products.styled';
import { SectionTitle, ProductsInfo } from 'infrastructure/components';
import { routerImg } from 'infrastructure/assets';
import { useProductsSpringAnim } from './hooks';

const Products = () => {
  const { mousePosListening, springDec1, springDec2, springDec3 } = useProductsSpringAnim();

  return (
    <S.Products id='productos' onMouseMove={mousePosListening}>
      <S.InnerCont>
        <SectionTitle
          SectionTitleProps={{
            text: 'Disfruta de Wuipi con estos productos',
            color: 'blue',
            weight: 'normal',
          }}
        />

        <S.ContentCont>
          <S.ProductImgCont>
            <S.ImgDetailBlue style={springDec2} />
            <S.ImgDetailOrange style={springDec1} />
            <S.ProductImg src={routerImg} alt='wuipi router img' style={springDec3} />
          </S.ProductImgCont>

          <ProductsInfo />
        </S.ContentCont>
      </S.InnerCont>
    </S.Products>
  );
};

export default Products;
