import styled from 'styled-components';
import { animated } from 'react-spring';

export const OurPlatform = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  min-height: calc(100vh - 121px);
  padding: 60px 0;
  overflow-x: hidden;

  @media screen and (min-width: 1920px) {
    min-height: auto;
    height: min(100vh, 800px);
  }
`;

export const InnerCont = styled.div`
  display: flex;
  flex-flow: wrap-reverse;
  justify-content: center;
  width: min(1000px, 86%);
  height: 100%;

  @media screen and (min-width: 900px) {
    justify-content: space-between;
    align-items: center;
  }
`;

export const WuipiAnthena = styled(animated.img)`
  width: min(100%, 250px);
  height: auto;
  object-fit: contain;

  @media screen and (min-width: 600px) {
    width: min(90%, 400px);
    height: auto;
    object-fit: contain;
  }
`;

export const OurPlatformImgCont = styled.div`
  position: relative;
  width: min(350px, 100%);
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;

  @media screen and (min-width: 900px) {
    margin-top: 0px;
  }
`;

export const BlueCircle = styled(animated.div)`
  position: absolute;
  left: -20px;
  top: -50px;
  width: 260px;
  height: 260px;
  background-color: #99aff2;
  border-radius: 50%;
`;

export const BlueDeepCircle = styled(animated.div)`
  position: absolute;
  bottom: 15px;
  left: 70px;
  width: 80px;
  height: 80px;
  background-color: #0f78f2;
  border-radius: 50%;
`;

export const BlueRing = styled(animated.div)`
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translate(0, -50%);
  width: 140px;
  height: 140px;
  background-color: #0f78f2;
  border-radius: 50%;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 130px;
    height: 130px;
    background-color: white;
  }
`;

export const Anthena = styled(animated.img)`
  width: min(100%, 250px);
  height: auto;
  margin-bottom: 10px;
  image-rendering: auto;
  filter: drop-shadow(0px 15px 8px rgba(0, 0, 0, 0.17));
`;
