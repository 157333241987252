import { Props, RowDataProps } from './interfaces/interfaces';
import { defaultData } from './utils/utils';
import InvoiceTablePagination from 'infrastructure/components/molecules/InvoiceTablePagination/InvoiceTablePagination';
import { createContext } from 'react';

import * as S from './InvoiceTable.styled';

export const InvoiceTableContext = createContext({} as RowDataProps[]);

export const InvoiceTable = ({ invoiceTableProps, children, ...restProps }: Props) => {
  const toContextValue: RowDataProps[] = invoiceTableProps?.rowData || defaultData;

  return (
    <InvoiceTableContext.Provider value={toContextValue}>
      <S.InvoiceTable>
        <S.Table {...restProps}>
          <S.InvoiceTableBody>{children}</S.InvoiceTableBody>
        </S.Table>
        <InvoiceTablePagination invoiceTablePaginationProps={invoiceTableProps?.paginationUtil} />
      </S.InvoiceTable>
    </InvoiceTableContext.Provider>
  );
};
