import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SupportPresentation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ButtonCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-flow: row wrap;
  width: min(100%, 550px);

  & button {
    margin-bottom: 20px;
  }

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    width: min(100%, 1200px);
  }
`;

export const LinkTo = styled(Link)``;
