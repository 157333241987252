import styled from 'styled-components';

export const ClientPayOrReport = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 40px 0 0 0px;
  margin-bottom: 1px;
`;

export const ResumeCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 50px;
  flex-direction: column;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    margin-bottom: 0px;
  }
`;

export const Greeting = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @media screen and (min-width: 600px) {
    margin-bottom: 0px;
  }
`;

export const ArticleName = styled.h2`
  font-size: 32px;
  margin-bottom: 10px;
  color: var(--color-blue-dark);
`;

export const IGTFInfo = styled.p`
  max-width: 300px;
  color: #0000005c;
  font-weight: 00;
  margin: 0;
`;

export const DueCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ClientPaymentsCont = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const PaypalFormCont = styled.div`
  width: min(100%, 500px);
  margin-top: 40px;
`;
