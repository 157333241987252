import { FormValuesProps, FormErrorsProps } from '../interfaces';

export const formValues: FormValuesProps = {
  name: '',
  email: '',
  ciOrRifType: 'V',
  ciOrRif: '',
  phoneNumber: '',
  contactPhoneNumber: '',
  addressForServiceInstallation: '',
  plan: '',

  paymentMethod: '',
};

export const formErrors: FormErrorsProps = {
  name: '',
  email: '',

  ciOrRif: '',
  phoneNumber: '',
  contactPhoneNumber: '',
  addressForServiceInstallation: '',
  plan: '',

  paymentMethod: '',
};
