import styled from 'styled-components';

export const Text = styled.p`
  color: var(--color-white-soft);
  font-size: 18px;
  width: min(100%, 750px);
  margin-bottom: 80px;
  word-wrap: anywhere;

  @media screen and (min-width: 600px) {
    word-wrap: normal;
  }
`;
