import styled from 'styled-components';

export const PlansAndPrices = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  min-height: calc(100vh - 121px);
  padding: 60px 0;
  overflow-x: hidden;

  @media screen and (min-width: 1920px) {
    min-height: auto;
    height: min(100vh, 800px);
  }
`;

export const InnerCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: min(1000px, 90%);
  height: 100%;
`;

export const PlansAndPricesBadgeCont = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 900px) {
    justify-content: center;
    flex-flow: row wrap;

    & > div {
      margin: 20px 10px;
    }
  }
`;

export const Note = styled.span`
  display: block;
  color: #828299;
  font-size: 16px;
  font-weight: 600;
`;
