import * as S from './CategoryDescription.styled';

interface Props {
  CategoryDescrptionProps?: CategoryDescriptionProps;
  className?: string;
  style?: {};
}

interface CategoryDescriptionProps {
  text?: string;
}

const CategoryDescription = ({ CategoryDescrptionProps }: Props) => {
  const text = CategoryDescrptionProps?.text;
  let textContent;

  if (text) {
    textContent = text;
  } else {
    textContent =
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint sit, nobis quas ea a cum perferendis ipsam nesciunt officia exercitationem eius maxime aspernatur voluptatibus iure illo eum reiciendis atque, deserunt, praesentium blanditiis totam? Facilis, doloremque esse repellendus aliquam ipsam vitae!';
  }

  return <S.Text>{textContent}</S.Text>;
};

export default CategoryDescription;
