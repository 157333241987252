import { useContext, CSSProperties } from 'react';
import { useFormatDate } from './hooks';
import { CategoryCardContext } from 'infrastructure/components/molecules/CategoryCard/CategoryCard';

import * as S from './CategoryCardDate.styled';
interface Props {
  CategoryCardDateProps?: CategoryCardDateProps;
  className?: string;
  style?: CSSProperties;
}

interface CategoryCardDateProps {
  date: string;
}

const CategoryCardDate = ({ CategoryCardDateProps, className, style }: Props) => {
  const { createdAt } = useContext(CategoryCardContext);
  const dateToShow: string = CategoryCardDateProps?.date || createdAt || '14:04';
  const { newDate } = useFormatDate({ date: dateToShow });

  return (
    <S.CategoryCardDate className={className} style={style}>
      Publicado el {newDate}
    </S.CategoryCardDate>
  );
};

export default CategoryCardDate;
