import { SupportInfo } from 'infrastructure/components';
import { wuipiSignal } from 'infrastructure/assets';
import { useSupportSpringAnim } from './hooks';
import { useRef } from 'react';

import * as S from './Support.styled';

const Support = () => {
  const animContRef = useRef(null);
  const { signalSpringStyles, mousePosListening } = useSupportSpringAnim({
    animContRef,
  });

  return (
    <S.Support id='soporte' ref={animContRef} onMouseMove={mousePosListening}>
      <S.InnerCont>
        <SupportInfo />

        <S.WuipiSignal src={wuipiSignal} alt='wuipi signal img' style={signalSpringStyles[0]} />
      </S.InnerCont>
    </S.Support>
  );
};

export default Support;
