import { useEffect, useState } from 'react';
import { useSpring, config } from 'react-spring';

const useSmathHomeSpringAnim = () => {
  const [mousePos, setMousePos] = useState({
    x: 0,
    y: 0,
  });
  const [dec1Plx, SetDec1Plx] = useState({
    px: 0,
    py: 0,
  });
  const [dec2Plx, SetDec2Plx] = useState({
    px: 0,
    py: 0,
  });

  const mousePosListening = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const { left, top, height, width } = e.currentTarget.getBoundingClientRect();
    setMousePos({
      x: (e.clientX - left) / width - 0.5,
      y: (e.clientY - top) / height - 0.5,
    });
  };

  const springDec1 = useSpring({
    transform: `translate(calc(${dec1Plx.px}px),calc(${dec1Plx.py}px))`,
  });

  const springDec2 = useSpring({
    transform: `translate(calc(${dec2Plx.px}px),calc(${dec2Plx.py}px))`,
    config: config.slow,
  });

  useEffect(() => {
    SetDec1Plx({
      px: -mousePos.x * 60,
      py: -mousePos.y * 60,
    });
    SetDec2Plx({
      px: -mousePos.x * 120,
      py: -mousePos.y * 120,
    });
  }, [mousePos]);

  return { mousePosListening, springDec1, springDec2 };
};

export default useSmathHomeSpringAnim;
