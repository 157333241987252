import { SectionText, SectionTitle } from 'infrastructure/components';
import * as S from './WhoIsWuipiInfo.styled';

const WhoIsWuipiInfo = () => {
  return (
    <S.IndividualService>
      <SectionTitle
        SectionTitleProps={{
          text: '¿Qué es Wuipi telecomunicaciones?',
          color: 'blue',
        }}
      />

      <SectionText
        color='blue'
        text='Es un proveedor de servicio de Internet Inalámbrico (Wisp), para clientes empresariales y residenciales. Brindamos un excelente servicio de Internet con anchos de banda dedicados o distribuidos según sea su requerimiento.'
      />
    </S.IndividualService>
  );
};

export default WhoIsWuipiInfo;
