import styled from 'styled-components';

export const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(90%, 300px);
  margin: 0 20px;
  margin-bottom: 60px;

  @media screen and (min-width: 900px) {
    margin-bottom: 0;
  }
`;
