import { useContext, useId, useMemo } from 'react';
import { Props } from './interfaces/interfaces';
import { defaultData } from './utils/utils';
import { useSeeDetails } from './hooks';

import { InvoiceTableContext } from 'infrastructure/components/organism/InvoiceTable/InvoiceTable';
import InvoiceTableRow from 'infrastructure/components/molecules/InvoiceTableRow/InvoiceTableRow';
import InvoiceTableRowDetails from 'infrastructure/components/molecules/InvoiceTableRowDetails/InvoiceTableRowDetails';

import * as S from './InvoiceTableRowFull.styled';

const InvoiceTableRowFull = ({ invoiceTableRowFullProps, colSpan, ...rest }: Props) => {
  const rowId = useId();
  const rowData = useContext(InvoiceTableContext);
  const dataToshow = useMemo(
    () => invoiceTableRowFullProps?.rowData || rowData || defaultData,
    [rowData, invoiceTableRowFullProps?.rowData]
  );
  const { invoiceToCheck, openDetails } = useSeeDetails(dataToshow);

  return (
    <>
      {dataToshow.map(
        (
          {
            status,
            invoiceNumber,
            currencyId,
            exchangeRate,
            detailsData,
            amountTotal,
            amountTotalSigned,
            amountResidual,
            amountResidualSigned,
          },
          i
        ) => {
          const { emision, expiration, description } = detailsData;

          return (
            <S.InvoiceTableRowWithDetails {...rest} key={`${invoiceNumber}-${rowId}-${i}`}>
              <S.InnerColumn colSpan={colSpan}>
                <S.InnerTable>
                  <S.InnerTableBody>
                    <InvoiceTableRow
                      invoiceTableRowProps={{
                        status,
                        invoiceNumber,
                        amountTotal,
                        invoiceToCheck,
                        currencyId,
                        exchangeRate,
                        amountTotalSigned,
                        amountResidual,
                        amountResidualSigned,
                      }}
                      onClick={() => openDetails(invoiceNumber)}
                    />
                    <InvoiceTableRowDetails
                      invoiceTableRowDetailsProps={{
                        emision,
                        expiration,
                        description,
                        invoiceToCheck,
                        invoiceNumber,
                      }}
                    />
                  </S.InnerTableBody>
                </S.InnerTable>
              </S.InnerColumn>
            </S.InvoiceTableRowWithDetails>
          );
        }
      )}
    </>
  );
};

export default InvoiceTableRowFull;
