import styled from 'styled-components';

export const ClientsData = styled.section`
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (min-width: 600px) {
    min-width: 315px;
    align-items: stretch;
  }

  @media screen and (min-width: 900px) {
    padding: 30px 60px;
  }
`;

export const StatusIdCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  max-width: 400px;
`;

export const SignOut = styled.button`
  cursor: pointer;
  margin-top: 30px;
  font-family: inherit;
  border: none;
  outline: none;
  padding: 5px 10px;
  font-weight: 500;
  border-radius: 30px;
  color: var(--color-white-soft);
  background-color: #bb4949;
  transition: var(--transition);

  &:hover {
    background-color: #d04343;
  }
`;

export const SpinnerCont = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;
