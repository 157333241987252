import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Outlet } from 'react-router';

interface Props {
  MetaData?: {
    fbqEventName: string;
    contentType: string;
    contentId: string;
  };
}

const RouteMeta = ({ MetaData }: Props) => {
  if (!MetaData) {
    return <Outlet />;
  }

  const { fbqEventName, contentType, contentId } = MetaData;

  return (
    <HelmetProvider>
      <>
        <Helmet>
          {`
							 fbq('track', '${fbqEventName}', { content_type: '${contentType}', content_id: '${contentId}' });
					`}
        </Helmet>

        <Outlet />
      </>
    </HelmetProvider>
  );
};

export default RouteMeta;
