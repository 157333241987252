import { CSSProperties, useEffect, useState } from 'react';
import * as S from './InvoiceTablePagination.styled';

interface Props {
  invoiceTablePaginationProps?: InvoiceTablePaginationProps;
  className?: string;
  style?: CSSProperties;
}

interface InvoiceTablePaginationProps {
  page?: number;
  nextPage?: () => void;
  previosPage?: () => void;
  rowAmount: {
    from: number;
    to: number;
    all: number;
  };
}

const InvoiceTablePagination = ({ invoiceTablePaginationProps: pagProps, ...restProps }: Props) => {
  const [isEqual, setIsEqual] = useState(false);

  useEffect(() => {
    setIsEqual(pagProps?.rowAmount.to === pagProps?.rowAmount.all);
  }, [pagProps?.rowAmount.to, pagProps?.rowAmount.all]);

  return (
    <S.InvoiceTablePagination {...restProps}>
      <S.HowManyCont>
        {pagProps?.rowAmount.from} - {pagProps?.rowAmount.to} de {pagProps?.rowAmount.all}
      </S.HowManyCont>
      <S.InvoiceTableArrowCont>
        <S.LeftArrow onClick={pagProps?.previosPage} />
        <S.ActualPage>{pagProps?.page}</S.ActualPage>{' '}
        <S.RightArrow onClick={!isEqual ? pagProps?.nextPage : () => null} data-maxed={isEqual} />
      </S.InvoiceTableArrowCont>
    </S.InvoiceTablePagination>
  );
};

export default InvoiceTablePagination;
