import styled from 'styled-components';

export const ClientInvoice = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 40px 0 0 0px;
  margin-bottom: 50px;
`;

export const ResumeCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
  flex-direction: column;

  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
`;

export const Greeting = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @media screen and (min-width: 600px) {
    margin-bottom: 0px;
  }
`;

export const ArticleName = styled.h2`
  font-size: 32px;
  margin-bottom: 10px;
  color: var(--color-blue-dark);
`;

export const IGTFInfo = styled.p`
  max-width: 300px;
  color: #0000005c;
  font-weight: 00;
  margin: 0;
`;

export const InvoiceInfoCont = styled.div`
  width: 100%;
  height: 100%;
`;

export const LoadingCont = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
