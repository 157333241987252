import { CSSProperties } from 'react';
import * as S from './BlueEffectRadio.styled';

interface Props {
  plansChoiceRadioProps?: PlansChoiceRadioProps;
  className?: string;
  style?: CSSProperties;
}

interface PlansChoiceRadioProps {
  name: string;
  id: string;
  radioValue: string;
  label: string;
  value: string;
  optionalInfo?: string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const BlueEffectRadio = ({ plansChoiceRadioProps, ...restProps }: Props) => {
  const { radioValue, value, label, handleChange, optionalInfo, ...rest } =
    plansChoiceRadioProps || {
      name: 'testname',
      id: 'test',
      radioValue: 'test',
      label: 'test',
      planInfo: 'Hasta 10 MBPS · $16 + IVA',
      value: '',
    };

  return (
    <S.PlansChoice {...restProps}>
      {optionalInfo ? <S.BlueEffect selected={value === radioValue} /> : null}
      <S.RadioCont>
        <S.Radio
          {...rest}
          type='radio'
          value={radioValue}
          checked={value === radioValue}
          onChange={handleChange}
        />
        <S.Label htmlFor={rest.id}>{label}</S.Label>
      </S.RadioCont>
      <S.OptionalInfo selected={value === radioValue}>{optionalInfo}</S.OptionalInfo>
    </S.PlansChoice>
  );
};

export default BlueEffectRadio;
