import styled from 'styled-components';

export const PaymentMethodInfoTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: min(100%, 500px);
  margin-bottom: 50px;
`;

export const MethodName = styled.span`
  font-size: 38px;
  font-weight: 600;
  color: var(--color-blue-dark);
  margin-bottom: 15px;
`;

export const Line = styled.hr`
  color: var(--color-blue-dark);
  width: 60%;
  height: 1px;
`;
