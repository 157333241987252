import { useId } from 'react';
import { WebTitle, SupportMainButton, SearchBar } from 'infrastructure/components';
import { useGetAllCategoriesQuery } from 'infrastructure/store/apis/support';

import * as S from './SupportPresentation.styled';

const SupportPresentation = () => {
  const categoriesId = useId();
  const { data, isSuccess, isError } = useGetAllCategoriesQuery(null);

  return (
    <S.SupportPresentation>
      <WebTitle
        WebTitleProps={{
          text: 'Bienvenido al portal de soporte',
          size: 'medium',
        }}
      />

      <SearchBar SearchBarProps={{ placeholder: 'Buscar' }} />
      <S.ButtonCont>
        {isSuccess && !isError ? (
          <>
            {data.map(({ name, id }) => {
              const nameFormated = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

              return (
                <S.LinkTo to={`${id}/#article`} key={`${categoriesId}-${id}`}>
                  <SupportMainButton
                    SupportMainButtonProps={{ text: nameFormated }}
                    type='button'
                  />
                </S.LinkTo>
              );
            })}
          </>
        ) : null}
      </S.ButtonCont>
    </S.SupportPresentation>
  );
};

export default SupportPresentation;
