import { useGetTelegramStatusQuery } from 'infrastructure/store/apis/telegram';
import { Notification } from 'infrastructure/components';
import { Outlet } from 'react-router-dom';
//import { useCookieCleanerOnPageReload } from 'infrastructure/hooks';

import * as S from './LayoutRoot.styled';

const LayoutRoot = () => {
  const { data } = useGetTelegramStatusQuery(null);

  //use it when client panel is added
  // useCookieCleanerOnPageReload();

  return (
    <S.LayoutRoot data-component-name='Layout Root'>
      <Notification
        data-component-names='Page Notification'
        NotificationProps={{
          message: data,
        }}
      />
      <Outlet />
    </S.LayoutRoot>
  );
};
//'Todos nuestros servicios se encuentran operativos. Su navegación es nuestra responsabilidad.'
export default LayoutRoot;
