import { ButtonProps } from 'react-html-props';
import { CSSProperties } from 'react';
import * as S from './MainButton.styled';

interface Props extends ButtonProps {
  MainButtonProps?: MainButtonProps;
  className?: string;
  style?: CSSProperties;
}

interface MainButtonProps {
  color: 'orange' | 'orangeGradient' | 'blue' | 'green' | 'none';
  borderRadius: 'round' | 'square';
  slimText?: boolean;
  text: string;
  disabled?: boolean;
  onClick?: () => void;
  type?: 'button' | 'reset' | 'submit';
}

const MainButton = ({ MainButtonProps, ...restProps }: Props) => {
  let customProps: MainButtonProps = MainButtonProps || {
    color: 'orange',
    borderRadius: 'round',
    slimText: false,
    text: 'Button',
    disabled: false,
    type: 'button',
  };

  return (
    <S.MainButton {...customProps} {...restProps}>
      {customProps?.text}
    </S.MainButton>
  );
};

export default MainButton;
