import styled from 'styled-components';

export const OurPlans = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  min-height: calc(100vh - 121px);
  padding: 60px 0;

  @media screen and (min-width: 1920px) {
    min-height: auto;
    height: min(100vh, 800px);
  }
`;

export const InnerCont = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: min(1000px, 86%);
  height: 100%;

  @media screen and (min-width: 1200px) {
    justify-content: space-between;
  }
`;

export const OurPlansVideo = styled.div`
  position: relative;
  padding: 0 !important;
  width: min(349px, 100%);

  & video {
    width: min(349px, 100%);
    height: auto;
  }

  @media screen and (min-width: 900px) {
    position: relative;
    right: calc(-40px - 110 * ((100vw - 900px) / 300));
    & video {
      position: relative;
      right: calc(-40px - 110 * ((100vw - 900px) / 300));
    }
  }

  @media screen and (min-width: 1200px) {
    width: min(450px, 100%);
    left: 65px;
    & video {
      width: min(450px, 100%);
      left: 65px;
    }
  }
`;

export const VideoFallBackImg = styled.img`
  width: min(349px, 100%);
  height: auto;

  @media screen and (min-width: 900px) {
    position: relative;
    right: calc(-40px - 110 * ((100vw - 900px) / 300));
  }

  @media screen and (min-width: 1200px) {
    width: min(450px, 100%);
    left: 65px;
  }
`;
