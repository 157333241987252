import { Link } from 'react-router-dom';
import { animated } from 'react-spring';
import styled from 'styled-components';

export const ContactThanks = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--color-white);
  min-height: calc(100vh - 121px);
  padding: 60px 20px;
  overflow: hidden;

  @media screen and (min-width: 1920px) {
    min-height: auto;
    height: min(100vh, 800px);
  }
`;

export const ContactThanksText = styled.h1`
  font-size: 42px;
  font-weight: 400;
  text-align: center;
  color: var(--color-blue-dark);
  margin-bottom: 30px;
  z-index: 1;
`;

export const ContactThanksMessage = styled.p`
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: var(--color-blue-dark);
  max-width: 600px;
  margin-bottom: 60px;
  z-index: 1;
`;

export const LinkTo = styled(Link)`
  z-index: 1;
`;

export const FullSquare = styled(animated.img)`
  position: absolute;
  top: 50%;
  left: -8%;
  transform: translate(-80%, -50%) rotate(-30deg);
  width: 300px;
  height: auto;

  @media screen and (min-width: 600px) {
    width: 600px;
  }
`;

export const emptySquare = styled(animated.img)`
  position: absolute;
  top: 75%;
  left: 110%;
  transform: translate(-20%, -50%) rotate(30deg);
  width: 300px;
  height: auto;

  @media screen and (min-width: 600px) {
    width: 600px;
  }
`;
