import * as S from './Navbar.styled';
import { useId } from 'react';
import { MainButton, NavbarLink } from 'infrastructure/components';
import ReactGA from 'react-ga';

interface NavbarProps {
  links: string[];
  noMainButton?: boolean;
}

const Navbar = ({ links, noMainButton }: NavbarProps) => {
  const id = useId();

  const gaClickCliente = () => {
    ReactGA.event({
      category: 'Botón',
      action: 'Click',
      label: 'Botón de cliente',
    });
  };

  const gaClickSupport = () => {
    ReactGA.event({
      category: 'Botón',
      action: 'Click',
      label: 'Botón de support',
    });
  };

  return (
    <S.Navbar>
      {links.map((link) => {
        return (
          <NavbarLink
            NavbarLinkProps={{ to: `/#${link}`, text: link, color: 'white' }}
            key={`${id}-${link}`}
          />
        );
      })}

      {!noMainButton && (
        <NavbarLink
          NavbarLinkProps={{
            to: '/support',
            text: 'soporte',
            color: 'orange',
            onClick: gaClickSupport,
          }}
        />
      )}
      {/* <S.Links to='/clients/invoice'> */}
      {!noMainButton && (
        <S.Links onClick={gaClickCliente} to='https://clientes.wuipi.net/#/' rel='noreferrer'>
          <MainButton
            MainButtonProps={{
              text: 'cliente',
              color: 'orangeGradient',
              borderRadius: 'round',
              type: 'button',
            }}
          />
        </S.Links>
      )}
    </S.Navbar>
  );
};

export default Navbar;
