import { CSSProperties, useContext } from 'react';
import { CategoryCardContext } from 'infrastructure/components/molecules/CategoryCard/CategoryCard';

import * as S from './CategoryCardSummary.styled';
interface Props {
  CategoryCardSummaryProps?: CategoryCardSummaryProps;
  className?: string;
  style?: CSSProperties;
}

interface CategoryCardSummaryProps {
  text: string;
}

const CategoryCardSummary = ({ CategoryCardSummaryProps, className, style }: Props) => {
  const { summary } = useContext(CategoryCardContext);
  const summaryToShow: string =
    CategoryCardSummaryProps?.text ||
    summary ||
    'search for ‘lorem ipsum’ will uncover many web sites still in their infancy.';

  return (
    <S.CategoryCardSummary className={className} style={style}>
      {summaryToShow.substring(0, 60)} ...
    </S.CategoryCardSummary>
  );
};

export default CategoryCardSummary;
