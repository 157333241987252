import styled from 'styled-components';

export const ClientAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  margin-top: 30px;
`;

export const Title = styled.h4`
  font-size: 20px;
  color: var(--color-blue-dark);
`;

export const Address = styled.span`
  font-size: 18px;
  margin-top: 10px;
  max-width: 400px;
  color: var(--color-blue-dark);
`;
