import styled from 'styled-components';
import { IoIosArrowDown } from 'react-icons/io';
import { Link } from 'react-router-dom';

export const InvoiceTableRow = styled.tr`
  font-size: 18px;
  color: var(--color-blue-dark);
  height: 40px;
  cursor: pointer;

  &:hover {
    background-color: #e5f2ff;
  }
`;

export const FirstColumn = styled.td`
  width: 30px;
  text-align: center;
  text-align: -moz-center;
  text-align: -webkit-center;
`;

interface StatusSignalProps {
  invoiceStatus: 'not_paid' | 'in_payment' | 'paid' | 'partial' | false;
}

export const StatusSignal = styled.div<StatusSignalProps>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${({ invoiceStatus }) =>
    invoiceStatus === 'paid' || invoiceStatus === 'in_payment'
      ? '#60cb60'
      : invoiceStatus === 'not_paid'
      ? '#d04343'
      : invoiceStatus === 'partial'
      ? '#FFBE45'
      : '#fff'};
`;

export const SecondColumn = styled.td`
  font-weight: 600;
  width: 200px;
  text-align: start;
`;

export const ThirdColumn = styled.td<StatusSignalProps>`
  display: none;
  font-weight: 600;
  width: 10px;
  text-align: start;

  width: 240px;
  display: ${({ invoiceStatus }) => (invoiceStatus === 'not_paid' ? 'table-cell' : 'none')};
`;

export const ReportOrPayCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Links = styled(Link)``;

export const ReportOrPay = styled.button`
  cursor: pointer;
  font-family: inherit;
  border: none;
  outline: none;
  padding: 5px 10px;
  font-weight: 500;
  border-radius: 10px;
  font-size: 16px;
  color: var(--color-white-soft);
  background-color: #bb4949;
  transition: var(--transition);

  &:hover {
    background-color: #d04343;
  }
`;

export const ForthColumn = styled.td``;

export const FifthColumn = styled.td`
  width: 700px;
  text-align: end;
`;

export const SixthColumn = styled.td`
  width: max-content;
  width: 40px;
  padding-top: 8px;
  text-align: center;
  text-align: -moz-center;
  text-align: -webkit-center;
`;

export const Arrow = styled(IoIosArrowDown)`
  font-size: 20px;
  transform: rotate(0deg);
  transition: var(--transition-fast);

  &[data-selected='true'] {
    transform: rotate(180deg);
  }
`;
