import {
  WhoIsWuipi,
  OurPlatform,
  Feasibility,
  InstallAndCost,
  InstallationKit,
  PlansAndPrices,
  ServiceRequest,
  ImportantNote,
} from 'infrastructure/components';

import * as S from './Services.styled';

const Services = () => {
  return (
    <S.Services>
      <WhoIsWuipi />
      <OurPlatform />
      <Feasibility />
      <InstallAndCost />
      <InstallationKit />
      <PlansAndPrices />
      <ServiceRequest />
      <ImportantNote />
    </S.Services>
  );
};

export default Services;
