import {
  FormInput,
  FormPhoneInput,
  FormSelect,
  MainButton,
  BlueEffectRadio,
} from 'infrastructure/components';
import { formValues, formErrors } from './utils';
import { useId } from 'react';
import { useForm } from './hooks';
import MoonLoader from 'react-spinners/MoonLoader';
import ReactGA from 'react-ga';

import style from './styleExtender/customStyle.module.scss';
import * as S from './ServiceRequestForm.styled';

const ServiceRequestForm = () => {
  const gaClickserviceResquest = () => {
    ReactGA.event({
      category: 'Botón',
      action: 'Click',
      label: 'Botón de service request',
    });
  };

  const id = useId();
  const {
    values,
    errors,
    disableSubmit,
    isLoading,
    isValidating,
    handleSubmit,
    handleChange,
    phoneNumberHandleChange,
    selectHandleChange,
  } = useForm(formValues, formErrors);

  return (
    <S.Form onSubmit={handleSubmit}>
      <FormInput
        FormInputProps={{
          id: `${id}-nameInput`,
          name: 'name',
          label: 'nombre o razón social del contratante',
          type: 'text',
          placeholder: 'Jhon Doe',
          errorMessage: errors.name,
          onChange: handleChange,
          value: values.name,
          required: true,
          isValidating,
        }}
      />
      <FormInput
        FormInputProps={{
          id: `${id}-emailInput`,
          name: 'email',
          label: 'Correo electrónico del contratante',
          type: 'email',
          placeholder: 'jhondoe@email.com',
          errorMessage: errors.email,
          onChange: handleChange,
          value: values.email,
          required: true,
          isValidating,
        }}
      />
      <S.CiOrRif>
        <FormSelect
          id={`${id}-ciOrRifTypeInput`}
          name='ciOrRifType'
          onChange={selectHandleChange}
          value={values.ciOrRifType}
          required
          className={`${style.selectForm}`}
          formSelectProps={{
            options: [
              { label: 'V', value: 'V' },
              { label: 'E', value: 'E' },
              { label: 'J', value: 'J' },
            ],
          }}
        />
        <FormInput
          className={`${style.inputStyleExtender}`}
          FormInputProps={{
            id: `${id}-ciOrRifInput`,
            name: 'ciOrRif',
            label: 'C.I./RIF DEL CONTRATANTE',
            type: 'text',
            errorMessage: errors.ciOrRif,
            onChange: handleChange,
            value: values.ciOrRif,
            required: true,
            isValidating,
          }}
        />
      </S.CiOrRif>
      <FormPhoneInput
        id={`${id}-PhoneNumberInput`}
        name='phoneNumber'
        placeholder='1 (234) 567-8910'
        formInputProps={{
          label: 'TELÉFONO DEL CONTRATANTE',
          errorMessage: errors.phoneNumber,
          withCheck: true,
          onChange: phoneNumberHandleChange,
          value: values.phoneNumber,
          isValidating,
          fromStartValidation: true,
        }}
      />
      <FormPhoneInput
        id={`${id}-PhoneNumberInput`}
        name='contactPhoneNumber'
        placeholder='1 (234) 567-8910'
        formInputProps={{
          label: 'TELÉFONO DE PERSONA DE CONTACTO',
          errorMessage: errors.contactPhoneNumber,
          withCheck: true,
          onChange: phoneNumberHandleChange,
          value: values.contactPhoneNumber,
          isValidating,
          fromStartValidation: true,
        }}
      />
      <FormInput
        FormInputProps={{
          id: `${id}-addressForServiceInstallation`,
          name: 'addressForServiceInstallation',
          label: 'DIRECCIÓN donde se realizará la INSTALACIÓN',
          placeholder: 'Casa, Calle, Sector/Residencia, Punto de referencia',
          type: 'text',
          errorMessage: errors.addressForServiceInstallation,
          onChange: handleChange,
          value: values.addressForServiceInstallation,
          required: true,
          isValidating,
        }}
      />

      <S.FieldSet>
        <S.Label>Planes</S.Label>
        <BlueEffectRadio
          style={{ marginBottom: 0, padding: '20px 0 10px 0' }}
          plansChoiceRadioProps={{
            id: `${id}-Plan-beam-10`,
            label: 'Plan beam 10',
            name: 'plan',
            optionalInfo: 'Hasta 10 MBPS · $16 + IVA',
            radioValue: 'Plan beam 10',
            value: values.plan,
            handleChange,
          }}
        />
        <BlueEffectRadio
          style={{ marginBottom: 0, padding: '10px 0' }}
          plansChoiceRadioProps={{
            id: `${id}-Plan-beam-20`,
            label: 'Plan beam 20',
            name: 'plan',
            optionalInfo: 'Hasta 20 MBPS · $31 + IVA',
            radioValue: 'Plan beam 20',
            value: values.plan,
            handleChange,
          }}
        />
        <BlueEffectRadio
          style={{ marginBottom: 0, padding: '10px 0' }}
          plansChoiceRadioProps={{
            id: `${id}-Plan-beam-30`,
            label: 'Plan beam 30',
            name: 'plan',
            optionalInfo: 'Hasta 30 MBPS · $43 + IVA',
            radioValue: 'Plan beam 30',
            value: values.plan,
            handleChange,
          }}
        />
        <BlueEffectRadio
          style={{ marginBottom: 0, padding: '10px 0' }}
          plansChoiceRadioProps={{
            id: `${id}-Plan-beam-50`,
            label: 'Plan beam 50',
            name: 'plan',
            optionalInfo: 'Hasta 50 MBPS · $76 + IVA',
            radioValue: 'Plan beam 50',
            value: values.plan,
            handleChange,
          }}
        />
        <S.Error>{errors.plan}</S.Error>
      </S.FieldSet>
      <S.FieldSet style={{ marginBottom: '30px' }}>
        <S.Label>Métodos de pago</S.Label>
        <BlueEffectRadio
          style={{ marginBottom: 0, padding: '20px 0 10px 0' }}
          plansChoiceRadioProps={{
            id: `${id}-paymentBolivares`,
            label: 'Bolívares',
            name: 'paymentMethod',
            radioValue: 'Bolivares',
            value: values.paymentMethod,
            handleChange,
          }}
        />
        <BlueEffectRadio
          style={{ marginBottom: 0, padding: '10px 0' }}
          plansChoiceRadioProps={{
            id: `${id}-paymentDolares`,
            label: 'Dólares',
            name: 'paymentMethod',
            radioValue: 'Dolares',
            value: values.paymentMethod,
            handleChange,
          }}
        />
        <BlueEffectRadio
          style={{ marginBottom: 0, padding: '10px 0' }}
          plansChoiceRadioProps={{
            id: `${id}-paymentZelle`,
            label: 'Zelle',
            name: 'paymentMethod',

            radioValue: 'Zelle',
            value: values.paymentMethod,
            handleChange,
          }}
        />
        <BlueEffectRadio
          style={{ marginBottom: 0, padding: '10px 0' }}
          plansChoiceRadioProps={{
            id: `${id}-paymentBanescoPanama`,
            label: 'Banesco Panamá',
            name: 'paymentMethod',

            radioValue: 'BanescoPanama',
            value: values.paymentMethod,
            handleChange,
          }}
        />
        <BlueEffectRadio
          style={{ marginBottom: 0, padding: '10px 0' }}
          plansChoiceRadioProps={{
            id: `${id}-paymentPaypal`,
            label: 'Paypal',
            name: 'paymentMethod',

            radioValue: 'Paypal',
            value: values.paymentMethod,
            handleChange,
          }}
        />
        <S.Error>{errors.paymentMethod}</S.Error>
      </S.FieldSet>

      {isLoading ? (
        <S.SpinnerCont>
          <MoonLoader color='#1F3487' size={50} speedMultiplier={0.5} />
        </S.SpinnerCont>
      ) : (
        <MainButton
          MainButtonProps={{
            onClick: gaClickserviceResquest,
            color: 'orange',
            borderRadius: 'round',
            text: 'ENVIAR',
            disabled: disableSubmit,
            type: 'submit',
          }}
        />
      )}
    </S.Form>
  );
};

export default ServiceRequestForm;
