import { createContext, CSSProperties, ReactElement } from 'react';
import * as S from './PaymentMethodInfo.styled';

interface Props {
  paymentMethodInfoProps?: PaymentMethodInfoProps;
  className?: string;
  style?: CSSProperties;
  children?: ReactElement | ReactElement[];
}

interface PaymentMethodInfoProps {
  title: string;
  summary: string;
  credentials: string;
}

export const PaymentMethodInfoContext = createContext({} as PaymentMethodInfoProps);

export const PaymentMethodInfo = ({
  paymentMethodInfoProps,
  className,
  style,
  children,
}: Props) => {
  const toContextValue: PaymentMethodInfoProps = paymentMethodInfoProps || {
    title: '',
    summary: '',
    credentials: '',
  };

  return (
    <PaymentMethodInfoContext.Provider value={toContextValue}>
      <S.PaymentMethodInfo>{children}</S.PaymentMethodInfo>
    </PaymentMethodInfoContext.Provider>
  );
};
