import { RowDataProps } from '../interfaces/interfaces';

export const defaultData: RowDataProps[] = [
  {
    status: false,
    invoiceNumber: false,
    exchangeRate: 0,
    currencyId: [0, 'USD'],
    amountTotal: false,
    amountTotalSigned: false,
    amountResidual: false,
    amountResidualSigned: false,
    detailsData: {
      emision: false,
      expiration: false,
      description: {
        title: false,
        suspensionDate: false,
        internetPlan: false,
        descarga: false,
        subida: false,
      },
    },
  },
];
