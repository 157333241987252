import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const ArticleNavLink = styled(NavLink)`
  margin-bottom: 1px;
  width: fit-content;
  padding: 10px 0;
  color: var(--color-white-soft);
  font-size: 18px;

  @media screen and (min-width: 600px) {
    font-size: 22px;
  }
`;
