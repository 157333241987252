import { Logo, Navbar, NavbarFooterMobile, SocialMediaIcon } from 'infrastructure/components';
import * as S from './Footer.styled';

const Footer = () => {
  const links = ['inicio', 'planes', 'servicios', 'productos', 'contacto'];

  return (
    <S.Footer data-component-name='Footer'>
      <S.FooterInnerCont>
        <S.FooterUpper>
          <S.LogoCont>
            <S.Links to='/'>
              <Logo color='white' />
            </S.Links>
          </S.LogoCont>
          <Navbar links={links} noMainButton />
          <NavbarFooterMobile links={links} />
        </S.FooterUpper>

        <S.FooterSocialLink>
          <SocialMediaIcon href='https://twitter.com/wuipitech'>
            <S.TwitterLogo />
          </SocialMediaIcon>
          <SocialMediaIcon href='https://www.tiktok.com/@wuipitech'>
            <S.TiktokLogo />
          </SocialMediaIcon>
          <SocialMediaIcon href='https://www.instagram.com/wuipitech/?hl=es'>
            <S.InstagramLogo />
          </SocialMediaIcon>
          <SocialMediaIcon href='https://es-la.facebook.com/wuipitelecomunicaciones/'>
            <S.FacebookLogo />
          </SocialMediaIcon>
          <SocialMediaIcon href='mailto:info@wuipi.net'>
            <S.EmailLogo />
          </SocialMediaIcon>
        </S.FooterSocialLink>

        <S.AdressCont>
          <S.AdressText>
            Avenida Principal La Tinia, Quinta Cerro Alto #1, Puerto La Cruz 6016, Anzoátegui
          </S.AdressText>
        </S.AdressCont>

        <S.AllRightCont>
          <S.AllRightIcon />
          <S.AllRightText>IKUSA MEDIA C.A. todos los derechos reservados</S.AllRightText>
        </S.AllRightCont>
      </S.FooterInnerCont>
    </S.Footer>
  );
};

export default Footer;
