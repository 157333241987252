import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface clientDataProps {
  id: number | null;
  name: string;
  displayName: string;
  phoneSanitized: string;
  isCompany: boolean;
  email: string;
  contactAdressComplete: string;
  credit: number | null;
  debit: number | null;
  totalInvoiced: number | null;
  invoiceIds: number[] | null;
  unpaidInvoices: number[] | null;
  vat: string | false;
  cedula: string | false;
  totalDue: number | null;
  totalOverdue: number | null;
  categoryIdStatus: number[] | null;
  exchangeRate: number | null;
}

interface clientsInitialState {
  status: 'idle' | 'loading' | 'success' | 'error';
  message: string;
  clientData: clientDataProps;
}

const initialState: clientsInitialState = {
  status: 'idle',
  message: '',
  clientData: {
    id: null,
    name: '',
    displayName: '',
    phoneSanitized: '',
    isCompany: false,
    email: '',
    contactAdressComplete: '',
    credit: null,
    debit: null,
    totalInvoiced: null,
    invoiceIds: null,
    unpaidInvoices: null,
    vat: false,
    cedula: false,
    totalDue: null,
    totalOverdue: null,
    categoryIdStatus: null,
    exchangeRate: null,
  },
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setClientData: (state, action: PayloadAction<clientDataProps>) => {
      const clientData = action.payload;
      state.clientData = clientData;
    },

    setStatus: (state, action: PayloadAction<'idle' | 'loading' | 'success' | 'error'>) => {
      state.status = action.payload;
    },

    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setClientData, setStatus, setMessage } = clientsSlice.actions;
