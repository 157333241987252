import styled from 'styled-components';
import { SectionTextProps } from './interfaces';

export const SectionText = styled.p.attrs((props: SectionTextProps) => {
  return props;
})`
  margin-bottom: 40px;
  font-size: 16px;
  color: ${({ color }) =>
    color === 'white' ? 'var(--color-white-soft)' : 'var(--color-blue-dark)'};

  @media screen and (min-width: 600px) {
    font-size: 18px;
  }
`;
