import styled, { css } from 'styled-components';
import { DivProps } from 'react-html-props';

export const WuipiSVGSignal = styled.div.attrs((props: DivProps) => {
  return props;
})`
  position: absolute;
  left: 65%;
  top: 55%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  z-index: 1;
  transform: rotate(10deg);
`;

interface SignalProps {
  top: string;
  left: string;
  $selected?: boolean;
}

export const Signal = styled.img<SignalProps>`
  max-width: 100%;
  height: auto;
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  transform: translate(-100%, 0%);
  transition: var(--transition);
  ${({ $selected }) =>
    $selected
      ? css`
          filter: invert(61%) sepia(47%) saturate(341%) hue-rotate(188deg) brightness(175%)
            contrast(90%);
        `
      : null}
`;
