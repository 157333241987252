import styled from 'styled-components';

interface PanelMainButtonProps {
  color?: 'orange' | 'blue';
}

export const PanelMainButton = styled.button<PanelMainButtonProps>`
  cursor: pointer;
  border: none;
  font-family: inherit;
  font-size: 18px;
  border-radius: 15px;
  padding: 8px 20px;
  color: var(--color-white-soft);
  font-weight: 600;
  width: max-content;
  background-color: ${({ color }) =>
    color === 'orange' ? '#D77630' : color === 'blue' ? '#384680' : 'transparent'};

  transition: var(--transition);
`;
