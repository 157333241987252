import styled from 'styled-components';

export const Notification = styled.div`
  grid-area: notification;
  position: relative;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  background-color: var(--color-blue-dark);
`;

export const NotificationText = styled.span`
  position: absolute;
  width: max-content;
  font-size: 20px;
  letter-spacing: 1px;
  color: var(--color-white);

  animation: circle-animation 25s linear infinite;

  @keyframes circle-animation {
    0% {
      transform: translateX(130%);
    }

    100% {
      transform: translateX(-130%);
    }
  }

  @media screen and (min-width: 900px) {
    @keyframes circle-animation {
      0% {
        transform: translateX(130vw);
      }

      100% {
        transform: translateX(-130vw);
      }
    }
  }
`;
