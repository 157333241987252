import styled from 'styled-components';
import { wave } from 'infrastructure/assets';

export const ImportantNote = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  min-height: calc(100vh - 121px);
  padding: 60px 0;
  overflow-x: hidden;
  background-image: url(${wave});
  background-position: -360px -100px;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (min-width: 600px) {
    background-position: center 0px;
  }
`;

export const InnerCont = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: min(1000px, 86%);
  height: 100%;
  padding-top: 50px;

  @media screen and (min-width: 900px) {
    align-items: center;
    padding-top: 180px;
  }
`;

export const Text = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: var(--color-blue-dark);
  text-align: center;
`;
