import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const CategoryCard = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: min(100%, 360px);
  min-height: 400px;
  margin: 10px 5px 20px 5px;
  padding: 40px 30px;
  cursor: pointer;

  &:nth-child(even) {
    background-color: #4270ed;
  }

  &:nth-child(odd) {
    background-color: #1f3487;
  }

  @media screen and (min-width: 600px) {
    padding: 40px;
  }
`;
