import styled from 'styled-components';

export const NavbarFooterMobile = styled.div`
  display: flex;
  flex-direction: column;

  & a {
    margin-bottom: 5px;
  }

  @media screen and (min-width: 900px) {
    display: none;
  }
`;
